@import "../../../assets/scss/colors";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/extended-classes";
@import "../../../assets/scss/break-points";

.custom-dropmenu {
  &.action-menu-dropdown {
    .MuiPaper-root.MuiMenu-paper {
      margin-top: .5rem;
      .MuiMenu-list {
        padding: 0;
        .MuiMenuItem-root {
          padding: 1.2rem 1.6rem;
          display: flex;
          align-items: center;
          &:hover {
            background-color: rgba(245, 244, 241, 0.4);
          }
          .icon {
            display: inline-flex;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
