.tab-page-section {
  .page-container {
    padding-right: 0;
    padding-bottom: 0;
    .timeline-wrapper {
      display: flex;
      justify-content: space-between;
      @include bp(smd-max) {
        flex-flow: column;
      }
    }
    .main-page-header {
      padding-right: 2.4rem;
      // margin-bottom: 1.6rem;
    }
    > .page-filters-wrapper {
      padding-right: 2.4rem;
      margin-bottom: 1.6rem;
      .right-filter {
        .btn-three-dots {
          min-width: 4.8rem;
          height: 4.8rem;
          .icon {
            display: flex;
            font-size: 2rem;
          }
        }
      }
    }
    .page-content {
      padding-top: 2.4rem;
      padding-bottom: 1.6rem;
      width: 100%;
      max-width: calc(100% - 38.4rem);
      @include bp(smd-max) {
        max-width: calc(100% - 2.4rem);
      }
      .page-filters-wrapper {
        margin-bottom: 2.4rem;
        .left-filter {
          .form-group {
            &.search-container {
              padding: 0;
              width: 30.4rem;
              max-width: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .activities-wrapper {
    margin: 0 0 2.4rem 0;
  }  
  .activity-list-box {
    background: $white;
    padding: 1.6rem;
    padding-bottom: 0;        
    box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.15);
    &:first-child {
      border-radius: 0.8rem 0.8rem 0 0;
    }
    &:last-child {
      padding-bottom: 1.6rem;
      border-radius: 0 0 0.8rem 0.8rem;
    }
    &.scrollable {
      max-height: calc(100vh - 33rem);
      overflow: auto;
      @include custom-scroll;
      @include bp(smd-max) {
        max-height: inherit;
        overflow: visible;
      }
    }
    .activity-group {
      margin-bottom: 16px;
      &:last-child {
        margin: 0;
      }
    }
    h6 {
      padding-bottom: 0.8rem;
      border-bottom: 0.1rem solid $grey3;
      margin: 0;
    }
    .recent-activity {
      max-height: 100%;
      // overflow: auto;
      // @include custom-scroll;
      &.no-scroll {
        max-height: inherit;
        overflow: inherit;
      }
      @include bp(smd-max) {
        display: flex;
        flex-flow: column;
      }
      .user-link {
        padding: 2rem 0;
        display: flex;
        //align-items: center;
        font-size: 1.4rem;
        @include line-height(12,20);
        letter-spacing: 0.01em;
        font-weight: 400;
        //cursor: pointer;
        position: relative;
        padding-right: 30px;

        .menu-trigger {
          position: absolute;
          right: 0;
          top: 50%;
          cursor: pointer;
          @include translateY(-50%);
          line-height: 0;
          display: inline-block;
          .trigger-icon {
            display: inline-block;
            line-height: 0;
            color: $grey4;
            font-size: 24px;
            @include rotate(90deg);
          }
        }

        .initials,
        .user-avatar {
          height: 3.6rem;
          width: 3.6rem;
          min-width: 3.6rem;
          border-radius: 50%;
          background: #f3ebff;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2rem;
          font-weight: 500;
          img {
            border-radius: 50%;
          }
        }
        .user-name {
          padding-left: 1.2rem;
        }
        .user-detail {
          padding-left: 1.2rem;
          letter-spacing: 0.014rem;
          color: $black;
          font-weight: normal;
          .user-name {
            padding-left: 0;
            color: $purple1;
          }
        }
        .time {
          color: $grey5;
          position: relative;
          padding-left: 10px;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 3px;
            width: 3px;
            display: block;
            background: $grey5;
            border-radius: 50%;
          }
        }
      }
      .infinite-scroll-component {
        overflow-x: hidden !important;
      }
    }
  }
  &.spaces-team-page{
    .page-container {
      .main-page-header{
        margin-bottom: 1.6rem;
      }
    }
  }
}

// Right side summary
.summary, .tag-result {
  position: relative;
  width: 33.6rem;
  height: auto;  
  min-height: calc(100vh - 21.7rem);
  border-left: 1px solid $grey2;
  padding: 2.4rem;
  font-size: 1.4rem;
  color: $black1;
  background: $white;
  @include line-height(14, 20);
  &.scrollable {
    height: calc(100vh - 22rem);
    overflow: auto;
    @include custom-scroll;
    @include bp(smd-max) {
      height: auto;
      overflow: visible;
    }
  }
  @include bp(smd-max) {
    width: 100%;
    max-width: calc(100% - 2.4rem);
    border: 1px solid $grey2;
    margin-bottom: 2.4rem;            
  }
  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0.8rem 0;
    .title {
      padding: 0;
    }
    .MuiButtonBase-root {
      width: 2.4rem;
      height: 2.4rem;
      min-width: 2.4rem;
      font-size: 2rem;
      font-weight: 400;
      padding: 0;
      &.xs-btn,&.is-icon-btn {
        width: 2.4rem;
        height: 2.4rem;
        font-size: 1.6rem;
        line-height: 1;
        background: $purple6;
        transition: none;
        &:focus, &:focus-visible, &:visited {
          background: inherit;
          transition: none;
          outline: none;
        }
      }
    }
    .MuiPaper-root {
      .MuiList-root {
        margin-top: 0.4rem;
      }
    }
  }
  .title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0 0 1.2rem 0;
    color: $black;
    letter-spacing: 0.1rem;
    &.space {
      padding: 0 0 .8rem 0;
    }
  }

  .credits {
    display: flex;
    justify-content: space-between;
    padding: 0 0 2.4rem;
    @include bp(smd-max) {
      width: 100%;
      padding: 0 0 1.6rem 0;
    }
    .credit-balance {
      .count {
        font-size: 3.2rem;
        @include line-height(32, 40);
        font-weight: 500;
        padding: 0 0 1.6rem;
        @include bp(smd-max) {
          padding: 0;
        }
      }
    }
    .request-credits {
      a {
        color: $purple1;
        text-decoration: underline;
        font-size: 1.2rem;
        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
  .content-row {
    border-top: 0.1rem solid $grey2;
    padding: 2.4rem 0;
    position: relative;
    &:first-child {
      border-top: 0;
    }
    .corner-link {
      position: absolute;
      right: 0;
      top: 2rem;
      line-height: 0;
      font-size: 1.6rem;
      color: $grey11;
      cursor: pointer;
    }
    &:first-child {
      border-top: 0;
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    @include bp(smd-max) {
      width: 100%;
      padding: 1.6rem 0;
    }
    .owner-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .owner {        
        .user-avatar {
          display: flex;
          align-items: center;
          max-width: 13.6rem;  
          .initials {
            font-size: 1.4rem;
            @include line-height(14,20);
          }
          img {
            margin-right: 0.8rem;
          }
        }
      }
      .member {
        text-align: right;
        .icons-wrap {
          display: inline-flex;
          align-items: center;
          .icon {
            width: 3.6rem;
            height: 3.6rem;
            background: $grey6;
            border-radius: 2.4rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 0.1rem solid $white;
            margin-left: -1rem;
            font-size: 1.4rem;
            font-weight: 500;
            color: $black2;
            cursor: pointer;            
          }
        }
      }
    }
    &.credit-consumed-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .credit-consume {        
        .progress-wrap {
          position: relative;
          .MuiCircularProgress-root {
            color: $purple;
          }
          .progress-val {
            position: absolute;
            top: 2rem;
            left: 1.8rem;
            color: $purple1;
            font-size: 1.5rem;
            @include line-height(15,20);
            font-weight: 500;
          }
        }
      }
      .credit-request {
        font-size: 1.2rem;
        color: $purple1;
        @include line-height(12,16);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
    .space-name {
      font-size: 1.4rem;
      font-weight: 500;
      @include line-height(14,20);
      letter-spacing: 0.014rem;
      max-width: 100%;
      word-wrap: break-word;
    }
    .user-link {
      display: inline-flex;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1.4285714286;
      letter-spacing: 0.01em;
      font-weight: 500;
      cursor: pointer;

      .initials,
      .user-avatar {
        height: 3.6rem;
        width: 3.6rem;
        min-width: 3.6rem;
        border-radius: 50%;
        background: #f3ebff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: 500;
        img {
          border-radius: 50%;
        }
      }
      .user-name {
        padding-left: 1.2rem;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: 0.14px;
        color: $black1;
        word-break: break-word;
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

      }
    }
    .user-detail {
      padding-left: 1.2rem;
      letter-spacing: 0.014rem;
      color: $black1;
      font-weight: normal;
      .user-name {
        padding-left: 0;
        color: $purple1;
      }
      .description-text {
        .time {
          color: $grey5;
        }
      }
    }
    .MuiStack-root {
      flex-wrap: wrap;
      margin-bottom: -8px;
      &.purple-chips {
        .MuiChip-root {
          background-color: $lightPurple !important;
          color: $black1 !important;
          font-weight: 500;
        }
      }
      .MuiChip-root {
        margin: 0 0.8rem 0.8rem 0;
        max-width: fit-content;
        background-color: $lightPurple;
        height: 2.4rem;
        font-size: 1.2rem;
        line-height: 2.4rem;
        .MuiChip-label {          
          font-weight: 500;
          font-size: 1.2rem;
          //text-transform: capitalize;
          color: $black1;
          max-width: 24rem;
        }
      }
    }
    .recent-activity {
      max-height: 100%;
      // overflow: auto;
      // @include custom-scroll;
      strong {
        font-weight: 400 !important;
      }
      &.no-scroll {
        max-height: inherit;
        overflow: inherit;
      }
      .user-link {
        padding: 0.8rem 0;
        font-size: 1.4rem;
        align-items: flex-start;
      }
      @include bp(smd-max) {
        display: flex;
        flex-flow: column;
      }
      .time {
        color: $grey5;
        position: relative;
        padding-left: 10px;
        margin-left: 8px;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 3px;
          width: 3px;
          display: block;
          background: $grey5;
          border-radius: 50%;
        }
      }
    }
    .tags {
      max-width: calc(100% - 3rem);      
      .MuiStack-root {
        .MuiButtonBase-root {          
          position: relative; 
          background: $lightPurple;
          border: 1px solid transparent;
          .MuiChip-label {
            padding: 0.4rem 0.8rem;
            min-width: 5rem;
            text-align: center;
            @extend .transition; 
          }
          .MuiChip-deleteIcon {
            opacity: 0;
            font-size: 0.8rem;
            color: $black1;
            position: absolute;
            right: 0.8rem;
            top: 0.4rem;
            padding: 0.4rem;
            margin: 0;
            line-height: 1;
            display: inline-block;
            background-color: $lightPurple;
            transition: all ease-in 0.1s;
          }
          &:hover {
            //.MuiChip-label {}            
            background: $lightPurple;
            border: 1px solid $purple2;
            cursor: pointer;
            .MuiChip-deleteIcon{
              opacity: 1;
            }
          }
        }
      }
    }
    //Tags
    .search-tag-dropdown-menu {
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15) !important;
      .MuiPaper-root {
        min-width: 17.6rem;
        @include custom-scroll;
        .MuiList-root {
          .MuiButtonBase-root {
            &:hover {
              background: inherit;
              cursor: pointer;
            }
            .MuiStack-root {
              .MuiChip-root {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .base-card-wrap {
    border-bottom: 0.1rem solid grey2;
    margin-bottom: 2.4rem;  
    .base-card {
      border: .1rem solid $grey2;
      border-radius: .8rem;
      padding: 1.6rem;
      .card-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        @include line-height(10, 16);
        letter-spacing: .1rem;
        text-transform: uppercase;
        color: $black1;
        .three-dots {
          .be-vertical-dots {
            cursor: pointer;
            &::before {
              font-size: 1.6rem;
            }
          }
        }
      }
      .card-title {
        padding: .8rem 0 .2rem;
        color: $black1;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        @include line-height(14, 20);
        letter-spacing: .014rem;
      }
      .sub-heading {
        color: $grey4;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        @include line-height(12, 16);
        letter-spacing: .012rem;
        img, .icon {
          margin-right: .4rem;
        }
      }
      .button-wrap {
        padding: 1.6rem 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-join {
          margin: 0 .8rem 0 0;
          width: calc(100% - 4.2rem);
          background: $purple5;
        }
      }
    }
  }
}

.MuiButtonBase-root {  
  &:hover {
    background: inherit;
    cursor: pointer;
  }
}



.owner-row{
  display: flex;
  justify-content: space-between;
  .initials {
    height: 3.4rem;
    width: 3.4rem;
    min-width: 3.4rem;
    border-radius: 50%;
    background: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $white;
    font-size: 1.4rem;
    color: $black2;
    background-color:$grey6;
    font-weight: 500;
    text-transform: uppercase;
    + .initials {
      margin-left: -0.6rem;
    }
    img {
      border-radius: 100%;
    }
    &.mybgcolor {
      background-color: #E1C3FF;
    }
    &.myforecolor {
      background-color: #FFC7B8;
    }
    &.triadic  {
      background-color: #C8F8FF;
    }
  }
  .sub-name{
    display: block;
  }
}

.credit-score{
  .title{
    display: flex;
    justify-content: space-between;
  a{
    text-transform: none;
    font-size: 12px;
  }
}
.progress-with-bg .progress-val{
  font-size: 1.5rem;
}
}
