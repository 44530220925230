$black: #000000;
$white: #ffffff;
$purple: #651fff;
$purple1: #6200ea;
$purple2: #b388ff;
$purple3: #7c4dff;
$purple4: #6200ea;
$purple5: #bda5ff;
$purple6: #eeeeee;
$purple7: #ebebeb;
$lightPurple: #f3ebff;
$lightPurple1: #fbf8ff;
$trueGrey: #595959;
$grey: #c0c3ce;
$grey1: #fafbfc;
$grey2: #dfe0e6;
$grey3: #e8e8e8;
$grey4: #676b7e;
$grey5: #9b9fb1;
$grey6: #ecedf0;
$grey7: #f8f9fa;
$grey8: #d0d2da;
$grey9: #8c8c8c;
$grey10: #d9d9d9;
$grey11: #83889e;
$grey12: #afb2c0;
$grey13: #f5f5f5;
$grey14: #ecedf0;
$grey15: #f7f5ff;
$grey16: #bcb1f5;
$grey17: #f6f1fe;
$grey18: #CDCFD8;
$grey19: #E8DBFF;
$grey20: #FCFBFD;
$green: #00e676;
$green1: #00c853;
$green2: #e2fcea;
$black1: #3c3e49;
$black2: #262626;
$dark-grey: #484848;
$red: #d50000;
$red1: #ff8a80;
$light-red: #fee5e2;
$dark-red: #f7221f;
$red3:#FF5546;
$amber: #ffab00;
$yellow: #ffe57f;
$yellow2: #FFD740;
$pink:#F588FF;
$green2:#B3D380;
$org:#FFB186;
$orange2: #FFF9E1;
$dgreen:#008243;
$lgreen:#EBF5F0;
$lred:#FFECEC;


