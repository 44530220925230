@import "../../assets/scss/colors";
@import "../../assets/scss/mixins";
@import "../../assets/scss/extended-classes";
@import "../../assets/scss/break-points";
@import "../../assets/scss/overview-section";

.supply-chain-distribution {
  &.owner-overview-page {
    // TODO : move to respective section
    .page-container {
      padding-right: 4.8rem;
      .compnay-logo {
        text-align: right;
        img {
          max-width: 7.9rem;
        }
      }
    }
    .overview-page-row {
      .overview-left {
        .overview-welcome {
          .overview-container {
            align-items: flex-start;
            .left-content {
              padding: 0;
              .boxed-title-block {
                .boxed-title {
                  font-size: 4.4rem;
                }
              }
            }

            .video-wrapper {
              width: 54%;
              @include bp(smd-max) {
                width: 100%;
                margin-top: 3rem;
              }
            }
          }
        }
        .no-data-wrapper {
          .grey-btn {
            min-width: 18rem;
          }
        }
      }

      .overview-right {
        .onboarding-steps {
          li {
            margin-bottom: 1.2rem;
            a {
              position: relative;
              background: rgba($grey14, 0.5);
              &.active {
                background: $grey14;
              }
              .step-close-btn {
                position: absolute;
                right: 1.8rem;
                top: 3rem;
                line-height: 0;
                font-size: 1.8rem;
                z-index: 1;
                cursor: pointer;
                &::before {
                  font-weight: 600 !important;
                }
              }
              .step-content {
                width: calc(100% - 6rem);
                .sub-title {
                  color: $grey9;
                }
              }
            }
          }
        }
      }
    }
  }
}

.space-overview-page {
  .page-container {
    padding-right: 0;
    padding-bottom: 0;
    .main-page-header {
      padding-right: 2.4rem;
      margin-bottom: 1.6rem;
    }
    .page-filters-wrapper {
      padding: 0;
      margin: 0 0 1.6rem 0;
      .left-filter {
        &.btn-wrapper {
          display: flex;
          align-items: center;
          .count {
            margin-right: 1.2rem;
            color: $black1;
          }
          .btn-plus {
            min-width: 3.6rem;
            .icon {
              height: 3rem;
              font-size: 1.6rem;
            }
          }
        }
      }
      .right-filter {
        @include bp(smd-max) {
          flex-wrap: nowrap;
          justify-content: flex-end;
        }
        @include bp(xs-max) {
          flex-wrap: wrap;
          justify-content: unset;
        }
        .form-group {
          @include bp(smd-max) {
            //width: 100%;
            width: calc(100% - 16rem);
            margin: 0 0 1.5rem;
            order: -1;
          }
          @include bp(xs-max) {
            width: 100%;
          }
        }
        .btn-add-members {
          width: auto;
          background: $lightPurple !important;
        }
        .btn-plus {
          min-width: 3.6rem;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .search-wrap {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .dropdown-text {
            font-size: 1.4rem;
            font-weight: 400;
            color: $black1;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.14px;
            margin: 0 1.4rem 0 0;
            strong {
              font-weight: 500;
            }
            .icon {
              font-weight: 500;
              display: flex;
              align-items: center;
              margin: 0 0 0 0.4rem;
            }
          }
          .MuiFormControl-root {
            @include bp(lg-min) {
              min-width: 30.4rem;
            }
          }
          .borderless-dropdown {
            display: flex;
            width: 18rem;
            max-width: 18rem;
            align-items: flex-start;
          }
        }
        .btn-wrapper {
          display: flex;
          align-items: center;
          background: $lightPurple;
          border-radius: 0.8rem;
        }
      }
    }
    .overview-inner-section {
      display: flex;
      justify-content: space-between;
      @include bp(smd-max) {
        flex-wrap: wrap;
      }
      .page-content {
        padding-top: 2.4rem;
        padding-right: 2.4rem;
        padding-bottom: 1.6rem;
        width: 100%;
        max-width: calc(100% - 33.4rem);
        @include bp(smd-max) {
          max-width: 100%;  
        }
      }
    }
  }

  // Grid and List view Tabs
  .grid-list-tabs {
    .MuiTabs-root {
      min-height: 3.6rem;
      min-width: 5.2rem;
      background: $lightPurple;
      border-radius: 0.8rem;
      .MuiButtonBase-root {
        min-height: inherit;
        min-width: inherit;
        background: $grey14;
      }
    }
  }
}
.accordion-table-holder {
  .MuiTable-root {
    .MuiTableRow-root {
      td {
        border: 0 !important;
      }
    }
  }
  .MuiAccordionSummary-root {
    min-height: 6.4rem;
    &.Mui-expanded {
      .space-ac-wrapper {
        .ac-left {
          .arrow-icon {
            @include rotate(90deg);
          }
        }
      }
    }
  }
  .table-wrapper {
    border-radius: 0 0 0.8rem 0.8rem;
  }
  .space-ac-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .ac-left {
      display: inline-flex;
      align-items: center;
      width: 40%;
      @include bp(xlg-max) {
        width: 100%;
      }
      .arrow-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        width: 2rem;
        font-size: 1rem;
        @extend .transition;
      }
      .text-lable {
        font-size: 1.6rem;
        line-height: 2rem;
        padding: 0 0.8rem;
        font-weight: 500;
        max-width: calc(100% - 2rem);
        color: $black1;
        text-transform: capitalize;
      }
      .rating {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .ac-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.6rem;
      width: 60%;
      @include bp(xlg-max) {
        width: 100%;
        justify-content: flex-start;
        padding: 1.5rem 0 0 2.8rem;
      }
      .inner-data {
        margin-right: 1.6rem;
        &:last-child {
          margin-right: 0;
        }
        .primary-link {
          font-size: 1.4rem;
          line-height: 2rem;
        }
        .tag-wrapper {
          display: flex;
          align-items: center;
          .custom-tag {
            margin-right: 0.8rem;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .custom-tag {
          line-height: 2.4rem;
          white-space: nowrap;
          text-transform: none;
          font-size: 1.2rem;
          letter-spacing: 0.012rem;
          max-width: 16.5rem;
          @extend .truncated-text;
          cursor: pointer;
        }
        .counts {
          display: inline-flex;
          align-items: center;
          color: $grey4;
          font-weight: 500;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
          .value {
            color: $black;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}
// Accordion Wrapper
.accordion-wrapper {
  margin: 0 0 1.6rem 0;
  .MuiAccordionSummary-content {
    margin: 0.8rem 0;
  }
  > .MuiPaper-root {
    margin: 0 0 2.4rem 0;
    border-radius: 0.8rem;
    border: 1px solid $grey2;
    background: $white;
    box-shadow: none;
    z-index: 1;
    > .MuiButtonBase-root {
      &:hover {
        border-radius: inherit;
      }
    }
  }

  // Accordion head table wrapper
  .accordion-head-table-wrap {
    padding: 0;
    background: $white;
    > .MuiTableContainer-root {
      > .MuiTable-root {
        > .MuiTableBody-root {
          > .MuiTableRow-root {
            > .MuiTableCell-root {
              color: $black;
              font-size: 1.2rem;
              border: 0;
              .user-link {
                text-transform: capitalize;
                border: 0;
                color: $black1;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 500;
                @include line-height(16, 24);
                display: flex;
                align-items: center;
                .icon {
                  line-height: 0;
                }
                .text-lable {
                  padding-left: 0.8rem;
                  letter-spacing: 0.014rem;
                  @include line-height(14, 20);
                }
                img {
                  padding-left: 0.8rem;
                }
              }
              .number-title {
                text-transform: uppercase;
                color: $grey4;
                font-size: 1.2rem;
                font-weight: 500;
                @include line-height(12, 16);
              }
              .MuiStack-root {
                .MuiChip-root {
                  margin-right: 2.4rem;
                  .MuiChip-label {
                    font-weight: 500;
                  }
                }
              }
              a {
                text-decoration: underline;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }

  // Expended accordion list table data
  .MuiCollapse-root {
    .MuiCollapse-wrapper {
      .MuiCollapse-wrapperInner {
        .MuiAccordion-region {
          .MuiAccordionDetails-root {
            padding: 0;

            // Expended list table-wrap
            .expended-list-table-wrap {
              > .MuiTableContainer-root {
                > .MuiTable-root {
                  > .MuiTableHead-root {
                    > .MuiTableRow-root {
                      > .MuiTableCell-root {
                        border: 0;
                        color: $black;
                        font-size: 10px;
                        font-weight: 500;
                        @include line-height(10, 16);
                        letter-spacing: 0.1rem;
                        text-transform: uppercase;
                        background: $grey1;
                        text-align: center;
                        &:first-child {
                          padding: 0 0 0 1.6rem;
                          text-align: left;
                        }
                        &:last-child {
                          padding: 0 1.6rem 0 0;
                        }
                        .icon {
                          &::before {
                            color: $grey11;
                          }
                        }
                      }
                    }
                  }
                  > .MuiTableBody-root {
                    > .MuiTableRow-root {
                      > .MuiTableCell-root {
                        border: 0;
                        color: $black;
                        font-size: 1.4rem;
                        font-weight: 400;
                        @include line-height(14, 20);
                        letter-spacing: 0.014rem;
                        text-align: center;
                        &:first-child {
                          padding: 0 0 0 1.6rem;
                          text-align: left;
                        }
                        &:last-child {
                          padding: 0 1.6rem 0 0;
                        }
                        //Tags
                        .MuiStack-root {
                          display: inline-block;
                          .MuiChip-root {
                            padding: 0;
                            height: 2rem;
                            width: 13.5rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @extend .truncated-text;
                            .MuiChip-label {
                              color: $white;
                              font-size: 1rem;
                              font-weight: 500;
                              @include line-height(10, 16);
                              letter-spacing: 0.1rem;
                              text-transform: uppercase;
                            }
                          }
                        }
                        
                        .progress-circle {
                          position: relative;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          height: 1.5rem;
                          cursor: pointer;
                          .progress-bar {
                            height: 10px;
                          }
                          &:hover {
                            .credits {
                              display: block;
                            }
                          }
                        }
                        .MuiButtonBase-root {
                          &.is-icon-btn{
                            &.purple-btn {
                              width: 2.8rem;
                              height: 2rem;
                              border-radius: 1.2rem;
                              font-size: 1rem;
                              font-weight: 400;
                              .be-tag-pin {
                                width: 1.2rem;
                                &::before {
                                  font-size: 1.2rem;
                                }
                              }
                            }
                          }
                        }
                      }
                      &:last-child {
                        .MuiTableCell-root {
                          border: 0;
                        }
                      }
                    }
                  }
                }
              }
            }

            // Grid view cards wrapper
            .grid-view-cards-wrapper {
              padding: 1.6rem;
              .data-container {
                margin-bottom: -1.6rem;
                .card-template {
                  margin-bottom: 1.6rem;
                  background: linear-gradient(102deg,#fbf9ff .94%,#f5eefe 74.96%,rgba(243,235,255,0) 155.42%);
                  display: flex;
                  flex-direction: column;
                  height: calc(100% - 1.6rem);
                  &:hover {
                    border: 1px solid $purple2;
                    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);                    
                  }
                  .card-body {
                    background: transparent;
                    flex: 1;
                    .tag-container {
                      padding: 0 0 .8rem 0;
                      .MuiStack-root {
                        .MuiChip-root {
                          height: 2rem;
                          padding: 0;
                          .MuiChip-label {
                            padding: 0 0.8rem;
                          }
                        }
                      }
                    }                    
                  }
                  .card-footer {
                    background: $white;
                    @extend .be-radius;
                    margin-top: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // Expended accordion list table data  - close
}

// Tooltip popover
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    width: auto;
    min-width: auto;
    border-radius: 8px;
    box-shadow:
      0px 1px 4px 0px rgba(0, 0, 0, 0.08),
      0px 4px 20px 0px rgba(0, 0, 0, 0.06);
  }
}

.taglist-menu {  
  .MuiMenu-list {
    padding: 1.6rem;
    .tag-row {
      margin-bottom: 1.6rem;
      &:last-child {
        margin-bottom: 0;
      }
      .custom-tag {
        @extend .truncated-text;
        display: block;
        font-size: 1.2rem;
        line-height: 2.4rem;
        text-transform: none;
        letter-spacing: 0.012rem;
        cursor: pointer;
      }
    }    
  }
}

.page-filters-wrapper {
  .right-filter {
    .search-input {
      width: 30.4rem;
      max-width: 100%;
    }
    .button-grouping {
      max-height: 3.6rem;
    }
  }
}

