h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 0 1.5rem;
  padding: 0;
  font-weight: normal;
}

p {
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }
}

h1,
.h1 {
  font-size: 2.8rem;
  @include line-height(28, 36);
  font-weight: 500;
  @include bp(lg-max) {
    font-size: 2.5rem;
  }
  &.lg {
    font-size: 3.2rem;
    @include line-height(32, 40);
    @include bp(lg-max) {
      font-size: 3rem;
    }
    @include bp(smd-max) {
      font-size: 2.8rem;
    }
    @include bp(sm-max) {
      font-size: 2.6rem;
    }
  }
}

h2, .h2 {
  font-size: 2.4rem;
  @include line-height(24, 32);
  font-weight: 500;
  @include bp(lg-max) {
    font-size: 2.2rem;
  }
}

h3, .h3 {
  font-size: 2.2rem;
  @include line-height(22, 32);
}

h4, .h4 {
  font-size: 2rem;
  @include line-height(20, 32);
  font-weight: 500;
}

h5, .h5 {
  font-size: 1.8rem;
  @include line-height(18, 28);
  font-weight: 500;
}

h6, .h6 {
  font-size: 1.6rem;
  @include line-height(16, 24);
  font-weight: 500;
}
