@charset "UTF-8";

@font-face {
  font-family: "builder-enterprise";
  src: url("../fonts/builder-enterprise.eot");
  src:
    url("../fonts/builder-enterprise.eot?#iefix") format("embedded-opentype"),
    url("../fonts/builder-enterprise.woff") format("woff"),
    url("../fonts/builder-enterprise.ttf") format("truetype"),
    url("../fonts/builder-enterprise.svg#builder-enterprise") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "builder-enterprise" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="be-"]:before,
[class*=" be-"]:before {
  font-family: "builder-enterprise" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.be-house:before {
  content: "\61";
}
.be-eye:before {
  content: "\62";
}
.be-pie-line-graph:before {
  content: "\63";
}
.be-buildcard:before {
  content: "\65";
}
.be-eye-off:before {
  content: "\66";
}
.be-search:before {
  content: "\68";
}
.be-tick:before {
  content: "\69";
}
.be-folder:before {
  content: "\6d";
}
.be-tooltip:before {
  content: "\6e";
}
.be-down-arrow:before {
  content: "\6f";
}
.be-share:before {
  content: "\71";
}
.be-copy:before {
  content: "\72";
}
.be-add-circle:before {
  content: "\75";
}
.be-star:before {
  content: "\76";
}
.be-right-arrow:before {
  content: "\77";
}
.be-file-landscape:before {
  content: "\78";
}
.be-account-settings:before {
  content: "\42";
}
.be-automations:before {
  content: "\43";
}
.be-bill-payments:before {
  content: "\44";
}
.be-credit-card:before {
  content: "\45";
}
.be-roles-permission:before {
  content: "\46";
}
.be-builder-logo-b:before {
  content: "\64";
}
.be-building:before {
  content: "\67";
}
.be-caret-right:before {
  content: "\6a";
}
.be-check-circle:before {
  content: "\6b";
}
.be-close:before {
  content: "\6c";
}
.be-info:before {
  content: "\70";
}
.be-integrations:before {
  content: "\73";
}
.be-multiple-users:before {
  content: "\74";
}
.be-pdf-file:before {
  content: "\7a";
}
.be-upload:before {
  content: "\41";
}
.be-upload-img:before {
  content: "\47";
}
.be-user-group:before {
  content: "\48";
}
.be-user:before {
  content: "\4b";
}
.be-close-circle:before {
  content: "\4c";
}
.be-send-email:before {
  content: "\4d";
}
.be-close-dark:before {
  content: "\4f";
}
.be-tick-square:before {
  content: "\50";
}
.be-upload-cloud:before {
  content: "\51";
}
.be-archive:before {
  content: "\53";
}
.be-vertical-dots:before {
  content: "\49";
}
.be-notification:before {
  content: "\79";
}
.be-down-arrow-1:before {
  content: "\56";
}
.be-archive-disabled:before {
  content: "\57";
}
.be-plus:before {
  content: "\54";
}
.be-chevron-down:before {
  content: "\4e";
}
.be-edit:before {
  content: "\55";
}
.be-play:before {
  content: "\58";
}
.be-download:before {
  content: "\4a";
}
.be-box-arrow-down:before {
  content: "\5a";
}
.be-corner-arrow:before {
  content: "\30";
}
.be-left-arrow:before {
  content: "\59";
}
.be-upload-document:before {
  content: "\31";
}
.be-top-arrow-box:before {
  content: "\32";
}
.be-edit-box:before {
  content: "\33";
}
.be-pin:before {
  content: "\34";
}
.be-mic:before {
  content: "\35";
}
.be-meetings:before {
  content: "\37";
}
.be-buildcard-box:before {
  content: "\38";
}
.be-flag:before {
  content: "\39";
}
.be-folder-outlined:before {
  content: "\52";
}
.be-folder-1:before {
  content: "\36";
}
.be-whiteboard:before {
  content: "\21";
}
.be-calendar-plus:before {
  content: "\22";
}
.be-link-chain:before {
  content: "\23";
}
.be-table:before {
  content: "\24";
}
.be-card-group:before {
  content: "\25";
}
.be-grid:before {
  content: "\24";
}
.be-list:before {
  content: "\25";
}
.be-card-group:before {
  content: "\26";
}
.be-power-enable:before {
  content: "\27";
}
.be-pathfinder-merge:before {
  content: "\28";
}
.be-expand:before {
  content: "\29";
}
.be-right-arrow-thin:before {
  content: "\2a";
}
.be-location:before {
  content: "\2b";
}
.be-calendar:before {
  content: "\2c";
}
.be-trending:before {
  content: "\2d";
}
.be-tag-pin:before {
  content: "\2e";
}
.be-business-goals:before {
  content: "\2f";
}
