@import "../../../assets/scss/colors";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/break-points";
@import "../../../assets/scss/extended-classes";

.rouded-tag {
	font-size: 12px;
	font-weight: 500;
	color: $dgreen;
	border: 1px solid #B3DAC7;
	border-radius: 3.2rem;
	padding: 0.2rem 0.8rem;
	background-color: $lgreen;
}

.credits-page {
	.page-filters-wrapper {
		align-items: flex-end;
	}

	.credits-right-filter {
		display: flex;

		@include bp(sm-max) {
			margin-top: 1rem;
		}

		button {
			height: 4.8rem;
			font-size: 1.4rem;
			color: $black1;
			margin-left: 1.5rem;

			@include bp(smd-max) {
				margin-right: 1.5rem;
				margin-left: 0;

			}

			&.border-btn {
				background-color: $white;
				border: 1px solid rgba(179, 136, 255, 0.3);
			}
		}
	}

	.page-top-navigation {
		height: auto;

		button {
			padding-left: 0;
			min-width: inherit;
			padding-right: 0;
			margin-right: 4.7rem;
			text-transform: none;
			font-size: 1.6rem;
			color: $black1;
			font-weight: 400;

			&.Mui-selected {
				color: $purple1;
				font-weight: 500;
			}
		}
	}

	.total-credits-block {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1rem;
		list-style: none;
		padding: 0;

		li {
			width: 33.33%;
			padding: 0 1rem;

			@include bp(smd-max) {
				width: 100%;
				margin-bottom: 2rem;
			}

			.credits-box {
				background-color: $lightPurple;
				border: 1px solid $purple;
				display: flex;
				justify-content: space-between;
				padding: 2.5rem 2.5rem 1.5rem;
				align-items: center;
				height: 100%;

				@include bp(smd-max) {
					padding: 1.5rem 2.5rem 1.0rem;
				}

				.credits-left {
					p {
						font-size: 1.6rem;
						line-height: 2.4rem;
						color: $black1;
						font-weight: 400;
						margin-bottom: 1rem;
					}

					.total {
						font-size: 3.2rem;
						color: $black1;
						font-weight: 500;
						margin-bottom: 0;

						@include bp(smd-max) {
							font-size: 2.5rem;
						}

						sub {
							font-size: 1.4rem;
							color: $purple1;
							font-weight: 400;
							position: relative;
							bottom: 9px;
							display: inline-block;
							padding-left: 1rem;
						}
					}

					em {
						font-size: 1.2rem;
						color: $black1;
						font-weight: 300;
						position: relative;
						top: 1px;
					}
				}

				.credits-right {
					.thumb {
						max-width: 7rem;
						display: block;
					}
				}
			}
		}
	}

	.credits-content-block {
		padding: 2.4rem 0;

		.right-filter {
			max-width: 30.3rem;
			margin-bottom: 3rem;
		}

		&.credits-history {
			padding-top: 0;
			margin: 0 -4.8rem;
			padding-bottom: 0;

			@include bp(sm-max) {
				margin: 0 -2rem;
			}

			.right-filter {
				margin-bottom: 0;
				width: 30rem;

				.form-group {
					margin-bottom: 0;
				}

			}
		}

		.user-link {
			display: inline-flex;
			align-items: center;
			font-size: 1.4rem;
			line-height: 1.4285714286;
			letter-spacing: 0.01em;
			font-weight: 500;
			cursor: pointer;

			.initials,
			.user-avatar {
				height: 3.6rem;
				width: 3.6rem;
				min-width: 3.6rem;
				border-radius: 50%;
				background: #f3ebff;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				font-size: 1.2rem;
				font-weight: 700;
				text-transform: uppercase;
				font-style: normal;

				img {
					border-radius: 50%;
				}
			}

			.user-name {
				padding-left: 1.2rem;
			}
		}
	}

	.credit-allocation-row {
		display: flex;
		flex-wrap: wrap;
		margin: 3rem -1.2rem;

		@include bp(sm-max) {
			margin-bottom: 0;
		}

		.credit-allocation-col {
			width: 50%;
			padding: 0 1.2rem 0;

			@include bp(sm-max) {
				width: 100%;
				margin-bottom: 2rem;
			}

			span {
				font-size: 1.2rem;
				color: $black1;
				font-weight: 400;
				display: block;
			}

			.pie-chart {
				display: block;
				text-align: center;
				margin-top: 1.3rem;

				img {
					width: 100%;
				}
			}

			h4 {
				margin-bottom: 0;
			}
		}
	}

	.card {
		cursor: pointer;
		border: 1px solid $grey2;
		border-radius: 0 0 0 2rem;
		padding: 2.4rem;
		height: 100%;
		position: relative;
		background-color: $white;
	}

	.credit-spaces {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $grey2;
		align-items: center;
		margin-bottom: 2rem;
		// margin-top: 2rem;

		.right-filter {
			display: flex;

			.form-group {
				background-color: #F3EBFF;
				border-radius: 0.4rem;
				font-size: 1.4rem;
				color: $black1;
				padding: 0 0.8rem;
				margin-left: 1.5rem;

				.MuiInputBase-root {
					border: 0;
				}
			}

			.be-archive {
				&:hover {
					border-color: $lightPurple;
					background-color: rgba(179, 136, 255, 0.3);
				}
			}
		}
	}

	.total-credits {
		font-size: 12px;
		color: $black1;
		font-weight: 400;
		padding-right: 5px;
		position: relative;
		top: -2px;
	}

	.requested-block {
		margin-bottom: 2.5rem;

		.user-link {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.5rem;

			@include bp(sm-max) {
				flex-wrap: wrap;

			}


			.user-icon {
				display: flex;
				width: 60%;

				.users-box {
					width: 3.2rem;
					height: 3.2rem;
					background-color: $pink;
					border-radius: 0.4rem;
					font-size: 1.5rem;
					color: $white;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					margin-right: 1rem;

					.icon {
						display: flex;
					}

					&.green {
						background: $green2;
					}

					&.org {
						background-color: $org;
					}

					&.grey {
						background-color: $grey;
					}
				}

				h5 {
					font-weight: 400;

				}

				.warning {
					position: relative;
					margin-left: 0.7rem;
					position: relative;
					top: 0.5rem;
				}

				&.truncated-text {
					width: 90%;
				}
			}

			.user-name {
				display: flex;
				align-items: center;

				@include bp(xs-max) {
					width: 100%;
				}

				em {
					margin-right: 0.8rem;
				}
			}

			.archive-icon {
				padding-left: 0.6rem;
				position: relative;
				top: 0.5rem;
				font-size: 1.7rem;
			}


		}

		.progress-bar {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.progressbar-num {
				padding-left: 1rem;
				font-size: 1.4rem;
				color: $black1;
				font-weight: 400;
			}

			.MuiLinearProgress-root {
				background: $lightPurple;
				height: 0.6rem;

				.MuiLinearProgress-bar {
					background-color: $purple2;
				}
			}
		}
	}

	.manage-credits-row {
		display: flex;
		align-items: self-start;
		flex-wrap: wrap;

		.manage-credits-col-8 {
			width: 58%;

			@include bp(smd-max) {
				width: 100%;
			}
		}

		.manage-credits-col-4 {
			width: 42%;
			padding-left: 2.2rem;
			position: sticky;
			top: 0;

			@include bp(smd-max) {
				width: 100%;
				padding-left: 0;
			}

			.total-credits {
				display: block;
				text-transform: uppercase;
				font-size: 1.0rem;
				letter-spacing: 1px;
				position: static;
			}

			.credit-balance {
				font-size: 3.2rem;
				font-weight: 500;
				line-height: 2.8rem;
				margin-bottom: 2rem;
			}
		}

		.manage-block {
			margin-bottom: 0.5rem;

			.progressbar-num {
				font-size: 14px;
				color: $black1;
				font-weight: 400;

				strong {
					font-weight: 500;
				}
			}

			h5 {
				font-size: 2rem;
			}

			.truncated-text {
				width: 90%;
			}

		}

		.manage-card {
			padding: 1.5rem;
			margin-bottom: 3rem;

			.credits-edit {
				display: flex;
				flex-wrap: wrap;
				justify-content: end;
				align-items: center;
				font-size: 14px;
				width: 40%;

				@include bp(sm-max) {
					justify-content: start;
					width: 100%;
				}

				h2 {
					margin: 0 1rem;

				}

				.purple-btn {
					font-size: 1.7rem;

					&:hover {
						border: 2px solid $purple2;
					}
				}

				.press {
					font-size: 1.0rem;
					letter-spacing: 1px;
					text-transform: uppercase;
					position: relative;
					top: 0.8rem;

					@include bp(sm-max) {
						justify-content: start !important;
					}
				}
			}

			.progressbar-num {
				font-size: 14px;
				margin: 1.8rem 0 1rem;
			}

			.MuiInputBase-formControl {
				width: 86px;
				height: 36px;
				margin-left: 1rem;

				input {
					text-align: center;
					font-size: 20px;
				}
			}
			&.no-search-results{
				border: 0.1rem dashed $grey2;
				padding: 6rem 0;
			}
		}
	}

	.space-manager {
		background-color: $lightPurple;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 3.5rem 0.7rem;
		border-radius: 0 0 0 2rem;
		margin: -2rem 0 2rem;
		flex-wrap: wrap;

		.left-col {
			display: flex;
			align-items: center;
			font-size: 12px;
			color: $black1;

			strong {
				font-weight: 600;
				margin: 0 0.6rem;
			}
		}

		.right-col {
			a {
				font-size: 1.2rem;
				color: $purple1;
				text-decoration: underline;
				font-weight: 500;
				margin: 0 0.8rem;
			}
		}

		.icon {
			position: relative;
			top: 0.3rem;
			margin-left: 0.8rem;
		}
	}

	.main-page-header {
		.page-breacrumb {
			.compnay-logo {
				width: 7rem;
			}
		}
	}
	.page-container.top-space{
		padding-bottom: 0;
	}
}

.feedback-dialog {
	.form-group {
		margin: -15px 0 2.5rem;

		.form-control {
			border: 1px solid $grey;
			width: 100%;
			height: 21.1rem;
			resize: none;
			font-size: 14px;
			border-radius: 0.4rem;
			color: $grey12;
			padding: 2rem 1.5rem 1.5rem;
		}
	}

	button {
		width: 100%;
	}

	h2 {
		color: $black;
	}

	.modal-btn-block {
		margin: 0;

		button {
			height: 4.8rem;
			font-size: 14px;
			color: $black2;
		}
	}

	p {
		font-size: 14px;
		font-weight: 400;
		color: $black1;
		margin: 2.3rem 0 5.5rem;
	}
}

.CompanyApproved {
	.no-btns {
		padding: 3rem !important;
	}

	.feedback-dialog {
		.modal-title {
			padding-right: 3rem;
		}

		.form-group {
			margin-bottom: 0;
		}
	}
}

.allocate-dialog {
	.MuiFormControl-root {
		margin: 3.5rem 0 5rem;
	}

	p {
		margin: -3.5rem 0 5rem 0;
	}
}

.credits-history {
	padding-top: 0 !important;

	.history-list-table {
		.be-multiple-users {
			background-color: $pink;
			color: $white;
			width: 2.4rem;
			height: 2.4rem;
			border-radius: 0.4rem;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: relative;
			top: 2px;
			margin-right: 0.4rem;
		}

		thead {
			th {
				background-color: $grey7;
				border-top: 1px solid $grey6;
				color: $grey11;
			}
		}

		th,
		td {
			padding: 0 4.8rem !important;
			height: 4.8rem;

			@include bp(sm-max) {
				padding: 0 2rem !important;
			}

			img {
				float: left;
				margin: -0.8rem 0.6rem 0px 0;
				position: relative;
				top: 7px;
				border-radius: 50%;
			}

			&:first-child {
				width: 15%;

				@include bp(xl-max) {
					width: auto;
				}
			}

			&:nth-child(2) {
				width: 27%;
				padding-right: 8% !important;
			}

			&:nth-child(3) {
				width: 26%;
			}

			&:nth-child(24) {
				width: 26%;
			}
		}

		tr:nth-child(even) {
			background-color: #FCFBFD;
		}

		table {
			@include bp(lg-max) {
				width: 1505px;

			}
		}
		.no-search-results{
			position: relative;
			margin-top: 3.5rem;
		}
		.MuiTable-root .MuiTableRow-root:last-child td {
			border-bottom: 0;
		}
	}

	.allocation-header {
		background-color: $white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1.5rem 4.8rem;
		flex-wrap: wrap;

		@include bp(sm-max) {
			padding: 1.5rem 2rem;
		}

		h4 {
			margin-bottom: 0;

			@include bp(sm-max) {
				margin-bottom: 1rem;
			}
		}
	}

	.custom-pagination-wrapper {
		background-color: $white;
		align-items: center;
		margin-top: 0;
		padding: 2rem 4.8rem;

		@include bp(sm-max) {
			padding: 2rem;
		}

		p {
			margin-bottom: 0;

			@include bp(sm-max) {
				margin-bottom: 1rem;
			}
		}
	}

	.plus,
	.minus {
		@extend .rouded-tag;
	}

	.minus {
		color: $red;
		background-color: $lred;
		border-color: #FFD7D7;
	}
}

.highcharts-legend-item {
	font-style: normal !important;

	text {
		color: $black1 !important;
		font-weight: 400 !important;
		// font-size: 12px !important;
	}
}

// Chart tooltip
.chart-tooltip {
	box-shadow: 0 0.4rem 1.6rem 0 #00000026;
	margin: 0 0;
	border-radius: 0.4rem;
	background: $white;
	max-height: 24rem;
	overflow-x: hidden;
	overflow-y: auto;
	@include custom-scroll;

	.head {
		padding: 0.8rem 1.6rem;
		font-size: 1.2rem;
		font-weight: 500;
		line-height: 1.6rem;
		color: $dark-grey;
		border-bottom: 1px solid $grey2;
		word-break: break-all;
		text-transform: none;

		&::first-letter {
			text-transform: uppercase;
		}
	}

	.body {
		padding: 0.8rem 1.6rem;

		.content-row {
			margin-bottom: 0.8rem;

			&:last-child {
				margin-bottom: 0;
			}

			.title {
				color: $grey11;
				font-size: 1rem;
				line-height: 1.6rem;
			}

			.count {
				color: $black1;
				font-size: 1.2rem;
				font-weight: 500;
				line-height: 1.6rem;
			}

			.tag-wrapper {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				max-width: 25rem;
				visibility: hidden;

				.custom-tag {
					visibility: visible;
					padding: 0.2rem 0.8rem;
					margin: 0 0.8rem 0.8rem 0;
					color: $black1;
					background: $lightPurple;
					font-size: 1.2rem;
					font-weight: 500;
					line-height: 1.6rem;
					border-radius: 1.2rem;
					max-width: 19.2rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					text-transform: none;

					&:last-child {
						margin: 0;
					}

					&::first-letter {
						text-transform: uppercase;
					}
				}
			}
		}
	}
}

.highcharts-tooltip {
	pointer-events: auto !important;
}

.no-allocations {
	padding: 9rem 1.5rem;
	margin: 2.5rem 0;

	button {
		background-color: $lightPurple;
		height: 4.8rem !important;
	}
}
.spaces-credits-page {
	> .page-container {
		padding-right: 0;
		> .main-page-header {
			padding-right: 2.4rem;
			margin-bottom: 1.6rem;
		}
		.projects-top-nav-wrapper {
			.page-top-navigation {
				ul {
					padding-bottom: 0;
				}
			}
		}
	}
}
.main-content-wrap {
	margin: 0;
	padding: 0 !important;

	&.full-width {
		width: calc(100% + 2.4rem);
		margin-left: -2.4rem;
		margin-right: 0;
	}

	.overview-inner-section {
		display: flex;
		justify-content: space-between;
		@include bp(smd-max) {
			display: block;
		}
		.page-content {
			width: 100%;
			max-width: calc(100% - 33.4rem);
			padding: 2.4rem;
			@include bp(smd-max) {
				max-width: 100%;
			}
			.section-row {
				margin: 2.4rem 0 0;

				.title-wrap {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					h4 {
						line-height: 2.4rem;
					}
					h5 {
						margin-bottom: 0;						
						@extend .truncated-text;
					}

					span {
						font-size: 1.2rem;
						color: $black1;
						@include line-height(12, 16);

						&.credit-consuption {
							color: $grey11;
							.warning {
								color: $red;
							}
						}
					}

					.button-wrap {
						display: flex;
						align-items: center;

						button {
							margin-left: 1.4rem;
						}
					}
				}

				.progress-wrap {
					padding: 2.4rem 0;

					.MuiBox-root {
						.MuiLinearProgress-root {
							border-radius: 0;
							background-color: $white;
							border: 1px solid $purple2;
							height: 1.2rem;

							.MuiLinearProgress-bar {
								background-color: $purple2;
							}
						}
					}
				}

				.count-wrap {
					display: flex;
					align-items: center;
					justify-content: space-between;

					span {
						color: $black1;
						font-size: 1.2rem;
						@include line-height(12, 16);
						display: inline-flex;
						align-items: center;
					}

					.left {
						span {
							margin-right: 1.6rem;
						}

						.square-dot {
							height: 1rem;
							width: 1rem;
							border: 1px solid $black1;
							background: $white;
							margin-right: 0.8rem;
							display: inline-flex;
							border-radius: 0.125rem;

							&.purple {
								background: $purple2;
								border-color: $purple2;
							}
						}
					}

					.right {
						strong {
							font-size: 2rem;
							@include line-height(20, 28);
						}
					}
				}

				.graph-wrap {
					margin: 2.4rem 0 0;
				}

				.tag-wrapper {
					.custom-tag {
						background: $lightPurple;
						padding: 0.4rem 0.8rem;
						color: $black1;
						font-size: 1.2rem;
						@include line-height(12, 16);
						font-weight: 500;
						text-transform: lowercase;
						max-width: 19.8rem;
						letter-spacing: 0.001rem;
						@extend .truncated-text;
						margin-right: 0.8rem;

						&::first-letter {
							text-transform: uppercase;
						}

						&:last-child {
							margin-right: 0;
						}
					}
				}

				.credit-estimated {
					color: $grey4;
					font-size: 1.4rem;
					display: flex;
					align-items: center;
					span {
						@extend .truncated-text;
					}
					img.warning-icon {
						margin-left: 0.8rem;
					}
				}

				.credits-list {
					.list-content {
						margin-bottom: 2.4rem;

						&:last-child {
							margin-bottom: 0;
						}
					}

					.progress-wrap {
						padding: 0.8rem 0 0;

						.MuiBox-root {
							.MuiLinearProgress-root {
								height: 0.6rem;
								border-radius: 0.8rem;
								background: $lightPurple;
								border: 0;

								.MuiLinearProgress-bar {
									border-radius: 0.8rem;
									background: $purple2;
								}
							}
						}

						&.warning {
							.MuiBox-root {
								.MuiLinearProgress-root {
									.MuiLinearProgress-bar {
										background: $red3;
									}
								}
							}
						}
					}
				}
			}
		}
		.summary {
			@include bp(smd-max) {
				width: calc(100% - 4.8rem);
				margin-left: 2.4rem;
			}			
		}
	}
}

.estimated-consuption-tooltip {
	max-width: 25.3rem;
  min-width: auto;
}

.credit-history-table {
	min-height: 40rem;
	
	.link-text {
		color: $purple1;
		font-size: 1.2rem;
		font-weight: 500;

		&.secondary {
			font-size: 1.4rem;
		}
	}

	.plus,
	.minus {
		@extend .rouded-tag;
	}
	
	.MuiTableContainer-root {
		min-width: 62rem;
		.MuiTable-root {
			.MuiTableHead-root {
				background: $grey7;

				.MuiTableRow-root {
					.MuiTableCell-root {
						height: 6.4rem;
						&:first-of-type {
							padding-left: 4rem;
						}
					}
				}
			}

			.MuiTableBody-root {
				.MuiTableRow-root {
					.MuiTableCell-root {
						height: 6.4rem;
						&:first-of-type {
							padding-left: 4rem;
						}
					}

					&:nth-child(even) {
						.MuiTableCell-root {
							background: $grey20;
						}
					}

					// &:last-child {
					// 	.MuiTableCell-root {
					// 		border: 0;
					// 	}
					// }
				}
			}
		}
	}

	.tag-wrapper {
		.MuiStack-root {
			.MuiChip-root {
				margin: 0;
				padding: 0.2rem 0.8rem;
				height: 2rem;
				border-radius: 2rem;

				.MuiChip-label {
					padding: 0;
					color: $white;
					font-size: 1rem;
					font-weight: 500;
					@include line-height(10, 16);
					text-transform: uppercase;
					max-width: 20rem;
					@extend .truncated-text;
				}
			}
		}
	}
}

.credit-history-modal {
	.modal-close {
		top: 2.6rem !important;
	}
	.modal-body-wrap {
		padding: 0 !important;
		.modal-body {
			.modal-title {
				h2 {
					font-size: 2rem;
				}
				margin: 0;
				padding: 2rem 4rem 1.6rem;
			}
		}
	}
}
.cancel-request-modal {
	.modal-body-wrap {
		.modal-body {
			.modal-title {
				margin-bottom: 5.2rem;
				h2 {
					font-size: 2.4rem;
					margin-bottom: 0.8rem;
				}
				p {
					font-size: 1.6rem;
				}
			}
		}
	}
}
.request-credit-modal {
	.MuiPaper-root {
		max-width: 51.6rem !important;
	}
	::placeholder {
		color: $grey11;
	}
	.modal-body-wrap {
		.modal-body {
			.modal-title {
				margin-bottom: 2.4rem;
				h2 {
					font-size: 2rem;
					margin-bottom: 0;
				}
			}
			.modal-form {
				.form-group {
					margin-bottom: 2.4rem;
					> label {
						color: $black1;
						font-size: 1.6rem;
						font-weight: 500;
						margin-bottom: 0.8rem;
						display: flex;
					}
					.MuiFormControl-root {
						label {
							color: $grey11;
						}
					}
				}
				.credit-note {
					background: $orange2;		
					padding: 1.2rem 1.6rem;			
					border-left: 0.4rem solid $amber;
					border-radius: 0.4rem;
					p {
						font-size: 1.2rem;
						@include line-height(12,16);
						color: $black1;
					}
				}
			}
		}
	}
}

