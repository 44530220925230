@import "../../../assets/scss/colors";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/extended-classes";
@import "../../../assets/scss/break-points";

// Project meetings tab content sections
.project-meetings-page {
	padding: 3.2rem 0 0;
	@include bp(smd-max) {
		margin-bottom: 2.4rem;
	}
	.tab-content-wrapper {
		.MuiBox-root {
			border: 0 none;
			.MuiTabs-root {
				.MuiTabs-fixed {
					.MuiTabs-flexContainer {
						width: fit-content;
						background: $grey13;
						border-radius: .8rem;
						.MuiTab-root {
							padding: 6px 12px;
							color: $black1;
							text-transform: none;
							letter-spacing: 0.014rem;
							@include line-height(14, 20);
							font-weight: 400;
							&.Mui-selected {
								background: $lightPurple;
								color: $purple1;
								font-size: 1.4rem;
								font-style: normal;
								font-weight: 500;
								border-radius: .8rem;
								letter-spacing: 0.014rem;
							}
						}						
					}
					.MuiTabs-indicator {
						background: none;
						height: 0;
					}
				}
			}
		}
		// Tab content sections
		.MuiTabPanel-root {
			padding: 3.2rem 0;

		}
		.tab-content-section {
			.tab-content-row {
				display: flex;
				align-items: center;
				padding: 1.6rem;
				border: 1px solid $grey2;
				border-radius: .4rem;
				background: $white;
				margin-bottom: 1.6rem;
				@include bp(lg-max) {
					flex-flow: column;
				}
				.col1,.col2,.col3 {
					@include bp(lg-max) {
						width: 100%;
						margin: 0 0 1.5rem;
						order: -1;
					}
				}
				.col1 {			
					width: 42%;		
					.badge {
						background-color: $grey4;
						color: $white;
						font-size: 1rem;
						font-style: normal;
						font-weight: 500;
						@include line-height(10, 16);
						letter-spacing: .1rem;
						text-transform: uppercase;
						margin-bottom: .7rem;
						padding: .2rem .8rem;
						border-radius: 2rem;
						width: max-content;
					}
					.title {
						color: $black1;
						font-size: 1.6rem;
						font-style: normal;
						font-weight: 400;
						@include line-height(16, 26);
						letter-spacing: -0.016rem;
					}
				}
				.col2 {		
					width: 30%;			
    			display: flex;
					.info-title {
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 500;
						@include line-height(12, 16);
						letter-spacing: .1rem;
						text-transform: uppercase;
						color: $black1;
						display: flex;
						align-items: center;
						&:first-child {
							margin-right: 1.6rem;
						}
						.avatar {
							color: #fff;
							background-color: $purple3;
							width: 2.4rem;
							height: 2.4rem;
							border-radius: 1.6rem;
							border: 1px solid $white;
							display: flex;
							align-items: center;
							justify-content: center;
							margin: 0 0 0 .4rem;
							&.overlapped {
								margin-right: -1rem;
							}
						}
						
					}
				}
				.col3 {
					width: 28%;
					justify-content: flex-end;
    			display: flex;
					.MuiButtonBase-root {
						padding: 1.6rem 2.4rem;
						background-color: $white;
						border-radius: 8px;						
						border: 1px solid rgba(179, 136, 255, 0.30);
						&.btn-download {
							width: max-content;
						}
						.icon {
							margin-right: .8rem;
						}
						&.is-icon-btn {
							margin-right: .8rem;
							padding: 0;
							width: 3.6rem;
							img {
								width: 1.4rem;
								height: 1.4rem;
							}
						}
						&.purple-btn {
							background-color: $purple3;
							color: $white;
						}
					}
					.icon-buttons-wrap {
						display: flex;
						align-items: center;						
						.MuiButton-root {
							margin-right: .8rem;
						}
					}
				}
			}
		}
	}
}

.MuiTooltip-popper {
	.tooltip-content{
		&.multiple-content {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-flow: column;
			min-width: 13rem;
			> span {
				border-bottom: 1px solid $lightPurple;
				padding: .8rem 0;				
				width: 100%;
				text-align: center;
				&:last-child {
					border: 0;
					margin-bottom: 0;
				}
			}
		}
	}
}