@import "../scss/colors";
@import "../scss/mixins";
@import "../scss/extended-classes";
@import "../scss/break-points";

// Section title
.section-title {
  color: $black1;
  font-size: 2rem;
  font-weight: 500;
  @include line-height(20,32);
  margin-bottom: 2rem;
}
// truncated name
.truncate-name {
  max-width: 16rem;
  display: inline-block;
  line-height: 1.6rem;
  vertical-align: middle;
  @extend .truncated-text;
}

// side by side count : spaces[with custom tooltip] and buildcard
.side-by-side-count {
  margin: 0 0 1.6rem 0;
  span {
    &.separator { margin: 0 1rem; border-right:1px solid $grey;}
  }
  .spaces { 
    position: relative;                                
    &:hover {
      cursor: pointer;
      .custom-tooltip {
        transform: scale(1);
        opacity: 1;
      }
    }                
  }
  ul.custom-tooltip {
    background: $white;
    position: absolute;
    top: 100%;
    left: -0.3rem;                
    transition: all 0.3s ease-in-out;
    padding: 0;
    margin: 0;
    border: 1px solid $grey6; 
    border-radius: 0.8rem;  
    box-shadow: 0 0.4rem 1.6rem 0 #00000026;   
    opacity: 0;
    transform: scale(0);
    max-height: 18rem;
    width: max-content;
    overflow: hidden;
    overflow-y: auto;
    z-index: 2;
    @include custom-scroll;
    &:hover { cursor: default; }
    li {
      display: flex;
      align-items: center;
      list-style-type: none;
      padding: 1.2rem 1.6rem;
      border-bottom: 1px solid $grey6;   
      color: $black1;
      font-size: 1.2rem;
      @include line-height(12,16);            
      &:last-child { border: 0; }
      //&:hover { cursor: default; }
      .icon {
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 0.2rem;
        padding: 0.4rem;
        display: flex;
        align-items: center;
        justify-content:center;
        background: $purple;
        margin: 0 0.8rem 0 0;
        &::before {
          font-size: 1rem;
          color: $white;
        }
      }
      span {
        max-width: 18rem;                  
        @extend .truncated-text;
      }
    }
  }
}

// side by side title | spaces
.side-by-side-title {         
  display: flex; 
  align-items: center;
  margin: 0 0 1.6rem 0;
  h3 {
    color: $black1;
    font-size: 2.4rem;
    font-weight: 500;
    @include line-height(24,32);            
    margin: 0 3.2rem 0 0;
  }
  .separator { margin: 0 1rem; border-right:1px solid $grey;}          
  .spaces { 
    position: relative;                                
    &:hover {
      cursor: pointer;
      .custom-tooltip {
        transform: scale(1);
        opacity: 1;
      }
    }                
  }        
}

// Golas : Business/Company and space goals
.goals-overview-page, 
.space-goals-wrapper {  
  .page-container { 
    .timeline-graph-wrap {
      margin-top: 3.2rem;
      background: $white;
      padding: 2.4rem 2.4rem 1.2rem;
      border-radius: 0px 0px 0px 20px;
      border: 1px solid $grey2;
      margin-bottom: 4rem;
      h4 {
        color: $black1;
        font-size: 2rem;
        font-weight: 500;
        @include line-height(20,32);
        margin-bottom: 1.6rem;
      }
      .graph-container {
        img { width: 100%; }
        display: flex;
        &.custom-scrollbar {
          max-height: 36rem;
          overflow-x: hidden;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: -ms-autohiding-scrollbar;
          @include custom-scroll;
        }
        .label-box {
          max-width: 100%;
          @extend .truncated-text;
        }
      }
    }    
    .goals-container {
      padding: 1.6rem 0 0 0;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -2.4rem;
      &.space {
        padding-top: 2.4rem;
      }
      
      .goals-left-col {
        padding: 0 2.4rem;
        width: calc(100% - 31.2rem);   
        @include bp(sm-max) {
          width: 100%;  
        }     
      }
      .goals-right-col {
        padding: 0 2.4rem;
        width: 31.2rem;
        @include bp(sm-max) {
          width: 100%;  
        }
        .search-wrap {
          margin-bottom: 1.6rem;
        }
        .right-col-section {
          background: #fff;
          border: 0.1rem solid #dfe0e6;          
          margin-bottom: 2.4rem;
          padding: 2.4rem;
          border-radius: 0 0 0 2rem;
          .goal-count {
            font-size: 1.4rem;
            font-weight: 500;
            @include line-height(14,20);
            letter-spacing: 0.014rem;
            margin-bottom: 1.3rem;
          }
          .chart-wrapper {
            text-align: center;
            margin-bottom: 2.4rem;
          }
          .goals-status-wrap {
            .content-row {
              font-size: 1.4rem;
              font-weight: 400;
              @include line-height(14,20);
              letter-spacing: 0.014rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 1.2rem 0;
              border-bottom: 1px solid $grey2;
              &:last-child { border-bottom: 0; }
              &.title > div{
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 500;
                @include line-height(10,16);
                letter-spacing: .1rem;
              }
              .content-left {
                position: relative;
                padding-left: 2rem;
                .color-dot {
                  background: $lightPurple;
                  width: 1.2rem;
                  height: 1.2rem;
                  border-radius: 100%;
                  margin-right: .8rem;
                  display: inline-flex;
                  position: absolute;
                  left: 0;
                  top:.3rem;
                }
              }
              //.content-right {}
            }
          }
        }
      }

      // Sorting
      .secondary-header {
        .page-filters-wrapper {
          margin-bottom: 1rem;
          .left-filter {
            display: flex;
            .sort-by {
              margin-bottom: 0;
              &:first-child {
                margin-right: 1.2rem;
              }
              .dropdown-text {
                font-size: 1.4rem;   
                strong { position: relative; z-index: 3; }             
              }
              .MuiInputBase-root {                
                position: relative;
                top: 0;
                left: -0.8rem;
                z-index: 2;
                .MuiSelect-select {
                  padding-right: 2.4rem;
                }
              }
              .dropdown-wrapper {
                margin-right: 2.4rem;
                .MuiInputBase-root {
                  .MuiSelect-select {
                    padding-right: 2.4rem !important;
                    font-weight: 500;
                    font-size: 1.4rem;
                  }
                }
              }
            }
          }
          .right-filter {
            .btn-add-goal {
              min-width: 10.8rem;
            }
          }
        }
      }

      // Increased by box
      .incread-by-box {
        border-radius: 0 0 0 2rem;
        border: 1px solid $purple6;
        background: $white;
        box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.15);
        padding: 2.4rem;
        border-left: 0.4rem solid $white;
        margin-bottom: 1.6rem;        

        .title-head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .private {
            border-radius: 2rem;
            background: $purple6;
            padding: 0.4rem 0.8rem;
            margin-left: 1.2rem;
            font-size: 1.2rem;
            font-weight: 500;
            @include line-height(12,16);
            letter-spacing: 0.012rem;
          }
          h4 {            
            font-size: 1.8rem;
            display: flex;
            align-items: center;
            @include line-height(18,28);
            margin-bottom: 1.2rem;
            max-width: calc(100% - 3rem);
            display: block;
            @extend .truncated-text;
            .icon {
              margin-left: 0.8rem;
              height: 2.4rem;
              cursor: pointer;
            }
          }
          .three-dot-btn {
            border: 0;
            background: transparent;
            width: auto;
            padding: 0;
            min-width: unset;
            width: 2.1rem;
            height: 2rem;
            margin-bottom: 1.5rem;
            .icon {
              height: inherit;
              width: inherit;
              &::before {
                font-weight: 500 !important;
                font-size: 2rem;
                color: $trueGrey !important;
              }
            }
            &:hover {
              background: transparent;
            }
          }
        }
        .progress-wrap {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          @include bp(md-max) {
            display: block;
            .text-content {
              margin-bottom: 1.6rem;
            }
          }
          .text-content {
            font-size: 1.6rem;
            max-width: 41.5rem;
            @extend .truncated-text;
          }
          .progress-bar {
            width: 40rem;    
            padding-top: 0.6rem;  
            @include bp(smd-max) {
              width: 100%;
            }      
            .MuiBox-root {
              margin-bottom: 0.8rem;
              .MuiLinearProgress-root {
                height: 0.8rem;
                background: $grey6;
                .MuiLinearProgress-bar {
                  background: $purple2;
                }
              }
            }
            .progress-text {
              font-size: 1.6rem;
            }
          }          
        }
        .tags-wrapper {
          padding-top: 1.2rem;
          .MuiStack-root {
            .MuiChip-root {
              height: 2.4rem;
              background-color: $lightPurple;
              color: $black1;
              .MuiChip-label {
                min-width: 3rem;
                max-width: 19.8rem;
                text-align: center;
                padding: 4px 8px;
                font-size: 1.2rem;
                font-weight: 500;
                @include line-height(12,16);                
                @extend .truncated-text;                        
                &:first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .box-footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 2.4rem;
          .date-box {
            font-size: 1rem;
            font-weight: 500;
            @include line-height(10,16);
            letter-spacing: 1px;
            text-transform: uppercase;
            border-radius: 2px;
            border: 1px solid $grey8;
            padding: 0.4rem 0.8rem;
            @include bp(sm-max) {
              width: min-content;  
            }
          }
          .status {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 500;
            @include line-height(12,16);
            letter-spacing: .1rem;
            .color-dot {
              background: $lightPurple;
              margin-left: 0.8rem;
              width: 1.6rem;
              height: 1.6rem;
              border-radius: 100%;
              display: inline-flex; 
              &.left {
                margin-right: 0.8rem;
              }
            }
            img {
              margin-left: 0.8rem;
            }
            .MuiInputBase-root {
              > div > .dot-sm { display: none; }
            }
          }
        }
        &:last-child { margin-bottom: 0; }
        &.on-track {
          border-left: 0.4rem solid #69F0AE;
        }
        &.progressing{
          border-left: 0.4rem solid $yellow2;
        } 
        &.off-track {
          border-left: 0.4rem solid $red1;
        }
        &.accomplished {
          border-left: 0.4rem solid $green1;
        }
        &.accomplished2 {
          .title-head {
            h4 {
              color: $trueGrey;
            }
          }
          .progress-wrap .progress-bar .MuiBox-root .MuiLinearProgress-root .MuiLinearProgress-bar {
            background: $grey11;
          }
        }
        &.disabled, :disabled {
          *{ 
            color: $grey11 !important; 
            border-color: #CDCFD8 !important;
          }
        }
      }
      
      .no-data-wrapper {  
        padding: 3.2rem 3.2rem 3.6rem;
        p {
          margin: 0 0 1.6rem 0;
        }      
        .btn-add-goals {
          width: 18rem;
          height: 4.8rem;
          background-color: $lightPurple;
        }
      }
    }
  }
}

.goals-detail-page {
  .page-container {
    .main-page-header {
      .page-title {
        display: flex; 
        align-items: center;
        justify-content: space-between;
        .right-filter {
          .button-wrap {
            display: inline-flex;
            align-items: center;
            margin-top: 11px;
            .square-btn-three-dots {
              background: $white;
              border: 1px solid $grey19;
              margin-left: 1.6rem;
            }
          }
        }
      }
    }
    .inner-section-wrap {
      padding-top: 1.5rem;
      padding-top: 0.8rem;
      .page-filters-wrapper {
        margin-bottom: 0;
        //.left-filter {}
        .right-filter {
          .button-wrap {
            display: flex;
            align-items: center;
            margin-top: -3.5rem;
            .square-btn-three-dots {
              background: $grey13;
            }
            .MuiButtonBase-root {
              &:first-child {
                margin-right: 1.6rem;
              }
            }
          }
        }
      }
    }

  }
}

// Tab section
.tab-label-wrapper {
  .MuiBox-root {
    .MuiTabs-root {    
      min-height: 2.4rem;      
      .MuiButtonBase-root {
        padding: 0.8rem 0 !important;
        min-width: none;
        min-height: 4.8rem;
        margin-right: 4.8rem;
        text-transform: none;
        min-width: auto;
        font-size: 1.6rem;
        @include line-height(16,24);
        &:last-child { margin-right: 0; }
        &:hover:not(.Mui-selected) {
          color: $purple;
          &::after { 
            left: 0;
            right: 0;
            width: 100%;
          }
        }
        &:not(.Mui-selected) {          
          &::after {
            content: "";
            position: absolute;
            left: 50%;
            right: 50%;
            bottom: 0;
            width: 0;
            height: 0.2rem;
            background-color: $purple1;
            @extend .transition;
          }
        }
      }
      //.MuiTabs-indicator {}        
    }
  }
}
// Tab context panel
.tab-context-wrapper {
  .section-title {
    font-size: 2.4rem;
    font-weight: 500;
    @include line-height(24,32);
    letter-spacing: 0.024rem;
    margin: 0 0 2.4rem;
  }
  .status-content-wrap {
    padding: 2.4rem 4.8rem 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $white;
    margin:0 -4.8rem 3.2rem -4.8rem;
    border-top: 1px solid $purple6;
    @include bp(md-max) {
      flex-wrap: wrap;
    }   
    .col-wrap {
      display: flex;
      align-items: center;
      &:first-child {
        @include bp(md-max) {
          order:1;
        }
        @include bp(sm-max) {
          flex-wrap: wrap;
        } 
      }
      &:last-child {
        @include bp(md-max) {
          order:2;          
        } 
        .col {
          text-align: right;
          padding-right: 0;
          @include bp(sm-max) {            
            width: 100%;
          }
        }
      }
    } 
    .col {
      flex: none;
      border-right: 1px solid $grey2;      
      padding: 0.8rem 5.8rem 0.8rem 2.4rem;
      @include bp(sm-max) {
        padding: 0.8rem 3.2rem 0.8rem 1.6rem;
        width: 50%;
        border: 0;
        &:nth-child(3) {
          padding-left: 0;
        }
      }
      &:first-child {
        padding-right: 1.6rem;
        padding-left: 0;        
      }
      &:last-child, &.no-border {
        border-right: 0;
      }
      label {
        color: $trueGrey;
        font-size: 1.2rem;
        font-weight: 500;
        @include line-height(12,16);
        letter-spacing: .1rem;
        text-transform: uppercase;
        margin: 0 0 .4rem;
      }
      .owner-name {
        font-size: 1.4rem;
        font-weight: 500;
        color: $black1;
        @include line-height(14,20);
      }
      .content-wrap {
        display: flex;
        align-items: center;
        .context-label {
          font-size: 2.8rem;
          font-weight: 500;
          color: $black1;
          @include line-height(28,36);
          @include bp(md-max) {
            font-size: 1.8rem;
          }
        }        
        .dot {
          display: inline-flex;
          width: 2.4rem;
          height: 2.4rem;
          background: $grey2;
          border-radius: 100%;
          margin-right: 1.2rem;
        }                
        .select-status {
          color: $black1;
          font-size: 2.8rem;
          font-weight: 500;
          @include line-height(28,36);
          @include bp(md-max) {
            font-size: 1.8rem;
          }
          .MuiSelect-select {
            &::after {
              font-weight: 400;
              font-size: 1.4rem;
            }
          }
        }
        .MuiInputBase-root {
          .MuiSelect-select {
            min-height: auto;
            padding: 0 3.2rem 0 0;
            > .dot-sm { display: none; }
          }
          fieldset { display: none; }
        }
      }
    }
  }  
  .MuiTabPanel-root {
    padding: 0;
    h4 {
      @extend .section-title;
    }
    .goal-section {
      margin-bottom: 3.2rem;      
      .title-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 { margin: 0; }
      }      
      &:last-child { margin-bottom: 0; }
      &.resources-tab-section {
        // background: $white;
        padding: 2.4rem 4.8rem 2.4rem;
        margin:0 -4.8rem 3.6rem -4.8rem;
        border-top: 1px solid $purple6; 
      }
      .light-purple-btn {
        background: $lightPurple;
      }
      .no-data-wrapper {
        margin-top: 2.4rem;
        margin-bottom: 2.4rem;
      }
      .kpi-count {
        padding-top: 1.2rem;
        color: $trueGrey;
        font-size: 1.4rem;
        @include line-height(14,20);
      }
      .graph-wrapper {
        margin-top: 2rem;
        .kpi-performance {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .separator {
        border: 1px solid $grey2;
        margin-bottom: 4rem;
      }      

      .efficiancy-cards-wrapper {
        margin: 2.4rem 0 0rem;
        .card-section {
          display: flex;
          align-items: flex-start;     
          flex-wrap: wrap;  
          .epmty-card-text {
            font-size: 1.6rem;
            margin-bottom: 1.4rem;
            @include line-height(16,24);
          }   
          .card {
            background: $white;
            flex-grow: 1;
            width: calc(33% - 4.8rem);
            max-width: calc(33% - 1rem);
            padding: 2rem 2.4rem 2.4rem;
            margin: 0 2.4rem 2.4rem 0;
            border: 1px solid $purple6;
            border-radius: 0 0 0 2rem;
            &:nth-child(3n) { margin-right: 0; }
            &.off-track { border-top: 4px solid $red1; }
            &.progressing { border-top: 4px solid $yellow2; }
            &.on-track, &.not-started { padding-top: 2.4rem; }
            @include bp(md-max) { 
              width: calc(50% - 2.4rem); 
              max-width: calc(50% - 1rem);
              &:nth-child(even) { margin-right: 0; }
              &:nth-child(odd) { margin-right: 2.4rem; }
            }
            h4 {
              cursor: pointer;
              color: $black1;
              font-size: 1.6rem;
              @include line-height(16,24);
              font-weight: 500;
              @extend .truncated-text;
              margin: 0 0 1.6rem 0;
              &:first-letter {
                text-transform: uppercase;
              }
            }
            .count {
              @extend .side-by-side-count;
            }            
            .text-description {
              color: $trueGrey;
              font-size: 1.4rem;
              @include line-height(14,20);
              letter-spacing: 0.005rem;
              @extend .truncated-text;
            }
            .card-bottom {
              margin: 1.6rem 0 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              text-transform: uppercase;
              .target-date {
                border: 1px solid $grey18;
                border-radius: 0.2rem;
                padding: 0.3rem 0.6rem;
                color: $black2;
                font-size: 1rem;
                font-weight: 500;
                @include line-height(10,16);
              }
              .status {
                color: $black1;
                font-size: 1rem;
                font-weight: 500;
                @include line-height(10,16);
                display: flex;
                align-items: center;
                justify-content: flex-end;
                em, img {
                  width: 1.6rem;
                  height: 1.6rem;
                  border-radius: 100%;
                  background-color: $grey2;
                  margin-left: 0.8rem;
                  display: inline-flex;
                }
              }
            }
          }
        }
        .accomplished-cards {
          h3 {
            color: $black1;
            font-size: 1.6rem;
            font-weight: 500;
            @include line-height(16,24);
            margin-bottom: 1.2rem;
          }
          //.card-section {}
        }
      }
      
      // Spaces working toward goal
      .spaces-in-goal-wrapper {
        .title-wrap { 
          @extend .side-by-side-title;          
          justify-content: space-between;
          h3 { margin-right: 2.4rem; }
          .count {
            display: inline-flex;
            margin: 0;
            @extend .side-by-side-count;
          } 
        }
        .table-wrapper {
          margin: 0.8rem 0 2.4rem;
          tr {
            td { border-bottom: 0; 
              background: $white;              
              &:first-child { padding-left: 1.6rem; width: 20.37%; }
              &:nth-child(2) { width: 17.85%;}
              &:nth-child(3) {width: 19.26%;}
              &:nth-child(4) {width: 17.85%;}
              &:nth-child(5) {width: 17.85%;}
              &:last-child {width:6.66%;
                a.underline { text-decoration: underline;
                  &:hover { text-decoration: none; }
                }
              }
            }            
          }
          .outer-table {
            .be-down-arrow-1 { 
              left: 0; 
              cursor: pointer;
              position: relative;
              top: 0.3rem;
              &::before { color: $grey11; }
              &.move-up {
                transform: rotate(180deg);
                display: inline-block;    
                top: -0.2rem;            
              }
            }
            .be-left-arrow {
              cursor: pointer;
              padding-left: 1rem;
            }
            .be-chevron-down { 
              font-size: 1rem; 
              height: 2rem; 
              width: 2rem;
              color: $purple;
              font-weight: 500;
              margin-right: 0.6rem;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              &::before {
                transform: rotate(-90deg);
                display: inline-flex;
              }
              &.toggle-down::before {
                transform: rotate(0deg);                
              }
              &.hidden { visibility: hidden; }
            }
            .collapse-table-wrap {
              background: transparent;
            }
            .inner-table {              
              strong { 
                color: $purple;                 
                padding-left: 2.8rem;                
                vertical-align: middle;
                line-height: 14px;               
              }
              a { 
                vertical-align: middle;
                line-height: 14px;
              }
              tr {
                td {
                  background: transparent;
                  &:nth-child(4) {
                    padding-left: 9.4%;
                  }
                  &:nth-child(5) {
                    padding-left: 5.2%;                    
                  }
                }
              }
            }
          }
        }
      }      
    }
    
  }
  
}

// Welcome to business goal popup
.welcome-to-business-goal-popup {
  padding: 2rem;
  width: 45.6rem;
  background: #fff;  
  bottom: 2.4rem;  
  left: 8.2rem;
  opacity: 0;
  padding: 2rem;
  position: fixed;
  text-align: left;
  transform: translateY(1rem);
  visibility: hidden;
  z-index: 100;
  border-radius: 0 1.2rem 1.2rem 1.2rem;
  box-shadow: 0 1.2rem 3rem 0 rgba(101, 31, 255, 0.10);
  @include bp(md-max) {
    left: 2.6rem;
  }
  @include bp(sm-max) {
    left: 2rem;
    width: calc(100% - 4rem);
  }
  &.open {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .close-menu {
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;
  }
  .inner-container {
    padding: 0.4rem 2rem 3.6rem;    
    h4.title {
      margin: 2rem 0 1.2rem 0;
      font-size: 1.8rem;
      font-weight: 500;
      @include line-height(18,28);
    }
    p {
      margin: 0 0 2.4rem;
    }
    .xs-btn {
      width: 100%;
    }
  }
  
}
.whats-being-built {
  .title-wrap {
    display: flex;
    align-items: center;  
    justify-content: space-between;
    padding-bottom: 1.6rem;   
    .left {
      display: flex;
      align-items: center;
      h3 {
        @extend .section-title;
        margin: 0 !important;
      }
      .count {
        margin-left: 2.4rem;        
        color: $trueGrey;
        font-size: 1.4rem;
        @include line-height(14,20);
      }
      .separator {
        border: 1px solid $grey2;
        margin-bottom: 4rem;
        margin-right: 2.4rem;
      }
    } 
    .right {
      button {
        background-color: $lightPurple;
        &:hover {
          background-color: $lightPurple;
        }
      }
    }
  }
  .no-data-wrapper {
    margin-top: 1.3rem;
  }
  .grid-view-cards-wrapper {
    margin-top: 1.4rem;           
  }
}
// KPI Detail section
.kpi-detail-wrapper {
  margin: 3.2rem 0 2.4rem;
  .title-wrap { 
    @extend .side-by-side-title;          
    justify-content: flex-start;
    margin-bottom: 3.2rem;
    h3 { margin-right: 2.4rem; }
    .count {
      margin: 0;
      display: inline-flex;
      @extend .side-by-side-count;
    } 
  }
  .ac-left {
    display: flex;
    align-items: center;
    .text-lable {
      color: $black1;
      font-size: 2rem;
      font-weight: 500;
      @include line-height(20,32);
    }
    .be-chevron-down {
      width: 2.4rem;
      height: 2.4rem;
      margin-left: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      //&.toggle-down {}
      &.toggle-up {
        transform: rotate(180deg);
        display: inline-block;
        margin-left: -0.2rem;
      }
      &::before {
        font-size: 1.2rem;
      }
    }
  }
  // .card-tags-wrap {
  //   .tag-wrapper {
  //     .tags-container {
  //       .MuiStack-root {
  //         .MuiChip-root {
  //           margin: 0 0.6rem 0.6rem 0;
  //         }
  //       }              
  //     }
  //     .icon-btn-wrap {
  //       .MuiButtonBase-root {
  //         margin-bottom: 1rem;
  //       }
  //     }
  //   }
  // }
  .MuiAccordion-root {
    background: transparent;
    border: 0;
    box-shadow: none;
    &::before {
      display: none;
    }
    .MuiAccordionSummary-root {
      //&.Mui-expanded {}
      min-height: 3.2rem;
      padding: 0;
      margin-bottom: 1.2rem;
      .MuiAccordionSummary-content {
        //&.Mui-expanded {}
        margin: 0;
      }
    }
    .MuiCollapse-root {
      .MuiCollapse-wrapper {
        .MuiCollapse-wrapperInner {
          .MuiAccordionDetails-root {
            padding: 0;
          }
        }
      }
    }
  }
}

// Create business goal modal
.business-goal-modal {
  .MuiDialog-container {
    .MuiPaper-root {
      border-radius: 1.6rem !important;
      border: 1px solid $grey2;
      background: $white;
      box-shadow: 0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.15);
    }
  }  
  .business-goal-body-wrap {
    padding: 2.4rem 4rem 4rem !important;
    .empty-spacing {
      min-height: 28rem;
    }
    .modal-title {
      margin-bottom: 2.4rem;
    }
    .form-group {
      &.searchbar-wrap {
        .MuiOutlinedInput-root {
          .MuiAutocomplete-endAdornment { 
            display: none;
          }
        }
      }
      .separator {
        border-bottom: 1px solid $grey2;
        margin: 1.2rem 0 2.4rem;
      }
      .MuiFormControl-root {
        .MuiInputBase-root {
          border-radius: 0.8rem;
          &.Mui-focused {
            border-radius: 0.4rem;
          }
        }
      }
      .date-picker-wrap {
        .MuiStack-root {
          padding: 0;
          .basic-date-picker {
            min-width: 15rem;
          }
        }
        background: $white;
        label {
          background: none;
        }
        textarea {
          padding-top: 1rem;
        }
        .MuiInputBase-root {      
          fieldset {        
            legend {
              span {
                background-color: transparent;
                display: none;
              }
            }
          }
          input {
            padding: 2.7rem 0 0.8rem 1.8rem !important;
          }
          .MuiButtonBase-root {
            margin-right: 0;
            width: 2.4rem;
            height: 2.4rem;      
            .MuiSvgIcon-root {
              color: $black1;
              font-size: 2rem;
            }
          }
        }
        .MuiInputAdornment-root {
          .MuiButtonBase-root {
            width: 1.6rem;
            height: 1.6rem;
            .icon{
              &::before {
                font-size: 1.6rem;
                color: $black1;
              }
            }
          }
        }
      }
      .mt-4 {
        margin-top: 4rem;
      }
    }
    .chips-wrapper {
      &.list-items {
        ul {
          padding: 0;
          margin: 0;
          li {            
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.6rem 0;
            border-bottom: 1px solid $grey18;
            &:last-child { border:0; }
            span {
              font-weight: 500;
              @extend .truncated-text;
              max-width: 90%;
            }
            em {
              height: 1.6rem;
              &::before {
                font-size: 1.6rem;
                height: inherit;
              }
            }
          }
        }
      }
    }
  }
  
}

// Status Dropdown [Select options]
.status-menu-item {
  min-width: 18rem !important;
  border-bottom: 1px solid $grey2 !important;
  &:last-of-type { border: 0 !important; }
  .dot-sm {
    display: inline-flex;
    width: 1.2rem;
    height: 1.2rem;
    background: $grey2;
    border-radius: 100%;
    margin-right: 0.8rem;
  }
}

.card-tags-wrap {
  .tag-wrapper {
    .icon-btn-wrap {
      padding-top: 0.3rem !important;
    }
  }
}