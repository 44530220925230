@import "../../assets/scss/colors";
@import "../../assets/scss/mixins";
@import "../../assets/scss/extended-classes";
@import "../../assets/scss/break-points";

//.building-spaces {}

.page-filters-wrapper {
	.right-filter {
		.MuiButtonBase-root {
			width: 3.6rem;
			min-width: 3.6rem;
		}

		.MuiButton-root {
			&.purple-btn {
				min-width: 15.2rem;
				letter-spacing: 0.014rem;
				color: $black2;
			}

			&.is-icon-btn::before {
				left: 0;
			}
		}
	}
}

.form-container {
	padding: 2.4rem;
	border-radius: 0.8rem;
	background: $grey1;
	margin-bottom: 3.2rem;

	.has-add-email {
		position: relative;

		.add-email-wrapper {
			position: absolute;
			top: 6rem;
			left: 0;
			right: 0;
			border: 0.1rem solid $grey8;
			border-radius: 0 0 0.4rem 0.4rem;
			z-index: 1;
			background: $white;

			.add-email-box {
				cursor: pointer;
				padding: 1.6rem;
				border-left: 0.4rem solid $purple1;
				background: $grey7;
				font-weight: 400;
				font-size: 1.4rem;
				@include line-height(14, 20);
				letter-spacing: 0.01em;
				overflow: visible;
				text-overflow: inherit;
				white-space: inherit;
				strong {
					word-break: break-all;
				}
			}
		}
	}
}

//owner-email-dropdown
.MuiPopper-root.MuiAutocomplete-popper {
	.owner-email-dropdown {
		display: flex;
		align-items: center;
		width: 100%;

		.owner-initials {
			width: 3.2rem;
			min-width: 3.2rem;
			height: 3.2rem;
			border-radius: 100%;
			border: 1px solid $lightPurple;
			background: $lightPurple;
			margin-right: 1rem;
			font-size: 1rem;
			font-weight: bold;
			color: $purple2;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
		}
		.right-side {
			display: block;
			//width: calc(100% - 4.2rem);
			flex-direction: column;
			align-items: flex-start;
			@extend .truncated-text;
			.owner-name {
				color: $black2;
				font-weight: bold;
				font-size: 1.4rem;
				margin-right: 0.8rem;
			}
			.owner-email {
				color: $trueGrey;
				font-size: 1.4rem;
			}
			@include bp(sm-max) {
				display: block;
				width: calc(100% - 4.2rem);
				flex-direction: column;
				align-items: flex-start;
				@extend .truncated-text;
				.owner-name {
					display: block;
				}
				.owner-email {
					display: block;
				}
			}
		}
	}
}

.modal-btn-block {
	.btn-save {
		width: 100%;
	}
}

.checkbox-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.spaces-list-container {
	background: $white;
	padding: 0;
	margin-top: 1.6rem;
	max-height: 40rem;
	overflow: auto;
	@include custom-scroll;

	ul {
		margin: 0 0.4rem 0 0;
		padding: 0;

		li {
			padding: 1.6rem 0;
			border-bottom: 1px solid $grey8;
			list-style-type: none;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:last-child {
				border: 0;
			}

			.description {
				font-size: 1.6rem;

				.email {
					margin-top: 0.3rem;
					font-size: 1.4rem;
					color: $trueGrey;
				}
			}

			.icon-delete {
				cursor: pointer;
				height: 2rem;
				display: flex;
				align-items: center;
			}
		}
	}
}

.table-wrapper {
	.multiple-user-icon {
		border-radius: 0.4rem;
		padding: 0.4rem;
		width: 2.4rem;
		opacity: 0.9;
		.icon {
			color: $white;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.lock-icon,
	.archive-icon {
		padding: 0 0 0 0.4rem;
		width: 2.4rem;
		cursor: pointer;
		display: flex;
		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		img {
			margin-left: 8px;
		}
		em {
			margin-left: 8px;
			font-size: 16px;
		}
	}
	.MuiTable-root {
		.icon-archive {
			display: inline-block;
			margin-top: 0.4rem;
			margin-right: 1.4rem;
			cursor: pointer;
		}
		.be-down-arrow-1 {
			margin: 0 0 0 0.8rem;
			font-size: 12px;
			position: relative;
			top: 0.2rem;
		}
		.be-left-arrow {
			display: inline-block;
			&.move-down {
				transform: rotate(270deg);
			}
			&.move-up {
				transform: rotate(90deg);
			}
		}
		.MuiTableRow-root {
			&.disabled {
				.lock-icon,
				.archive-icon {
					em {
						color: $purple2;
					}
				}
				.user-name {
					color: $purple2 !important;
				}
				.user-link {
					.multiple-user-icon {
						opacity: 0.5;
					}
				}
				.MuiTableCell-body {
					color: $purple2 !important;
				}
				.initials {
					background: $grey12 !important;
					color: $black1;
					opacity: 0.6;
				}
			}
		}
	}

	.initials {
		height: 2.4rem;
		width: 2.4rem;
		min-width: 2.4rem;
		border-radius: 50%;
		background: inherit;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $white;
		font-size: 1rem;
		color: $black2;
		text-transform: uppercase;
		+ .initials {
			margin-left: -0.6rem;
		}
	}

	.progress-circle {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 1.5rem;
		cursor: pointer;

		.progress-bar {
			height: 10px;
		}

		&:hover {
			.credits {
				display: block;
			}
		}
	}

	.three-dots {
		cursor: pointer;
		margin: 0 1.2rem 0 0;

		.be-vertical-dots {
			font-size: 2rem;
			font-weight: bold;
		}
	}
}

.MuiTooltip-popper {
	.MuiTooltip-tooltip {
		min-width: 20rem;
		border-radius: 8px;
		box-shadow:
			0px 1px 4px 0px rgba(0, 0, 0, 0.08),
			0px 4px 20px 0px rgba(0, 0, 0, 0.06);
	}
}

.credits {
	.date-row {
		padding: 0.5rem 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.2rem;

		span:first-child {
			display: flex;
			align-items: center;

			em {
				margin-right: 4px;
			}
		}

		.icon-blue,
		.icon-grey {
			background: #6200ea;
			height: 1.2rem;
			width: 1.2rem;
			border-radius: 100%;
		}

		.icon-grey {
			background: $grey8;
		}

		.price {
			font-weight: bold;
		}
	}
}

.MuiPaper-root {
	&.MuiMenu-paper {
		&.MuiPaper-elevation {
			&.MuiPaper-rounded {
				min-width: 11.1rem;
				border-radius: 0.8rem;
				box-shadow:
					0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.02),
					0 0.4rem 0.2rem 0 rgba(0, 0, 0, 0.01);
			}
		}
	}

	.toggle-menu-item-row {
		.icon {
			margin-right: 1.2rem;
			height: 1.8rem;
		}
		img {
			height: 1.5rem;
			margin-right: 1.2rem;
		}
	}
}

.make-private-label {
	display: inline-flex;
	align-items: center;
	.be-info {
		line-height: 0;
		display: inline-flex;
		margin-left: 5px;
		font-size: 1.4rem;
	}
}

// Space Idea board
.idea-board-wrapper {
	.main-page-header {		
		padding: 0;
		justify-content: space-between;
		background: $grey7;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;		
		
		.left-page-header {
			display: flex;
			align-items: center;
			.MuiButtonBase-root {
				margin: 0 1.6rem 0 0;
				&.btn-back {
					background: $white;
					width: 3.6rem;
					height: 3.6rem;
					min-width: 3.6rem;
					border: 1px solid rgba(179, 136, 255, 0.30);
					.btn-icon {
						font-size: 1.6rem;
						margin: 0;
					}
				}
			}
			.dropdown-wrap {
				padding-left: 1.7rem;
				.MuiButtonBase-root {
					min-width: 10rem;
					width: 10rem;
					height: 3.6rem;
					display: flex;
					align-items: center;
					font-size: 1.4rem;
					font-weight: 500;
					color: $black1;
					border-radius: 0.8rem;
					em {
						margin: 0 0 0 1.3rem;
						line-height: 1;
					}
				}
			}
		}
		.right-page-header {            
			.compnay-logo {
				padding-right: 0;
				position: absolute;
				right: 0;
				top: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 4rem;
				width: 10rem;
				img {
					max-height: 100%;
					max-width: 100%;
				}
			}
		}		
	}	

	// Ideaboard iframe page wrapper
	.iframe-page-head {
		max-height: 6.5rem;
		.main-page-header {
			padding: 1.5rem 3.2rem 0 1.8rem;			
			.left-page-header {
				align-items: normal;
			}
			.right-page-header {            
				.compnay-logo {					
					top: 1.2rem;
				}
			}	
		}
	}
	
	.separator {
		border: 0;
		border-bottom: 1px solid $grey2;
		display: block;
		margin: 0 -4.8rem;
		height: 1px;
	}
	.page-filters-wrapper.secondary-header {
		// margin-bottom: 0;
		// padding-bottom: 2.4rem;		
		
		.right-filter {
			.MuiButton-root.purple-btn {
				&.btn-start-idea {
					min-width: 20.8rem;
					height: 4.8rem;
				}
			}
			.btn-add-whiteboard {
				height: 4.8rem !important;
				width: 4.8rem !important;
				img {
					width: 2rem;
					height: auto;
				}
				&.is-icon-btn {
					width: 3.2rem !important;
					height: 3.2rem !important;
					border: 0;
					padding: 0;
					margin: 0 0 0 3.2rem;
					img {
						width: 3.2rem;
						height: auto;
					}
				}				
			}
		}
	}
	.inner-section-wrap {
		padding: 2.4rem 0 2.4rem;
		.left-filter {
			.count {
				display: flex;
				align-items: center;
				color: $black1;
				font-size: 2rem;
				font-weight: 500;
				@include line-height(20,32);
				img {
					height: 2rem;
					width: auto;
					margin: 0 .8rem 0 0;
				}
			}
		}
		.page-filters-wrapper {
			margin-bottom: 2.4rem;
			.right-filter {
				margin-right: 0;
				.form-group.sort-by {
					margin-right: 2.2rem;
					.dropdown-text {
						margin-right: 0.4rem;
					}
				}
			}
		}
		.tab-label-wrap {
			.MuiBox-root {
				.MuiTabs-root {
					min-height: 3.6rem;
					.MuiTabs-flexContainer {
						background: $grey13;
						border-radius: .8rem;
						.MuiButtonBase-root {						
							min-width: 4.2rem;
							min-height: 3.6rem;
							font-size: 1.4rem;
							font-weight: 400;
							padding: .6rem 1.2rem;
							@include line-height(14,20);
							letter-spacing: 0.014rem;
							color: $black1;
							text-transform: capitalize;
							&[aria-selected="true"]{
								background: $lightPurple;
								border-radius: .8rem;
								color: $purple1;
								font-weight: 500;
							}
							//.MuiTouchRipple-root {}
						}
					}
				}
				.MuiTabs-indicator { display: none; }
			}
		}
		// Tab content section
		.MuiTabPanel-root {
			padding: 0;
			.row-container {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 2.4rem 3.2rem;
				border-radius: 0 0 0 2.4rem;
				border: 1px solid $grey2;
				background: $white;
				margin: 0 0 1.6rem;
				.left-content,
				.right-content {
					display: flex;
					align-items: center;
					color: $black1;
					font-size: 1.6rem;
					font-weight: 400;
					@include line-height(16,24);
					.label {
						font-weight: 500;
					}
					.icon-wrap {
						img, .icon {
							width: 2.4rem;
							height: auto;
							margin-right: 2.4rem;
							position: relative;
							top: .3rem;
							left: 0;
							&::before {
								font-size: 2.24rem;
							}
						}
						.icon { top: .2rem; }
					}
				}
				.right-content {
					width: 40%;
					justify-content: flex-end;
					> span { padding: 0 3.2rem 0 0; 
						&.auth-name {
							max-width: 20rem;
							padding-right: 2.4rem;
    					text-align: left;
							width: 65%;
							@extend .truncated-text;
						}
					}
					.date-time {
						padding-right: 2.4rem;
						width: 35%;
					}
				}
				&:hover {
					cursor: pointer;
					border-color: $purple;
				}
			}
		}
	}
	.MuiOutlinedInput-root.borderless-dropdown .MuiSelect-select {
		padding-right: 2.4rem !important;
	}
	.search-whiteboard {
		width: 30.4rem;
	}
	.MuiTableContainer-root {
		.MuiTable-root {
			.MuiTableRow-root {
				&:nth-child(odd) {
					background-color: $white;
				}
				.MuiTableCell-body {
					padding: 1.6rem;
					height: 6.4rem;
					border: 0;
					font-size: 1.4rem;
					letter-spacing: 0.014rem;
					&:first-child, &:nth-child(2), &:nth-child(3) {
						width: 20%;
					}
					&:first-child {
						padding-left: 1.6rem;
						strong {
							font-size: 1.6rem;
							font-weight: 500;							
						}
					}
					&:last-child {
						width: 40%;
						text-align: right;
					}
					.three-dots {
						margin: 0 1.6rem 0 0;
						display: inline-flex;
					}
				}
				&:last-child td {
					border: 0;
				}
			}
		}
	}
	.no-ideas-yet {
		p {
			margin: 0 0 1.6rem 0;
		}
		.btn-new-board {
			padding: 0.8rem 1.6rem;
			height: 4.8rem !important;
			img { margin-right: 0.8rem;}
		}
	}
	
}

.three-dot-menu {
	.MuiPaper-root {
		max-width: 9.3rem;
		min-width: 9.3rem !important;
		.MuiList-root {
			padding: 0 !important;			
			.MuiButtonBase-root {
				padding: 1.2rem 1.6rem !important;
				background: $white !important;
				box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
				font-size: 1.2rem;
				font-weight: 400;
				@include line-height(12,16);
				letter-spacing: 0.012rem;				
				img {
					margin-right: .8rem;
				}
			}
			&:hover {
				.MuiButtonBase-root {
					background: $grey6 !important;
				}
			}
		}
	}
}

.delete-whiteboard-modal {
	padding: 4rem !important;
	.modal-body {
		.modal-title {
			margin-bottom: 1.6rem;
		}
	}
	.modal-btn-block {
		margin-top: 4rem;
	}
}