@import "../../assets/scss/colors";
@import "../../assets/scss/mixins";
@import "../../assets/scss/extended-classes";
@import "../../assets/scss/break-points";
@import "../../assets/scss/overview-section";

// project - common page for tabs
.project-page-wrap {
  .page-container {
    padding-right: 0;
    padding-bottom: 0;
    .main-page-header {
      padding-right: 2.4rem;
    }

    .page-filters-wrapper {
      padding-right: 2.4rem;
      margin-bottom: 1.6rem;
      // max-width: 245px;
      // width: 50%;
      //.right-filter {}
    }
    .page-top-navigation {
      ul li a {
        &:hover,
        &.active {
          text-shadow: none;
        }
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    @include bp(smd-max) {
      flex-direction: column;
    }

    // Left page content
    .page-content {
      padding-top: 2.4rem;
      padding-right: 2.4rem;
      width: 100%;
      max-width: calc(100% - 33.4rem);
      @include bp(smd-max) {
        max-width: 100%;
        padding-bottom: 2.4rem;
        .table-wrapper {
          padding-bottom: 2.4rem;
        }
      }
      .page-filters-wrapper {
        padding: 0;
        .left-filter {
          .count {
            font-size: 1.6rem;
            font-weight: 500;
            color: $black1;
          }
          &.btn-wrapper {
            display: flex;
            align-items: center;
            .count {
              margin-right: 0.8rem;
            }
            .btn-plus {
              min-width: 3.6rem;
              .icon {
                height: 3rem;
                font-size: 1.6rem;
              }
            }
          }
        }
        .right-filter {
          @include bp(smd-max) {
            flex-wrap: nowrap;
            justify-content: space-between;
          }
          @include bp(xs-max) {
            flex-wrap: wrap;
            justify-content: unset;
          }
          .form-group {
            @include bp(smd-max) {
              //width: 100%;
              width: calc(100% - 16rem);
              margin: 0 0 1.5rem;
              order: -1;
            }
            @include bp(xs-max) {
              width: 100%;
            }
          }
          .btn-add-members {
            width: auto;
            background: $lightPurple !important;
          }
          .btn-plus {
            min-width: 3.6rem;
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .search-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .dropdown-text {
              font-size: 1.4rem;
              font-weight: 400;
              color: $black1;
              white-space: nowrap;
              display: flex;
              align-items: center;
              justify-content: center;
              letter-spacing: 0.14px;
              margin: 0 1.4rem 0 0;
              strong {
                font-weight: 500;
              }
              .icon {
                font-weight: 500;
                display: flex;
                align-items: center;
                margin: 0 0 0 0.4rem;
              }
            }
            .MuiFormControl-root {
              @include bp(lg-min) {
                min-width: 30.4rem;
              }
            }
            .borderless-dropdown {
              display: flex;
              width: 18rem;
              max-width: 18rem;
              align-items: flex-start;
            }
          }
          .btn-wrapper {
            display: flex;
            align-items: center;
            background: $lightPurple;
            border-radius: 0.8rem;
          }
        }
      }

      .no-data-wrapper {
        margin-bottom: 2.4rem;

        &.next-idea {
          padding-top: 8rem;
          padding-bottom: 8rem;
          .thumb {
            margin: 0 auto 1.6rem;
          }
          h4 {
            color: $black1;
            font-size: 2rem;
            font-weight: 500;
            @include line-height(20, 32);
          }
          p {
            font-size: 1.6rem;
            @include line-height(16, 24);
          }
          button {
            width: auto;
          }
        }
      }
    }    
  }
}

.MuiFilledInput-root {
  &.search-tag-input {
    border: 0;
    padding: 0;
    .MuiInputBase-input {
      color: $black1;
      padding-left: 0;
      &::placeholder {
        color: $grey11;
      }
    }
  }
}

.MuiTableContainer-root {
  .MuiTable-root {
    .MuiTableHead-root {
      .be-down-arrow-1 {
        position: relative;
        top: 2px;
        left: 6px;
        font-size: 1.2rem;
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          .user-link {
            padding: 1rem 0;
            display: inline-flex;
            align-items: center;
            font-size: 1.4rem;
            letter-spacing: 0.01em;
            font-weight: 500;
            color: $black1;
            cursor: pointer;
            .initials,
            .user-avatar {
              height: 3.6rem;
              width: 3.6rem;
              min-width: 3.6rem;
              border-radius: 50%;
              background: #f3ebff;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-size: 1.2rem;
              font-weight: 400;
              text-transform: uppercase;
              img {
                border-radius: 50%;
              }
            }
            .user-name {
              padding-left: 1.2rem;
              color: $purple1;
            }
            .MuiButtonBase-root {
              &.btn-star {
                min-width: 2.8rem;
                height: 2rem;
                margin-left: 0.4rem;
                border-radius: 1rem;
                padding: 0;
                &.purple-btn {
                  background: $purple1;
                }
                .icon {
                  line-height: 0;
                  color: $white;
                  font-size: 1.2rem;
                  width: 1.2rem;
                  height: 1.2rem;
                }
              }
            }
          }
          .three-dots {
            cursor: pointer;
            .be-vertical-dots {
              font-size: 2rem;
              font-weight: bold;
            }
          }
          .be-info {
            cursor: pointer;
            position: relative;
            left: 6px;
            top: 3px;
          }
        }
      }
    }
  }
}

// .table-bulk-members-added {
//   overflow-y: auto;
//   @include custom-scroll;
//   max-height: 24rem;
//   padding-top: 3.8rem;
//   .MuiTable-root {
//     .MuiTableRow-root{
//       td {
//         border: 0;
//       }
//     }
//   }
// }

// .custom-dropmenu {
//   .MuiPaper-root {
//     //margin-top: 1.6rem;
//     &.MuiMenu-paper {
//       min-width: 12.2rem;
//       .MuiMenu-list {
//         padding: 0.2rem 0;
//         .MuiMenuItem-root {
//           &:hover {
//             background: transparent;
//           }
//         }
//       }
//       .be-edit {
//         display: flex;
//         margin-right: 0.8rem;
//       }
//     }
//   }
// }

// Create project modal
.create-project-modal {
  .browse-files-link {
    font-weight: 400;
    img {
      margin: 0 0.8rem 0 0;
    }
  }
  .align-space-goal {
    margin: 0.4rem 0 0;
  }
  .advanced-settings {
    margin: 0.8rem 0 0;
  }
}
.advanced-settings {
  .icon {
    color: $black;
  }
  .MuiAutocomplete-root {
    .MuiButtonBase-root {
      &.MuiAutocomplete-clearIndicator {
        display: none;
        svg {
          display: none;
        }
      }
    }
  }
  .MuiPaper-root {
    box-shadow: none;
    background: transparent;
    h4 {
      margin: 0;
    }
    .MuiButtonBase-root {
      background: transparent;
      padding: 0;
      .MuiAccordionSummary-content {
        padding: 0 0 1.8rem 0;
        margin: 0;
      }
    }
    .MuiCollapse-root {
      .MuiCollapse-wrapper {
        .MuiAccordionDetails-root {
          padding: 0;
          .form-group {
            margin-bottom: 1.2rem;
            > .MuiFormLabel-root {
              color: $black1;
              font-size: 1.6rem;
              margin: 0 0 0.4rem;
              font-weight: 500;
              display: block;
            }
            .autocomplete-search {
              .MuiFormControl-root {
                .MuiInputBase-root {
                  padding-right: 1.6rem !important;
                  fieldset {
                    legend {
                      span {
                        background-color: transparent;
                        display: none;
                      }
                    }
                  }
                  .icon {
                    height: 1.6rem;
                    color: $black1;
                  }
                }
                //.MuiOutlinedInput-root { border: 0; }
              }
              .search-input-box {
                position: relative;
                &::after {
                  content: "\68";
                  position: absolute;
                  top: 50%;
                  right: 16px;
                  margin-top: -12px;
                  font-size: 2.4rem;
                  color: $black1;
                  cursor: pointer;
                  font-family: "builder-enterprise" !important;
                  font-style: normal !important;
                  font-weight: normal !important;
                  font-feature-settings: normal !important;
                  font-variant: normal !important;
                  text-transform: none !important;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                }
              }
            }
          }
        }
      }
    }
  }
  .tag-wrapper {
    margin-top: 0.8rem;
    .MuiStack-root {
      .MuiButtonBase-root {
        background: $lightPurple;
        padding: 0.4rem 0.8rem;
        border: 0;
        .MuiChip-label {
          color: $black1;
          font-size: 1.2rem;
          font-weight: 500;
          padding: 0 1.2rem 0 0;
          @include line-height(12, 16);
          letter-spacing: 0.012rem;
          min-width: 2rem;
          max-width: 20rem;
        }
        .icon {
          font-size: 1rem;
          color: $black1;
          margin: 4px 0 0;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
// Add new goal modal
.add-new-goal-dialog {
  .MuiDialog-container {
    align-items: flex-end;
  }
  .MuiFormLabel-root {
    color: $black2;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0 0 0.8rem;
    @include line-height(16, 24);
  }
  .form-group {
    margin-bottom: 2.4rem;
  }
  .label {
    font-weight: 500;
  }
  &.MuiDialog-root {
    .MuiDialog-container {
      .MuiPaper-root {
        height: 70%;
        .MuiDialogContent-root {
          padding-top: 2.9rem;
        }
      }
    }
  }
}

.owner-dropbox {
  padding: 0.8rem 1.6rem;
  display: flex;
  align-items: center;
  width: 100%;
  .initials {
    height: 3.2rem;
    width: 3.2rem;
    min-width: 3.2rem;
    border-radius: 50%;
    background: $purple3;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
    color: $white;
    margin: 0 1.6rem 0 0;
    text-transform: uppercase;
    cursor: pointer;
  }
  .right-content {
    font-size: 1.4rem;
    font-weight: 400;
    @include line-height(14, 20);
    letter-spacing: 0.014rem;
    max-width: 42rem;
    @extend .truncated-text;
    .owner-name {
      color: $black1;
      font-weight: 500;
      cursor: pointer;
    }
    .email {
      color: $trueGrey;
      cursor: pointer;
    }
  }
}

.MuiPopper-root.MuiAutocomplete-popper {
  .MuiAutocomplete-option {
    flex-flow: column;
    padding: 1.6rem;
  }
}

.playground-page {  
  .page-container {
    .main-page-header {		
      padding: 0;
      justify-content: space-between;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .left-page-header {
        display: flex;
        flex-flow: column;        
        .breadcrumb-wrap {
          display: flex;
          align-items: center;
          margin-bottom: 0.8rem;
          .page-breacrumb {
            margin-bottom: 0;
          }
        }        
        .MuiButtonBase-root {
          margin: 0 1.2rem 0 0;
          &.btn-back {
            // background: $white;
            background: transparent;
            width: 3.2rem;
            height: 3.2rem;
            min-width: 3.2rem;
            // border: 0.1rem solid rgba(179, 136, 255, 0.30);
            .btn-icon {
              font-size: 1.6rem;
              margin: 0;
            }
          }
        }
      }
      .right-page-header {            
        .compnay-logo {
          padding-right: 0;
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 4rem;
          width: 10rem;
          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }	
    }	
    > .page-filters-wrapper {
      align-items: end;
      .page-title {
        margin-bottom: 0;
        h2 { margin-bottom: 0.4rem; }
      }
    }
  }
  .playground-title {
    display: flex;
    align-items: center;
    .MuiButtonBase-root {
      margin-right: 1.6rem;
    }
  }
  .playground-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 2.4rem -2.4rem 0;
    //height: calc(100vh - 20.6rem);
    height: calc(100vh - 15.8rem);
    border-top: 0.1rem solid $grey2;
    @include bp(lg-max) {
      height: inherit;
    }
    .col-header {
      height: 6.8rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 0 2.4rem;
      border-bottom: 0.1rem solid $grey2;
      background-color: $white;
      &:first-child {
        height: 5.6rem;
      }
      &.grey-bg {
        background-color: $grey1;
      }
      @include bp(smd-max) {
        height: auto;
        padding: 2.4rem;
        &:first-child {
          height: auto;
        }
      }
      &.sm {
        height: 6.8rem;
        h6,
        .serch-count {
          font-size: 1.4rem;
          line-height: 2rem;
          letter-spacing: 0.014rem;
          strong {
            font-weight: 500;
            word-wrap: break-word;
          }
        }
      }
      h5 {
        margin: 0;
      }
      h6 {
        margin: 0;
      }
      .header-left {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .templates {
          text-transform: uppercase;
          color: $grey4;
          font-size: 1.2rem;
          font-weight: 500;
          letter-spacing: 0.1rem;
          display: flex;
          align-items: center;
          > span {
            display: flex;
            align-items: center;
            padding-left: 0.8rem;
            img,
            em {
              width: 2.4rem;
              height: 2.4rem;
              margin-right: 0.8rem;
              display: inline-flex;
              align-items: center;
              &:hover {
                cursor: pointer;
              }
            }
            em {
              font-size: 1.8rem;
            }
          }
        }
        @include bp(smd-max) {
          width: 100%;
        }
      }
      .header-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include bp(smd-max) {
          width: 100%;
          margin-top: 1.5rem;
          justify-content: flex-start;
        }
        @include bp(xs-max) {
          flex-direction: column;
          align-items: flex-start;
        }
        .MuiButton-root {
          margin-right: 1.6rem;
          @include bp(xs-max) {
            margin-right: 0;
            margin-bottom: 1rem;
            width: 100%;
          }
          &:hover {
            cursor: pointer;
          }
          &:last-child {
            margin: 0 !important;
          }
          
        }
        .grey-btn {
          border: 1px solid rgba(179, 136, 255, 0.30);
          background: $white;
          font-weight: 700;
        }
        .btn-approve {
          font-weight: 700;
          padding-left: 1.7rem;          
          padding-right: 1.7rem;          
        }
        
      }
      .feature-search {
        margin-left: 3.6rem;
        width: 25.8rem;
        max-width: 100%;
        @include bp(xs-max) {
          width: 100%;
          margin: 1rem 0 0;
        }
        .form-group {
          margin: 0;
          .MuiFormControl-root {
            border-color: $grey;
            border-radius: 0.4rem;
            height: 3.6rem;
            input {
              font-size: 1.2rem;
            }
            ::placeholder {
              color: $grey11 !important;
              opacity: 1;
            }
          }
        }
      }
      //Tabs
      .tab-contest {
        .MuiButtonBase-root {
          font-size: 1.6rem;
          min-height: 5.6rem;
          text-transform: capitalize;
          padding-left: 0;
          padding-right: 0;
          min-width: auto;
          margin-right: 4.8rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .col-body {
      height: calc(100% - 15.2rem);
      padding-bottom: 0;
      @include bp(lg-max) {
        height: inherit;
      }
      .no-search-results {
        padding: 13.9rem 0;
        &.sm-pad {
          padding-top: 6.8rem;
          padding-bottom: 6.8rem;
        }
        p { padding-left: 0.4rem;
          padding-right: 0.4rem;
          word-wrap: break-word; 
        }
      }
      .no-data-block {
        padding: 5.6rem 5.6rem 0;
        @include bp(lg-max) {
          padding: 5rem 2.4rem 0;
        }
        &.is-search-result {
          padding: 0 2.4rem 2.4rem;
          .thumb {
            width: 6rem;
            margin-bottom: 0.8rem;
          }
          .no-data-wrapper {
            padding-top: 6.8rem;
            padding-bottom: 6.8rem;
          }
        }
        .no-data-wrapper {
          &.features-tab-data {
            h4 {
              color: $black1;
              font-size: 2rem;
              line-height: 3.2rem;
            }
            p {
              font-size: 1.6rem;
              line-height: 2.4rem;
            }
            button {
              color: $black2;
              .icon {
                color: $black2;
              }
            }
          }
          .sm-font {
            word-break: break-all;
          }
        }
      }
    }
    .drafts-col {
      width: 35.2rem;
      background-color: $white;
      border-right: 0.1rem solid $grey2;
      height: 100%;
      @include bp(lg-max) {
        width: 100%;
        border-right: 0;
        border-bottom: 0.1rem solid $grey2;
        height: inherit;
      }
      .draft-list {
        height: 100%;
        overflow: auto;
        padding: 2.4rem 2.6rem;
        @include custom-scroll;
        @include bp(lg-max) {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: space-between;
          height: inherit;
        }
        .draft-card {
          @include bp(lg-max) {
            width: calc(50% - 1.2rem);
          }
          @include bp(sm-max) {
            width: 100%;
          }
        }
      }
      .col-body {
        height: calc(100% - 7.6rem);
      }
    }
    .features-col {
      width: calc(100% - 35.2rem);
      height: 100%;
      @include bp(lg-max) {
        width: 100%;
        height: inherit;
      }
      .no-data-wrapper {
        padding: 9.9rem 1.5rem;
      }
      .feature-wrapper {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        width: 100%;
        @include bp(lg-max) {
          height: inherit;
        }
        .col-header {
          padding: 0 2.4rem;
        }
        .journey-col {
          width: 24.3rem;
          border-right: 0.1rem solid $grey2;
          height: 100%;
          background: $grey7;
          @include bp(lg-max) {
            height: inherit;
          }
          @include bp(xs-max) {
            width: 100%;
          }
          .journey-list {
            height: calc(100% - 6.8rem);
            overflow: hidden;
            @include custom-scroll;            
            &::-webkit-scrollbar-thumb {
              border: 0.2rem solid transparent;
            }
            .journey-outer {
              padding: 2.1rem 2rem 2.1rem 1.6rem;
              display: flex;
              border-bottom: 0.1rem solid #dfe0e6;
              position: relative;
              cursor: pointer;
              @extend .transition;
              &:hover {
                .delete-btn {
                  opacity: 1;
                  visibility: visible;
                }
              }
              &.active {
                background-color: $lightPurple;
              }
              .count {
                height: 2.4rem;
                width: 2.4rem;
                min-width: 2.4rem;
                border-radius: 50%;
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: #fff;
                background: #83889e;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
              }
              .title {
                width: calc(100% - 4.4rem);
                display: block;
                padding-left: 0.8rem;
                font-size: 1.4rem;
                line-height: 2rem;
                letter-spacing: 0.014rem;
              }
              .delete-btn {
                cursor: pointer;
                position: absolute;
                right: 1.6rem;
                top: 2.6rem;
                width: 1.2rem;
                line-height: 0;
                z-index: 1;
                opacity: 0;
                visibility: hidden;
                @extend .transition;
              }
            }
            &:hover{
             overflow: auto;
            }
          }
        }
        .journey-cards-col {
          width: calc(100% - 24.3rem);
          height: 100%;
          @include bp(lg-max) {
            height: inherit;
          }
          @include bp(xs-max) {
            width: 100%;
          }
          .col-header {
            border-bottom: 0;
            background: inherit;
            .serch-count {
              strong { word-break: break-all; }
            }
          }
          .journey-card-wrapper {
            height: calc(100% - 6.8rem);
            overflow: auto;
            @include custom-scroll;
            padding: 0 2.4rem;
            @include bp(lg-max) {
              height: inherit;
              overflow: visible;
            }
            .card-row {
              display: flex;
              flex-wrap: wrap;
              margin: 0 -1.2rem -2.4rem;
              .card-col {
                padding: 0 1.2rem;
                width: 33.33%;
                margin-bottom: 2.4rem;
                @include bp(xlg-max) {
                  width: 50%;
                }
                @include bp(sm-max) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .discussion-tab-content {
        padding: 1.6rem;
        height: calc(100% + 7.6rem);
        display: flex;
        flex-direction: column;
        .scrollable {
          max-height: 40rem;
          overflow: auto;
          @include custom-scroll;
          @include bp(smd-max) {
            max-height: inherit;
            overflow: visible;
          }
        }
        .activity-group {
          margin-bottom: 16px;
          flex: 1;
          height: 100%;
          max-height: inherit;
          &:last-child {
            margin: 0;
          }
        }
        h6 {
          padding-bottom: 0.8rem;
          border-bottom: 0.1rem solid $grey3;
          margin: 0;
        }
        .recent-activity {
          max-height: 100%;
          &.no-scroll {
            max-height: inherit;
            overflow: inherit;
          }
          @include bp(smd-max) {
            display: flex;
            flex-flow: column;
          }
          .user-link {
            padding: 2rem 0;
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            line-height: 1.4285714286;
            letter-spacing: 0.01em;
            font-weight: 500;
            cursor: pointer;
            position: relative;
            padding-right: 30px;

            .menu-trigger {
              position: absolute;
              right: 0;
              top: 50%;
              cursor: pointer;
              @include translateY(-50%);
              line-height: 0;
              display: inline-block;
              .trigger-icon {
                display: inline-block;
                line-height: 0;
                color: $grey4;
                font-size: 24px;
                @include rotate(90deg);
              }
            }

            .initials,
            .user-avatar {
              height: 3.6rem;
              width: 3.6rem;
              min-width: 3.6rem;
              border-radius: 50%;
              background: #f3ebff;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-size: 1.2rem;
              font-weight: 700;
              img {
                border-radius: 50%;
              }
            }
            .user-name {
              padding-left: 1.2rem;
            }
            .user-detail {
              padding-left: 1.2rem;
              letter-spacing: 0.014rem;
              color: $black;
              font-weight: normal;
              max-width: calc(100% - 5rem);
              .user-name {
                padding-left: 0;
                color: $purple1;
              }
            }
            .time {
              color: $grey5;
              position: relative;
              padding-left: 10px;
              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 3px;
                width: 3px;
                display: block;
                background: $grey5;
                border-radius: 50%;
              }
            }
            .description-text {
              font-size: 1.4rem;
              line-height: 1.4285714286;
              letter-spacing: 0.01em;
              font-weight: 400;
              word-wrap: break-word;
            }
          }
        }
        .add-note {
          border-radius: 1.2rem;
          border: 1px solid $grey5;
          padding: 1.6rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left-content {
            width: 80%;
            input {
              border: 0;
              background: transparent;
              font-size: 1.4rem;
              min-height: 2rem;
              width: 100%;
              &::placeholder {
                color: $grey5;
              }
            }
          }
          .right-content {
            width: 20%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .MuiButtonBase-root {
              border: 0;
              background: none;
              height: 2rem;
              width: 2rem;
              margin-left: 1.2rem;
            }
          }
        }
      }
    }
  }
}

// Playground Welcome page
.playground-welcome-page {
  .inner-content {
    .welcome-content {
      .container {
        .flex-wrapper {
          //.welcome-logo {}
          .option-selection {
            .title {
              font-size: 2.8rem;
              font-weight: 500;
              @include line-height(28, 36);
            }
            //.link-button-wrap {}
          }
        }
      }
    }
  }
}

// Project overview tab page
//.project-overview-tab-page {}

// Playground meeting
.meeting-playground-page {
  .page-container {
    .main-page-header {
      .page-breacrumb {
        margin: 0 0 0.8rem 0;
      }
      .page-title {
        margin: 0;
      }
    }
  }
  .iframe-wrapper {
    width: 100%;
    height: calc(100vh - 14.28rem);
  }
  .footer-section {
    display: flex;
    justify-content: flex-end;
    .assigned-buildcard {
      width: 100%;
      max-width: 69rem;
      padding: 3.4rem 2.4rem 2.4rem;
      margin-right: 2.4rem;
      border-radius: 2rem 0 2rem 0;
      background: $white;
      box-shadow: 0 1.2rem 3rem 0 rgba(101, 31, 255, 0.1);
      .table-wrapper {
        &.assigned-buildcard-table {
          .MuiTableContainer-root {
            .MuiTable-root {
              .MuiTableHead-root {
                .MuiTableRow-root {
                  border: 0;
                  .MuiTableCell-root {
                    border: 0;
                    background-color: $white;
                    height: auto;
                    color: $trueGrey;
                    font-size: 1.2rem;
                    font-style: normal;
                    font-weight: 500;
                    @include line-height(12, 16);
                    letter-spacing: 0.1rem;
                    text-transform: uppercase;
                  }
                }
              }
              .MuiTableBody-root {
                .MuiTableRow-root {
                  border: 0;
                  .MuiTableCell-root {
                    border: 0;
                    background-color: $white;
                    height: auto;
                    color: $black1;
                    font-size: 1.8rem;
                    font-style: normal;
                    font-weight: 500;
                    @include line-height(18, 28);
                    span,
                    a {
                      color: $purple1;
                    }
                    .link-wrap {
                      .title {
                        color: #6200ea;
                        font-size: 1.8rem;
                        font-weight: 500;
                        @include line-height(18, 28);
                        max-width: 27rem;
                        @extend .truncated-text;
                      }
                      .primary-link {
                        margin: 0 0 0 1.6rem;
                        color: #6200ea;
                        font-size: 1.2px;
                        font-weight: 400;
                        @include line-height(12, 16);
                        letter-spacing: 0.012rem;
                        text-decoration-line: underline;
                        &:hover {
                          text-decoration: none;
                          cursor: pointer;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Modals [Dialogues]
.call-may-team-dialogue {
  .MuiDialog-container {
    .MuiPaper-root {
      .MuiDialogContent-root {
        padding: 2.4rem 2.4rem 1.6rem;
      }
    }
  }
  .tab-content-wrapper {
    .MuiBox-root {
      .MuiTabs-root {
        .MuiTabs-fixed {
          .MuiTabs-flexContainer {
            .MuiButtonBase-root {
              text-transform: none;
              font-weight: normal;
            }
            .MuiTab-root {
              &.Mui-selected {
                font-weight: 500;
              }
            }
          }
          //.MuiTabs-indicator {} // bottom-border
        }
      }
    }
    // Tab content sections
    //.MuiTabPanel-root {}
    .tab-content-section {
      .title-block {
        margin: 0 0 2.4rem;
        .left-title {
          display: flex;
          align-items: center;
          .MuiSwitch-root {
            margin-right: 0.8rem;
          }
          .enable-natasha-text {
            color: $black1;
            font-size: 1.4rem;
            font-weight: 500;
          }
          img.natasha-icon {
            margin-left: 0.8rem;
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }
      .info-title-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 0.4rem 0;
        h6 {
          margin: 0;
        }
      }
      .row {
        .form-group {
          margin-bottom: 2.4rem;
          h6 {
            margin: 0 0 0.4rem;
          }
          .select-box {
            width: 100%;
          }
        }
      }
      .searchbar-wrap {
        .MuiFormControl-root {
          .MuiInputBase-root {
            display: flex;
            flex-flow: row-reverse;
            height: 6rem;
            .MuiInputAdornment-root {
              margin: 0 1.6rem 0 0;
            }
          }
        }
        .chips-wrapper {
          margin: 0.8rem 0;
          .MuiStack-root {
            .MuiButtonBase-root {
              @extend .transition;
              margin: 0.8rem 0.4rem;
              position: relative;
              background: $lightPurple;
              padding: 0.4rem 0.8rem;
              .MuiChip-label {
                transition: all 0.2s ease-in;
                padding: 0 1.2rem 0 0;
                color: $black1;
                font-size: 1.2rem;
                font-weight: 500;
                @include line-height(12, 16);
                letter-spacing: 0.012rem;
              }
              .MuiChip-deleteIcon {
                margin: 0 0;
                @extend .transition;
              }
              .icon {
                font-size: 1rem;
                color: $black1;
                margin: 4px 0 0;
              }
              &:hover {
                cursor: pointer;
                background: $lightPurple;
              }
            }
          }
        }
      }
    }
  }
}

// Todo : to be removed later
.pt-0 {
  .no-data-wrapper {
    margin-top: 4rem;
  }
}

//Remove journey modal
.remove-journey-modal {  
  .remove-btn {
    color: $black1;
    background: $red1;
    &:hover {
      background: $red1;
    }
  }
}

.selected-tags .sm-close {
  font-size: 1rem !important;
  display: inline-flex;
  color: $black1 !important;
}
