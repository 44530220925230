@import "../../assets/scss/colors";
@import "../../assets/scss/mixins";
@import "../../assets/scss/extended-classes";
@import "../../assets/scss/break-points";

.members-page {
  .upload-progress {
    margin-bottom: 3.2rem;
    p {
      font-size: 1.4rem;
      @include line-height(14, 20);
      letter-spacing: 0.01em;
      margin-top: 0.8rem;
    }
  }
  .no-data-wrapper {
    margin-top: 8.8rem;
    @include bp(sm-max) {
      margin-top: 7rem;
    }
  }
  .MuiTable-root {    
    .select-data-wrapper {
      .MuiFormControl-root {
        .MuiInputBase-root {
          justify-content: flex-end;
          .MuiInputBase-input {
            padding-right: 2.4rem !important;
            justify-content: flex-end;
            width: auto;
          }
        }
      }
    }
  }  
}

.member-info-wrapper {
  padding-top: 1.5rem;
  .info-outer {
    margin-bottom: 4rem;
    &:last-child {
      margin-bottom: 0;
    }
    .info-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.2rem;
      &.sm-space {
        margin-bottom: 0.8rem;
      }
      h6 {
        margin: 0;
      }
      .primary-link {
        text-decoration: none;
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: 0.01em;
      }
    }
  }
  .name-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.6rem;
    .initials {
      height: 4.8rem;
      width: 4.8rem;
      background: $yellow;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 2rem;
      @include line-height(20, 24);
      color: $black2;
      font-weight: 500;
      text-transform: uppercase;
    }
    .name-info {
      width: calc(100% - 4.8rem);
      padding-left: 2rem;
      .name {
        display: block;
      }
      .date {
        display: block;
        color: $grey9;
        font-size: 1.4rem;
        @include line-height(14, 24);
      }
    }
  }

  .member-info-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .left-col {
      @include bp(lg-max) {
        width: 100%;
      }
      .detailed-info-row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -1.5rem;
        .inner-col {
          margin: 0 8rem 1.5rem 0;
          @include bp(sm-max) {
            margin-right: 0;
            width: 100%;
          }
          &:last-child {
            margin-right: 0;
          }
          .info-label {
            font-weight: 500;
            font-size: 1rem;
            @include line-height(10, 16);
            display: block;
            text-transform: uppercase;
          }
          .value {
            font-size: 1.4rem;
            @include line-height(14, 24);
            color: $black2;
            display: block;
          }
        }
      }
    }
    .right-col {
      @include bp(lg-max) {
        width: 100%;
        margin-top: 2rem;
      }
    }
  }

  .space-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem -2rem;
    .space-col {
      padding: 0 1rem;
      width: 33.33%;
      margin-bottom: 2rem;
      @include bp(xlg-max) {
        width: 50%;
      }
      @include bp(smd-max) {
        width: 100%;
      }
      .space-card {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        background: $white;
        border: 0.1rem solid $lightPurple;
        @extend .be-radius;
        padding: 2.3rem 2.4rem;
        @include bp(lg-max) {
          padding: 1.6rem;
        }
        &.has-no-space {
          .icon {
            width: 4rem;
            line-height: 0;
          }
          .no-space-content {
            width: calc(100% - 4rem);
            padding-left: 1.6rem;
          }
        }
        .card-left {
          width: calc(100% - 15.1rem);
          display: flex;
          align-items: center;
          @include bp(xs-max) {
            width: 100%;
          }
          .space-icon {
            height: 4.1rem;
            width: 4.1rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.7rem;
            font-size: 2.6rem;
            color: $white;
          }
          .name {
            width: calc(100% - 4.1rem);
            @extend .truncated-text;
            padding-left: 0.8rem;
          }
        }
        .card-right {
          width: 15.1rem;
          @include bp(xs-max) {
            width: 100%;
            margin-top: 1.2rem;
          }
          .MuiButton-root {
            width: 100%;
            font-weight: 400;
          }
        }
      }
    }
  }

  .roles-wrapper {
    background: $white;
    border: 0.1rem solid $grey2;
    padding: 2.4rem 2rem 2rem;
    @extend .be-radius;
    @include bp(sm-max) {
      padding: 1.8rem 1.5rem 1.5rem;
    }
    .box-radio {
      margin-bottom: 1.2rem;
      max-width: 116rem;
      &:last-child {
        margin-bottom: 0;
      }
      .MuiFormControlLabel-label {
        font-size: 1.4rem;
        line-height: 2.4rem;
        .sub-content {
          margin: 0;
          color: $grey5;
        }
      }
    }
  }
}
