@import "../assets/scss/colors";
@import "../assets/scss/fonts";
@import "../assets/scss/mixins";
@import "../assets/scss/extended-classes";
@import "../assets/scss/break-points";

.mobile-menu-icon {
  position: fixed;
  top: 6.5rem;
  background-color: $white;
  left: 5rem;
  z-index: 9999;
  width: 1.6rem;
  height: 1.6rem;
  border: 0.1rem solid $purple;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 1px 4px rgba($black, 0.15);
 
  @include bp(lg-min) {
    display: flex;
    .sidenav-visible & {
      
      &:after {
        @include rotate(180deg);
        left: 0rem !important;
        top: -0.1rem;
      }
    }
  }
  &:after {
    content: "\77";
    font-family: $enterpriseFont;
    color:$purple;
    position: absolute;
    left: 0.1rem;
    right: 0;
    top: 0.2rem;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
  }
 
}

.menu-expand-btn {
  display: flex;
  position: fixed;
  left: 5.6rem;
  top: 5.8rem;
  z-index: 100;
  cursor: pointer;
  @extend .transition;
  .sidenav-submenu-fixed & {
    left: 26.5rem;    
    &:after {
      @include rotate(180deg);
      left: -0.2rem !important;
    }
  }
  @include bp(lg-min) {
    display: none;
  }
  @include bp(lg-min) {
    left: -1.6rem;
    .sidenav-visible & {
      left: 5.6rem;
    }
  }
  &:after {
    content: "\77";
    font-family: $enterpriseFont;
    color: $white;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    //@extend .transition;
  }
}

.be-sidenav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: $white;
  // border: 1px solid rgba(222, 214, 241, 0.5);
  box-shadow: 0px 2px 4px rgba($black, 0.15);
  width: 5.6rem;
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include bp(md-max) {
    left: -7.2rem;
    .sidenav-visible & {
      left: 0;
    }
  }
a{
  transition: none;
}
  .sidenav-outer {
    padding: 2.9rem 0rem 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $white;
    // overflow: hidden auto;
    // @include custom-scroll;
  }

  .top-sidenav {
    display: flex;
    flex-direction: column;
    align-items: center;

    @extend .no-bullets;
    // min-height: 12.8rem;
    padding: 0rem;
    background: $white;
    li {
      margin-bottom: 1.5rem;
      border-bottom: 0.1rem solid $grey2;
      width: 100%;
      padding-bottom: 1.5rem;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0.6rem;
      }
    }
    .site-logo {
      color: $purple;
      display: inline-flex;
      font-size: 2.1rem;
    }
    .natasha-link {
      display: inline-flex;
      width: 3.5rem;
    }
  }
.notifications-info{
  display: none
}
  .middle-nav {
    @extend .no-bullets;
    padding:0rem ;
    background: $white;
    > li {
      margin-bottom:0;
      padding: 1.4rem 0 0;
      
      &:last-child {
        margin-bottom: 0;
        margin-top: 1.5rem;
        border-top: 0.1rem solid $grey2;
      }
      // &:hover {
      //   .submenu-wrapper {
      //     display: block;
      //   }
      // }
      > a,
      .middle-nav-link {
        color: $black1;
        font-size: 2.4rem;
        line-height: 0;
        cursor: pointer;
        height: 3.2rem;
        // width: 3.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        &:hover {
          background-color: $lightPurple;
        }
        &.active {
          color: $purple;
        }
        &.be-buildcard {
          .space-menu-fixed.sidenav-submenu-fixed & {
            color: $purple;
          }
        }
        &.be-building {
          .company-menu-fixed.sidenav-submenu-fixed & {
            color: $purple;
          }
        }
      }
      .submenu-wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 5.8rem;
        width: 20.9rem;
        background: $white;
        box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.15);
        padding: 3.5rem 1.6rem 0;
        z-index: -1;
        overflow: hidden auto;
        @include custom-scroll;
        @include translateX(-22rem);
        @extend .transition;
        @include bp(md-max) {
          @include translateX(-26.5rem);
        }
        &.active {
          @include translateX(0rem);
          // .sidenav-submenu-fixed & {
          // }
        }
        &:not(.active).default-fixed {
          .sidenav-submenu-fixed & {
            display: block;
          }
        }
        .sidenav-submenu-fixed:not(.sidenav-visible) & {
          @include bp(md-max) {
            opacity: 0 !important;
            visibility: hidden !important;
            transform: translateX(1rem) !important;
          }
        }
        .submenu-titlebar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.8rem;
          margin-bottom: 0.3rem;
          position: relative;
          h6 {
            margin: 0;
            font-weight: 400;
          }
          .search-trigger {
            color: $black1;
            font-size: 1.5rem;
            line-height: 0;
            cursor: pointer;
            &:hover {
              color: $purple;
            }
          }
          .submenu-searchbar {
            @extend .absolute-pos;
            z-index: 1;
            background: $white;
            display: flex;
            align-items: center;
            padding: 0.8rem;
            .search-icon {
              height: 16px;
              width: 16px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              color: $black1;
            }
            .search-input {
              padding: 0 0.8rem;
              width: calc(100% - 4rem);
              border: 0;
              color: $grey4;
              font-size: 1.4rem;
              line-height: 2rem;
              outline: 0;
              box-shadow: none;
              @include placeholder {
                color: $grey4 !important;
                font-size: 1.4rem !important;
                opacity: 1 !important;
              }
            }
            .close-searchbar {
              width: 2.4rem;
              height: 2.4rem;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border: 0.1rem solid rgba(179, 136, 255, 0.3);
              border-radius: 0.6rem;
              font-size: 1rem;
              cursor: pointer;
              @extend .transition;
              &:hover {
                border-width: 0.2rem;
              }
            }
          }
        }
        .submenu-body {
          margin: 0 -1.6rem;
          padding: 0 1.6rem 3.5rem;
          // overflow: hidden auto;
          // max-height: calc(100vh - 8rem);
          // @include custom-scroll;
        }
        .custom-accordion {
          border: 0;
          border-radius: 0;
          background-color: transparent;
          .no-result-found {
            font-size: 1.4rem;
            @include line-height(14,18);
            color: $black1;
            text-align: left;
            padding-left: .8rem;
          }
          .MuiAccordion-root {
            border: 0;
            margin-bottom: 0.6rem;
            &:last-child {
              margin-bottom: 0;
            }
            .MuiAccordionSummary-content {
              display: block;
              max-width: 100%;
              font-weight: 500;
            }
            .MuiAccordionSummary-root {
              padding: 0;
              margin-left: auto;
              padding-left: 0rem;
              border: 1px solid transparent;
              border-radius: 0.8rem;
              &::after {
                content: "\4e";
                content: "\6a";
                font-size: 0.9rem;
                position: absolute;
                left: 0.6rem;
                visibility: hidden;
                opacity: 0;
              }
              &.Mui-expanded {
                &::after {
                  visibility: visible;
                  opacity: 1;
                  @include rotate(90deg);
                }
              }
              .trigger-link {
                border-color: transparent;
                background-color: transparent;
              }
              &:hover,
              &.active {
                border: 1px solid #e8e8e8;
                background-color: #f3ebff;
                &.expand-icon {
                  &::after {
                    visibility: visible;
                    opacity: 1;
                  }
                }
              }
            }
            .MuiAccordionDetails-root {
              padding: 1.2rem 1rem 0.2rem 2.6rem;
            }
          }
          .sub-project-list {
            @extend .no-bullets;
            text-align: left;
            li {
              margin-bottom: 1.5rem;
              &:last-child {
                margin-bottom: 0;
              }
              a {
                font-size: 1.2rem;
                @include line-height(12, 18);
                @extend .truncated-text;
                color: $black1;
                display: block;
                &:hover {
                  color: $purple2;
                }
                .be-folder {
                  color: $purple2;
                  margin-right: 0.6rem;
                  font-size: 1.4rem;
                  line-height: 1;
                  position: relative;
                  top: 0.2rem;
                }
              }
            }
          }
        }

        .trigger-link {
          padding: 0.8rem 0.6rem;
          display: flex;
          justify-content: start;
          align-items: center;
          font-size: 1.2rem;
          @include line-height(12, 16);
          color: $black1;
          border: 0.1rem solid transparent;
          border-radius: 0.4rem;
          text-align: left;
          height: 3.6rem;
          width: 100%;
          &:hover,
          &.active {
            border-color: $grey3;
            background-color: $lightPurple;
          }
          .icon {
            height: 2.0rem;
            width: 2.0rem;
            border-radius: 0.4rem;
            background-color: $purple1;
            color: $white;
            font-size: 1.6rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            &.is-black {
              color: $black1;
              background-color: transparent;
            }
          }
          span {
            font-weight: 400;
            font-size: 1.4rem;
            color: $black1;
            // width: calc(100% - 2.3rem);
            padding-left: 0.8rem;
            //@extend .truncated-text;
            img { 
              position: relative; 
              top: .2rem; 
              left: 0;
            }
            &.truncated-text {
              @extend .truncated-text;
            }
          }
        }

        .submenu-list {
          @extend .no-bullets;
          li {
            margin-bottom: 0.6rem;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .bottom-sidenav {
    background: #fff;
    padding: 1.5rem 1rem 0 1.5rem;
    text-align: left;
    border-top: 0.1rem solid $grey2;
    @extend .no-bullets;
    li {
      margin-bottom: 1.8rem;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: $black1;
        font-size: 1.9rem;
        line-height: 0;
        position: relative;
        width: 3.2rem;
        height: 3.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        &:hover {
          background-color: $lightPurple;
        }
        &.user-menu {
          width: 3.2rem;
          height: 3.2rem;
          overflow: hidden;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: $lightPurple;
          font-size: 1.2rem;
          font-weight: 500;
          color: $purple2;
          img {
            height: 100%;
            width: 100%;
            @extend .cover-fit;
          }
        }
        .online-badge {
          display: inline-block;
          height: 1.1rem;
          width: 1.1rem;
          border-radius: 50%;
          background: $purple1;
          border: 0.2rem solid $white;
          position: absolute;
          left: 2rem;
          bottom: 0.4rem;
        }
      }
      .user-menu-outer {
        position: fixed;
        left: 5.8rem;
        bottom: 1.4rem;
        width: 21.6rem;
        background: $white;
        box-shadow: 0 1.2rem 2.4rem rgba($black, 0.15);
        border-radius: 0.4rem;
        padding: 2rem;
        text-align: left;
        z-index: 100;
        @extend .transition;
        @include translateY(1rem);
        opacity: 0;
        visibility: hidden;
        &.open {
          @include translateY(0);
          opacity: 1;
          visibility: visible;
        }
        .close-menu {
          position: absolute;
          right: 0.9rem;
          top: 1rem;
          cursor: pointer;
          font-size: 1.2rem;
        }
        .user-info {
          display: flex;
          align-items: center;
          margin-bottom: 1.6rem;
          .dp {
            height: 3.2rem;
            width: 3.2rem;
            border-radius: 50%;
            overflow: hidden;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: $lightPurple;
            font-size: 1.2rem;
            font-weight: 500;
            color: $purple2;
            font-style: normal;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          .name {
            width: calc(100% - 3.2rem);
            padding-left: 0.8rem;
            font-size: 1.4rem;
            line-height: 2rem;
            letter-spacing: 0.01em;
            @extend .truncated-text;
          }
        }
        .links {
          @extend .no-bullets;
          li {
            margin-bottom: 0.8rem;
            &:last-child {
              margin-bottom: 0;
            }
            a {
              font-size: 1.4rem;
              line-height: 2rem;
              letter-spacing: 0.01em;
              font-weight: 500;
              display: block;
              width: 100%;
              height: auto;
              background: transparent;
              &:hover {
                color: $purple1;
              }
            }
          }
        }
      }
      .d-flex{
        cursor: pointer;
      }
    }
  }
  .top-sidenav, .middle-nav{
    .nav-text{
      display: none;
    }
  }
  .builder-logo{
    display: none;
  }
}

// Notification panel
.notifications-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 5.8rem;
  width: 46.2rem;
  padding: 1.6rem 0.8rem;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transform: translateX(1rem);
  z-index: 50;
  background: #ffffff;
  @extend .transition;
  box-shadow: 0px 12px 30px 0px rgba(101, 31, 255, 0.10);
  &.active {
    opacity: 1;
    visibility: visible;
    @include translateX(0);
  }
  @include bp(md-max) {
    left: 0rem;
  }
  .sidenav-visible & {
    @include bp(md-max) {
      left: 5.6rem;
    }
  }
  .sidenav-submenu-fixed & {
    left: 26.5rem;
  }
  .notification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.8rem 2.7rem 0.8rem;
    .mark-all-read {
      color: $purple1;
      font-size: 1.4rem;
      font-weight: 500;
      @include line-height(14, 24);
    }
    .left {
      display: flex;
      align-items: center;
      .MuiTabs-root {
        min-height: 3.6rem;
        .MuiButtonBase-root {
          padding: 4px 20px 8px 20px;
          min-height: 3.6rem;
          min-width: 7rem;
          font-size: 1.4rem;
          font-weight: 500;
          text-transform: capitalize;
        }
      }
    }
    .right {
      margin-right: -0.8rem;
      a.mark-all-read {
        text-decoration: underline;
        font-size: 1.4rem;
        font-weight: 400;
        width: 100%;
        padding: 0 .8rem;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .notifications-body {
    .MuiTabPanel-root {
      padding: 0;
      height: 100%;
      max-height: calc(100vh - 9rem);
      overflow-y: auto;
      @include custom-scroll;
    }
    .notification-row {
      border-bottom: 1px solid $grey2;
      padding: 0 0.8rem;
      &.has-greyBG,
      &:hover {
        background: $lightPurple;
      }
      @include bp(smd-max) {
        display: flex;
        flex-flow: column;
      }
      .user-link {
        padding: 2.4rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.4rem;
        line-height: 1.4285714286;
        letter-spacing: 0.01em;
        font-weight: 500;
        position: relative;
        padding-right: 30px;
        .left-content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          padding: 0 0 0 2rem;
          .blue-dot {
            width: 0.8rem;
            height: 0.8rem;
            background: $purple1;
            margin: 0 1rem 0 0;
            border-radius: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -0.5rem;
          }
        }
        //.right-content { }
        .menu-trigger {
          position: absolute;
          right: 0;
          top: 50%;
          cursor: pointer;
          @include translateY(-50%);
          line-height: 0;
          display: inline-block;
          .trigger-icon {
            display: inline-block;
            line-height: 0;
            color: $grey4;
            font-size: 24px;
            @include rotate(90deg);
          }
        }

        .initials,
        .user-avatar {
          height: 4.8rem;
          width: 4.8rem;
          min-width: 4.8rem;
          border-radius: 50%;
          background: transparent;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 1.8rem;
          font-weight: 500;
          color: $white;
          cursor: pointer;
          img {
            border-radius: 50%;
          }
        }
        .initials {
          border: 2px solid $white;
          outline: none;
          background: $purple3;
        }
        .user-detail {
          padding-left: 1.2rem;
          letter-spacing: 0.014rem;
          color: $black;
          font-weight: normal;
          .basic-info {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            flex-flow: column;
            .user-name {
              padding-left: 0;
              color: $purple1;
              cursor: pointer;
            }
            .description-text {
              max-width: 26rem;
              color: $black1;
              font-size: 1.4rem;
              font-weight: 400;
              @include line-height(14, 20);
              letter-spacing: 0.014rem;
              text-align: initial;
              word-wrap: break-word;
              a,
              strong {
                font-size: inherit;
                font-weight: 500;
                color: inherit;
                cursor: pointer;
              }
            }
          }
          .btn-wrap {
            margin: 1.2rem 0 0;
            .MuiButton-root {
              background: $purple1;
              height: 3.6rem;
              padding: 1.6rem 2.4rem;
              color: $white;
              font-size: 1.4rem;
              font-weight: 500;
              @include line-height(14, 20);
              letter-spacing: 0.014rem;
            }
          }
        }
        .time {
          color: $grey9;
          position: relative;
          padding-left: 10px;
          font-size: 1.2rem;
          font-weight: 400;
          max-width: 8rem;
          display:inline-block;
          @extend .truncated-text;          
          @include line-height(12, 18);          
        }
      }
    }
  }
}

// No data wrapper
.no-data-wrapper {
  &.notifications {
    border: 0;
    transform: translate(0, 50%);
  }
  .thumb {
    margin: 0 auto;
  }
  h4 {
    margin: 1.6rem 0 0.4rem;
    color: $black1;
    font-size: 1.6rem;
    font-weight: 500;
    @include line-height(16, 24);
  }
  p {
    color: $trueGrey;
    font-size: 14px;
    @include line-height(14, 20);
    letter-spacing: 0.014rem;
  }
}

.company-dropdown{
  transition:none !important;
}
.sidenav-visible{
  .notifications-panel{
    left: 24.2rem;
  }  
  .no-data-wrapper.no-border.sm-pad{
    position: absolute;
    left: 62px;
  }
  .submenu-titlebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
    margin-bottom: 0.3rem;
    position: relative;
    h6 {
      margin: 0;
      font-weight: 400;
    }
    .search-trigger {
      color: $black1;
      font-size: 1.5rem;
      line-height: 0;
      cursor: pointer;
      &:hover {
        color: $purple;
      }
    }
    .submenu-searchbar {
      @extend .absolute-pos;
      z-index: 1;
      background: $white;
      display: flex;
      align-items: center;
      padding: 0.8rem;
      left: -29px;
      width: 118%;
      .search-icon {
        height: 16px;
        width: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $black1;
        font-size: 1.6rem;
      }
      .search-input {
        padding: 0 0.8rem;
        width: calc(100% - 4rem);
        border: 0;
        color: $grey4;
        font-size: 1.4rem;
        line-height: 2rem;
        outline: 0;
        box-shadow: none;
        @include placeholder {
          color: $grey4 !important;
          font-size: 1.4rem !important;
          opacity: 1 !important;
        }
      }
      .close-searchbar {
        width: 2.4rem;
        height: 2.4rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid rgba(179, 136, 255, 0.3);
        border-radius: 0.6rem;
        font-size: 1rem;
        cursor: pointer;
        @extend .transition;
        &:hover {
          border-width: 0.2rem;
        }
      }
    }
  }
  .be-sidenav{
    width: 24.0rem;
    .middle-nav{
      margin-top: 1rem;
      li{
      .company-dropdown{
        transform: none;
        position: static;
        z-index: 1;
        padding: 0;
        transition:none !important;
        box-shadow: none;
        width: auto;
        margin-right: -0.5rem;
      .submenu-titlebar{
        display: none;
      }
      .submenu-body{
        padding: 0;
        margin:0;
      }
      &.open-submenu{
        display: none;
        width: 100%;
        ul{
          li{
            padding-right: 0;
            margin-top: 0;
            padding-top: 0;
          }
        }
      }
      
      }
      .be-down-arrow{
        transform: rotate(-90deg);
        font-size: 1.4rem !important;
      }
     }
    
  }
    .sidenav-outer{
      padding-top: 2rem;
    }
    .bottom-sidenav{
      padding-left: 2rem;
      li{
        a{
          width: 100%;
          justify-content: start;
          .notifications-info{
            font-size: 16px;
            padding-left: 1rem;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            .badge{
              background-color: $purple;
              width: 2.4rem;
              height: 2.4rem;
              font-size: 1.2rem;
              color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              border-radius: 6rem;
              margin-right: 1rem;
            }
           
          }
          .online-badge{
            display: none;
          }
          &:hover{
            background-color: transparent;
          }
          .be-notification{
            width: 3.2rem;
           font-size: 2.2rem;
           text-align: center;
          }
        }
        .notifications-info{
          display: flex;
          flex-wrap: wrap;
          width: 75%;
          padding-left: 1rem;
          span{
            font-size: 1rem;
            color: $grey11;
            display: block;
          }
        }
        &:last-child{
          a{
            width: auto;
          }
        }
      }
     
     }
  }

  .bottom-sidenav li .user-menu-outer{
    left: 1rem;
  }


.middle-nav{
  overflow: hidden auto;
    @include custom-scroll;
    max-height: calc(74vh - 8rem);
    li{
      &:last-child{
        padding-top: 1rem;
        margin-top: 1rem;
        a{
        &:hover{
          background-color: transparent;
        }
      }
      }
      .MuiAccordionSummary-root{
        padding-left: 1rem !important;
      }
    }
}

.custom-accordion{
  margin-top: 1rem;
}
.natasha-link{
  width: 100% !important;
  border-radius: 1rem;
  padding: 0.5rem;
  &:hover{
  background-color: $lightPurple;
}
}

.top-sidenav li:last-child{
  padding: 0.8rem;
}
  
  .middle-nav, .top-sidenav{
    text-align: left;
    .nav-text{
      font-size: 1.6rem;
      padding-left: 1rem;
      display: inline-flex;
      width: 170px;
      justify-content: space-between;
      cursor: pointer;
      align-items: center;
    }
    li{
      padding:0 0.8rem;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      a{
        padding: 1rem 1.5rem;
        height: 5rem;
        transition: none;
      }
    }
  }
  .build-hub{
    display: flex;
    justify-content: space-between;
    width: 84%;
    align-items: center;
    .submenu-searchbar{
      display: flex;
    }
  }
  .be-builder-logo-b{
    display: none !important;
  }
  .builder-logo{
    display: inline-flex;
    max-width: 15.5rem;
  }
  .mobile-menu-icon{
    left: 23rem;
    top: 6.3rem;
  }
  .main-content{
    padding-left: 23.6rem;
    transition:none
  }

}

.space-menu-fixed{
  .open-submenu{
    display: block !important;
    cursor: pointer;
    .icon{
      font-size: 1.6rem !important;
    }
    li{
      a{
       
        &.active{
          background-color: $lightPurple1 !important;
          border: 0 !important;
         
          span{
            color: $purple !important;
          }
          .icon{
            color: $purple !important;
          }
        }
      }
    }
  }
  
    .be-down-arrow{
      transform: rotate(0deg) !important;
      position: relative;
      top: 3px;
      
    }
   
}

.build-hub{
 
  .submenu-searchbar{
    display: none;
  }
}