.full-width {
  width: 100% !important;
}
.login-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../images/login-bg.png");
  background-position: bottom right;
  background-size: auto;
  background-repeat: no-repeat;
  &.is-signup {
    flex-wrap: wrap;
    align-items: inherit;
    justify-content: inherit;
    background-image: none;
    .signup-slider {
      width: 42.2%;
      max-height: 100vh;
      background: $lightPurple;
      border-radius: 0 6.4rem 6.4rem 0;
      padding: 10rem 0 2rem;
      position: relative;
      @include bp(smd-max) {
        width: 100%;
        padding-top: 7rem;
        border-radius: 0 0 6.4rem 6.4rem;
      }
      .logo {
        display: inline-flex;
        max-width: 15.4rem;
        margin-bottom: 0;
        position: absolute;
        left: 7.4rem;
        top: 6.2rem;
        @include bp(lg-max) {
          left: 5rem;
          top: 4rem;
        }
        @include bp(sm-max) {
          left: 3rem;
          max-width: 13rem;
          top: 3rem;
        }
      }
      .swiper {
        height: 100%;
        padding: 5rem 0;
        @include bp(sm-max) {
          padding: 3rem 0;
        }
        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 7.4rem;
          @include bp(lg-max) {
            padding: 0 5rem;
          }
          @include bp(sm-max) {
            padding: 0;
          }
        }
      }
      .signup-slide {
        .thumb-img {
          text-align: center;
          img {
            @include bp(smd-max) {
              max-width: 35rem;
            }
            @include bp(sm-max) {
              max-width: 25rem;
            }
          }
        }
        .slider-content {
          margin-top: 5.5rem;
          @include bp(smd-max) {
            margin-top: 3rem;
            text-align: center;
            padding: 0 2rem;
          }
        }
      }
    }
    .signup-form-wrapper {
      width: 57.8%;
      @include bp(smd-max) {
        width: 100%;
      }
      .login-wrapper {
        padding: 2rem 0;
        padding: 0;
        @include bp(smd-max) {
          min-height: inherit;
          padding: 5rem 0;
        }
      }
    }
  }
  .login-wrapper {
    width: 60rem;
    max-width: calc(100% - 3rem);
    height: 100%;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    padding: 12rem 0;
    &.lg {
      width: 65.6rem;
    }
    &.secondary {
      align-items: flex-start;
      padding: 8.8rem 0;
      .logo {
        margin-bottom: 14.2rem;
        @include bp(lg-max) {
          margin-bottom: 10rem;
        }
        @include bp(sm-max) {
          margin-bottom: 7rem;
        }
      }
    }
    .login-outer {
      width: 100%;
      .form-group {
        margin-bottom: 3.2rem;
        &.secondary {
          margin-bottom: 2.4rem;
        }
        .form-error {
          margin-top: 0.8rem;
        }
      }
    }
    .checkbox-form-group {
      margin-bottom: 2rem;
    }
    .logo {
      display: block;
      text-align: center;
      // position: fixed;
      // left: 8rem;
      // top: 5rem;
      &.secondary {
        margin-bottom: 3.2rem;
      }
    }
    .signup-title {
      margin-bottom: 3.2rem;
      &.secondary {
        text-align: center;
        h1 {
          margin-bottom: 0.8rem;
        }
        p {
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: $black1;
          font-weight: 400;
          padding: 0 0;
          &.user-email {
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: $black1;
            margin-top: 3.2rem;
          }
        }
      }
      h1 {
        margin-bottom: 1.6rem;
        font-size: 2.2rem;
        font-weight: 400;
        @include line-height(22, 32);
        &:last-child {
          margin-bottom: 0;
        }
        &.bold {
          font-weight: 500;
        }
      }
      p {
        font-size: 1.8rem;
        @include line-height(18, 25);
        color: $trueGrey;
      }
      .logo {
        position: relative;
        left: auto;
        top: auto;
        margin: 0 0 3.2rem 0;
      }
    }

    .login-title {
      text-align: center;
      margin-bottom: 4.1rem;
      h1 {
        margin-bottom: 1.3rem;
        letter-spacing: -0.02em;
      }
      p {
        font-size: 1.6rem;
        @include line-height(16, 28);
      }
    }
    .forgot-link {
      padding: 0 0 2.2rem;
      &.secondary {
        padding: 0;
        margin-top: 3.2rem;
        text-align: center;
      }
      @include bp(sm-max) {
        padding: 0.8rem 0;
      }
      .primary-link {
        text-decoration: none;
        font-weight: 500;
      }
    }
    .inner-form {
      max-width: 47.7rem;
      margin: 0 auto;
      text-align: center;
      &.lg {
        max-width: 52.8rem;
      }
      .reset-link-sent {
        font-size: 1.6rem;
        @include line-height(16, 28);
        margin-top: 6.6rem;
        @include bp(sm-max) {
          margin-top: 4rem;
        }
        p {
          margin: 0;
        }
      }
      .mail-btns {
        display: flex;
        justify-content: space-between;
        margin-top: 2.8rem;
        @include bp(xsm-max) {
          flex-direction: column;
        }
        .MuiButton-root {
          width: calc(50% - 0.8rem);
          height: 4rem;
          @include bp(xsm-max) {
            width: 100%;
            margin-bottom: 1.2rem;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    .btn-block {
      margin-top: 1rem;
      &.lg-space {
        margin-top: 3.2rem;
      }
      .MuiButton-root {
        width: 100%;
      }
    }
    .static-link {
      margin-top: 3.4rem;
      .inner-form-link {
        color: $black1;
        text-decoration: underline;
        font-size: 1.6rem;
        @include line-height(16, 28);
        &:hover {
          color: $purple1;
        }
      }
    }
    .primary-link {
      &.is-bottom-sticky {
        position: absolute;
        bottom: 6rem;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
    }
  }
}

.success-generic-box {
  max-width: 65.6rem;
  margin: 0 auto;
  background: $white;
  border-radius: 0.8rem;
  padding: 7.7rem 8.7rem;
  @include bp(lg-max) {
    padding: 6rem 7rem;
  }
  @include bp(sm-max) {
    padding: 5rem 1.6rem;
  }
  .success-title {
    margin-bottom: 4.4rem;
    h2 {
      margin-bottom: 1.3rem;
    }
  }
  .MuiButton-root {
    min-width: 18rem;
  }
  .icon {
    display: inline-block;
    margin-bottom: 1.8rem;
  }
  h2 {
    margin-bottom: 3.2rem;
    &:last-child {
      margin: 0;
    }
  }
  p {
    margin-bottom: 3.2rem;
    &:last-child {
      margin: 0;
    }
  }
  .primary-link {
    text-decoration: none;
    font-weight: 500;
    line-height: 2.5rem;
  }
}

.page-header {
  padding:1.8rem 4.8rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  @include bp(lg-max) {
    padding: 3rem 3rem 0;
  }
  @include bp(sm-max) {
    padding: 2rem 2rem 0;
  }
  .left-header {
    @include bp(lg-max) {
      width: 100%;
    }
    .breadcrumb-wrapper {
      margin-bottom: 0.8rem;
    }
    .title-block {
      h2 {
        margin-bottom: 0.8rem;
      }
      p {
        font-size: 1.6rem;
        @include line-height(16, 24);
        color: $trueGrey;
      }
    }
  }
  .support-block {
    padding: 1.5rem 1.6rem;
    background: $white;
    border: 0.1rem solid $grey8;
    @extend .be-radius;
    display: flex;
    align-items: center;
    width: 30.8rem;
    @include bp(lg-max) {
      order: -1;
      margin-left: auto;
      display: none;
    }
    .support-avatar {
      display: inline-flex;
      height: 6.4rem;
      width: 6.4rem;
      overflow: hidden;
      border-radius: 50%;
    }
    .support-content {
      width: calc(100% - 6.4rem);
      padding-left: 1.6rem;
      font-size: 1.6rem;
      @include line-height(16, 24);
      * {
        display: block;
      }
      .name {
        font-weight: 500;
      }
      a {
        &.tel {
          color: $black;
          &:hover {
            color: $purple;
          }
        }
      }
    }
  }
  &.pricing-header{
    border-bottom: 1px solid $grey2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .getin-touch{
      background-color: $lightPurple;
      height: 4.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 340px;
      font-size: 1.4rem;
      color: $black2;
      font-weight: 500;
      margin-left: 1.6rem;
      @include bp(sm-max) {
        margin-left: 0;
        margin-top: 1rem;
      }
      img{
        margin-right: 1rem;
      }
    }
    .right-header{
      display: flex;
      align-items: center;
      @include bp(lg-max) {
        margin: 2rem 0;
      }
      @include bp(sm-max) {
        flex-wrap: wrap;
        margin: 1rem 0 2rem;
      }
    }
  }
}

.breadcrumb-wrapper {
  .breadcrumb-links {
    @extend .no-bullets;
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 1rem;
      font-size: 1.4rem;
      @include line-height(14, 32);
      color: $black1;
      max-width: 30rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:last-child {
        margin: 0;
        color: $purple1;
        text-transform: lowercase; 
        &:first-letter {
          text-transform: uppercase;
        }
        &::after {
          display: none;
        }
      }
      &::after {
        content: ">";
        margin-left: 1rem;
        color: $trueGrey;
      }
      a {
        color: $trueGrey;
        text-transform: lowercase; 
        &:first-letter {
          text-transform: uppercase;
        }
        &:hover {
          color: $purple1;
        }
      }
    }
  }
}

.page-top-navigation {
  border-bottom: 0.1rem solid $grey3;
  margin: 0 -2.4rem;
  padding: 0 2.4rem;
  white-space: nowrap;
  height: 3.6rem;
  overflow: hidden;
  @include bp(lg-max) {
    margin: 0 -2.4rem;
    padding: 0 2.4rem;
  }
  @include bp(lg-max) {
    margin: 0 -2rem;
    padding: 0 2rem;
  }
  ul {
    @extend .no-bullets;
    display: flex;
    padding-bottom: 20px;
    overflow: auto;
    li {
      margin-right: 2.4rem;
      @include bp(smd-max) {
        margin-right: 2.4rem;
      }
      &:last-child {
        margin-right: 0;
      }
      .link-btn {
        font-size: 1.6rem;
        line-height: 1.75;
        color: $trueGrey;
        padding-bottom: 0.7rem;
        font-weight: 400;
        display: inline-block;
        position: relative;
        padding-right: 1.6rem;
        cursor: pointer;
        &::after {
          content: "\4e";
          font-family: $enterpriseFont;
          position: absolute;
          right: 0;
          top: calc(50% - 0.7rem);
          height: 0.8rem;
          width: 0.8rem;
          font-size: 0.8rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }
      }
      a {
        position: relative;
        font-size: 1.6rem;
        @include line-height(16, 28);
        color: $trueGrey;
        padding-bottom: 0.7rem;
        font-weight: 400;
        &:hover,
        &.active {
          color: $purple1;
          font-weight: 500;
          // text-shadow:
          //   0 0 0.05rem $purple1,
          //   0 0 0.05rem $purple1;
          &::after {
            left: 0;
            right: 0;
            width: 100%;
          }
        }
        &:hover:not(.active) {
          font-weight: 400;
        }
        &:after {
          content: "";
          position: absolute;
          left: 50%;
          right: 50%;
          bottom: 0;
          width: 0;
          height: 0.2rem;
          background-color: $purple1;
          @extend .transition;
        }
      }
    }
  }
}

.enterprise-plan-card {
  background: $white;
  border: 0.1rem solid $purple2;
  border-radius: 0 0 0 2.4rem;
  overflow: hidden;
  transition: all 0.2s ease-out;
  &.selected {
    border:0.3rem solid $purple;
    box-shadow:
      0 0.8rem 2rem rgba(64, 0, 152, 0.04),
      0 0 0 0.1rem $purple;
    .plan-name-wrapper {
      .plan-selected-icon {
        opacity: 1;
        visibility: visible;
      }
    }
    .MuiButton-root{
      background-color: $green !important;
      color: $black2 !important;
    }
  }
  &.horizontal-card {
    display: flex;
    flex-wrap: wrap;
    padding: 2.4rem;
    align-items: center;
    @include bp(lg-max) {
      padding: 1.5rem;
    }
    @include bp(smd-max) {
      padding: 0;
    }
    &.selected {
      border-width: 0.1rem;
    }
    .plan-name-wrapper {
      width: 49%;
      padding: 1.6rem;
      @extend .be-radius;
      @include bp(smd-max) {
        width: 100%;
        border-radius: 0;
      }
      .plan-icon {
        display: flex;
        justify-content: center;
        height: auto;
        @include bp(smd-max) {
          justify-content: flex-start;
        }
      }
    }
    .plan-detail-wrapper {
      width: 51%;
      padding: 1rem 0.6rem 1rem 3.7rem;
      @include bp(smd-max) {
        width: 100%;
        padding: 2rem;
      }
    }
  }
  &.sm-card {
    .plan-name-wrapper {
      padding: 1.6rem 2.4rem;
      .plan-icon {
        height: 8rem;
        img {
          max-height: 8rem;
        }
      }
      .credit-info {
        .credit-left {
          font-size: 1.4rem;
        }
      }
    }
    .plan-detail-wrapper {
      padding: 1.6rem 2.4rem;
    }
  }
  &.disabled {
    cursor: not-allowed;
    .plan-name-wrapper {
      padding: 2.4rem 2.3rem;
      .plan-icon {
        opacity: 0.3;
      }
    }
    .plan-detail-wrapper {
      .info-body {
        text-align: center;
        h6,
        p {
          color: $grey12;
        }
      }
    }
  }
  &.is-info-card {
    border-color: $purple2;
    &.disabled {
      border-color: $lightPurple;
    }
    .plan-name-wrapper {
      padding: 2.4rem 2.3rem;
      .plan-icon {
        height: 8.4rem;
        justify-content: center;
      }
    }
  }
  &.flex-card {
    display: flex;
    flex-direction: column;
    .plan-detail-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      .info-body {
        margin-bottom: 2.4rem;
      }
      .plan-btn-wrap {
        margin-top: auto;
      }
    }
  }
  .plan-name-wrapper {
    padding:6rem 2.3rem 2.4rem;
    background-color: $lightPurple;
    position: relative;
    .badge {
      position: absolute;
      left: 2.3rem;
      top: 2.8rem;
      display: inline-block;
      font-size: 1.4rem;
      color: $purple1;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .plan-selected-icon {
      display: inline-flex;
      position: absolute;
      right: 1.6rem;
      top: 1.6rem;
      font-size: 2.4rem;
      color: $purple;
      opacity: 0;
      visibility: hidden;
      @extend .transition;
    }
    .plan-icon {
      display: flex;
      margin-bottom: 1.4rem;
      height: 12.4rem;
      align-items: center;
      &:last-child {
        margin: 0;
      }
    }
    h2,
    .h2 {
      color: $purple1;
      margin-bottom: 0.8rem;
      font-size: 2.8rem;
      @include bp(sm-max) {
        font-size: 2.2rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $black1;
    }
    h4 {
      font-size: 1.8rem;
      margin: 0;
    }
  }
  .plan-detail-wrapper {
    padding: 2.4rem;
    .info-body {
      text-align: center;
      h6 {
        margin-bottom: 0.8rem;
      }
      p {
        font-size: 1.6rem;
        @include line-height(16, 24);
        color: $trueGrey;
      }
    }
    .credit-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.4rem;
      .credit-left {
        font-weight: 500;
        align-items: flex-end;
        font-size: 1.8rem;
        .credit-no {
          font-size: 2.4rem;
          @include line-height(32, 36);
          color: $black1;
          margin-right: 0.4rem;
          @include bp(sm-max) {
            font-size: 2.2rem;
          }
        }
      }
      .pricing {
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: $grey4;
        text-align: right;
      }
      span {
        display: block;
      }
    }
    .plan-btn-wrap {
      margin-top: 2.4rem;
      .MuiButton-root {
        @extend .w-100;
        margin-bottom: 1.3rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .white-btn {
        color: $black1;
        border: 0.1rem solid #B388FF;
      }
    }
  }
  .plan-success-outer {
    background: #f3ebff;
    padding: 5.1rem 2.4rem 2.4rem;
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100%;
    .icon {
      display: inline-block;
      max-width: 8.4rem;
      margin-bottom: 1.6rem;
    }
    h6 {
      margin-bottom: 0.8rem;
    }
    p {
      color: $trueGrey;
      font-size: 1.6rem;
      @include line-height(16, 24);
    }
  }


  &:hover{
    // border:0.3rem solid $purple;
    box-shadow:
      0 0.8rem 2rem rgba(64, 0, 152, 0.04),
      0 0 0 0.1rem $purple;
      .MuiButton-root{
        background-color: $green !important;
        color: $black2 !important;
      }
  }
}

.tooltip-wrapper {
  .tooltip-trigger {
    display: flex;
    .trigger-text {
      max-width: calc(100% - 2.2rem);
      @extend .truncated-text;
    }
    .tooltip-icon {
      margin-left: 0.8rem;
      color: $trueGrey;
      line-height: 0;
      cursor: pointer;
      font-size: 1.4rem;
      position: relative;
      &:hover {
        .tooltip-content {
          @include translate(-50%, 0rem);
          opacity: 1;
          visibility: visible;
          &.right-align {
            @include translate(0, 0rem);
          }
        }
      }
    }
    .tooltip-content {
      position: absolute;
      padding-top: 0.8rem;
      z-index: 1;
      top: 100%;
      font-size: 1.2rem;
      @include line-height(12, 16);
      letter-spacing: 0.01em;
      color: $black1;
      width: 32.4rem;
      left: 50%;
      font-style: normal;
      @include translate(-50%, 1rem);
      @extend .transition;
      opacity: 0;
      visibility: hidden;
      cursor: default;
      text-align: left;
      white-space: pre-wrap;
      @include bp(sm-max) {
        width: 20rem;
      }
      &.sm-width {
        width: 17.8rem;
      }
      &.right-align {
        left: auto;
        right: -1.5rem;
        @include translate(0, 1rem);
        &::before {
          left: auto;
          right: 1rem;
        }
      }
      &.reverse {
        top: auto;
        bottom: 100%;
        padding-top: 0;
        padding-bottom: 0.8rem;
        @include translate(-50%, -1rem);
        .content-inner {
          box-shadow: 0 -0.6rem 1.5rem -0.3rem rgba($black, 0.15);
        }
        &::before {
          top: auto;
          bottom: 0.2rem;
        }
      }
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        top: 0.2rem;
        box-shadow: 0 0 1rem 0.1rem rgba($black, 0.15);
        height: 1.2rem;
        width: 1.2rem;
        background: $white;
        z-index: -1;
        display: block;
      }
      .content-inner {
        background: $white;
        border-radius: 0.3rem;
        box-shadow: 0 0.6rem 1.5rem -0.3rem rgba($black, 0.15);
        padding: 0.8rem 1.6rem;
      }
    }
  }
}

.custom-table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border: 0.1rem solid $purple3;
  border-radius: 1.2rem 1.2rem 0 0;
  background-color: $white;
  text-align: left;
  &.secondary {
    border-radius: 0.8rem;
    th {
      background: $grey7;
      height: 4.8rem;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 400;
      padding: 0 1.2rem;
      &:first-child {
        padding-left: 1.2rem;
        border-radius: 0.8rem 0 0 0;

      }
      &:last-child {
        border-radius: 0 0.8rem 0 0;
      }
      &.checkbox-col {
        width: 25rem;
        min-width: 25rem;
        text-align: center;
        @include bp(lg-max) {
          width: 15rem;
          min-width: 15rem;
        }
        @include bp(smd-max) {
          width: 10rem;
          min-width: 10rem;
        }
      }
      .sm-font {
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
    td {
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.014rem;
      padding: 0.2rem 1.2rem;
      border-right: 0;
      &:first-child {
        padding-left: 1.2rem;
      }
    }
  }
  th {
    font-size: 2rem;
    line-height: 3.2rem;
    height: 8.6rem;
    border-bottom: 0.1rem solid $grey18;
    border-right: 0.1rem solid $grey18;
    padding: 0 1.9rem;
    font-weight: 500;
    background-color: $lightPurple;
    width: 20%;
    color: $black1;
    &:first-child {
      padding-left: 2.4rem;
      border-radius: 1.3rem 0 0 0;
    }
    &:last-child{
      border-radius:0 1.3rem 0 0;
    }
    .price {
      display: block;
      color: $grey4;
      font-weight: 400;
      font-size: 1.6rem;
      @include line-height(16, 24);
    }
  }
  td {
    padding: 1.2rem 1.9rem;
    border-bottom: 0.1rem solid $grey18;
    font-size: 1.6rem;
    height: 7.2rem;
    @include line-height(16, 24);
    border-right: 0.1rem solid $grey18;
    span{
      color: $grey11;
    }
    a{
      font-weight: 600;
    }
    &:first-child {
      padding-left: 1.5rem;
    }
    // &:last-child {
    //   border-right: 0;
    // }
    .inner-title {
      font-weight: 500;
      margin-bottom:0;
      em{
        position: relative;
        top: 2px;
        margin-left: 0.5rem;
      }
    }
    .be-tick {
      color: $purple1;
    }
    .check-icon {
      background-color: $green1;
      border-radius: 1rem;
      width: 2.8rem;
      height: 2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &.yellow {
        background-color: $amber;
      }
    }
  }
  tr {
    &:last-child {
      td {
        border-bottom: 0;
      }
    }
    &.lg-height {
      td {
        height:7.2rem;
        vertical-align: middle;
      }
    }
    
  }
}

.custom-accordion {
  border: 0.1rem solid $grey2;
  background: $white;
  border-radius: 1.2rem;
  .MuiAccordion-root {
    box-shadow: none;
    color: $black;
    margin: 0;
    border-bottom: 0.1rem solid $grey2;
    &::before {
      display: none;
    }
    &:first-of-type {
      border-radius: 1.2rem 1.2rem 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 1.2rem 1.2rem;
      border-bottom: 0;
    }
    &.Mui-expanded {
      margin: 0;
    }
    .MuiAccordionSummary-root {
      padding: 2.35rem 1.6rem;
      background: transparent;
      position: relative;
      min-height: inherit;
      @include bp(lg-max) {
        padding: 1.8rem 1.6rem;
      }
      @include bp(sm-max) {
        padding: 1.5rem 1.2rem;
      }
      &:after {
        border: 0;
        transform: none;
        content: "\6f";
        font-family: $enterpriseFont;
        padding: 0;
        color: $black;
        line-height: 0.6;
        font-size: 2.4rem;
        @extend .transition;
        @include bp(lg-max) {
          font-size: 2rem;
        }
        @include bp(sm-max) {
          font-size: 1.6rem;
        }
      }
      &.Mui-expanded {
        min-height: inherit;
        &::after {
          @include rotate(180deg);
        }
      }
    }
    .MuiAccordionSummary-content {
      margin: 0;
      h6 {
        margin: 0;
        .country-label {
          display: block;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: $trueGrey;
        }
      }
    }
    .MuiAccordionDetails-root {
      padding: 0 1.6rem 1.6rem;
    }
  }
}

.secondary-accordion {
  .MuiAccordion-root {
    margin-bottom: 1.6rem;
    border-radius: 0.8rem;
    box-shadow: 0 0.2rem 0.4rem 0 rgba($black, 0.15);
    border: 0;
    padding: 1.6rem;
    color: $black1;
    &::before {
      display: none;
    }
    &:first-of-type {
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
    }
    &:last-of-type {
      margin-bottom: 0;
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }
    .MuiAccordionSummary-root {
      min-height: inherit;
      padding: 0;
      &.Mui-expanded {
        .left-heading {
          .icon {
            @include rotate(90deg);
          }
        }
        .accordion-heading {
          .right-btns {
            display: flex;
          }
        }
        .roles-desc {
          position: static;
          margin-left: 4.4rem;
          @include bp(lg-max) {
            margin-left: 3rem;
            width: 100%;
          }
          @include bp(sm-max) {
            display: block;
            margin-left: 0;
          }
          p {
            -webkit-line-clamp: inherit;
          }
        }
      }
      .roles-desc {
        width: 60%;
        position: absolute;
        right: 0;
        top: 0.8rem;
        @include bp(sm-max) {
          position: static;
          width: 100%;
          display: none;
          margin-top: 2.5rem;
        }
        p {
          font-size: 1.2rem;
          line-height: 1.6rem;
          letter-spacing: 0.012rem;
          color: $trueGrey;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .MuiAccordionSummary-content {
        margin: 0;
        flex-wrap: wrap;
      }
    }
    .MuiAccordionDetails-root {
      padding: 4rem 0 0 0;
    }
  }
}

.faq-section {
  h2 {
    margin-bottom: 2.5rem;
  }
  .contact-block {
    margin-top: 3.2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .faq-accordion{
    background: transparent;
  }
}

.plan-selection-row {
  margin: 0 -1.5rem -3rem;
  display: flex;
  flex-wrap: wrap;
  .plan-selection-col {
    padding: 0 1.5rem;
    width: 25%;
    margin-bottom: 3rem;
    @include bp(lg-max) {
      width: 50%;
    }
    @include bp(sm-max) {
      width: 100%;
    }
    .enterprise-plan-card {
      height: 100%;
      @include bp(sm-max) {
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.checkout-steps-header {
  position: fixed;
  left: 5.6rem;
  right: 0;
  top: 0;
  z-index: 5;
  background-color: $grey7;
  @extend .transition;
  @include bp(md-max) {
    left: 0;
  }
  .sidenav-submenu-fixed & {
    left: 26.5rem;
    @include bp(lg-max) {
      left: 5.6rem;
    }
    @include bp(md-max) {
      left: 0;
    }
  }
  .steps-list {
    @extend .no-bullets;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 33.33%;
      border-right: 0.1rem solid $grey2;
      @include bp(xs-max) {
        width: 25%;
        &.active {
          width: 50%;
          a {
            .step-name {
              display: block;
            }
          }
        }
      }
      &:last-child {
        border-right: 0;
      }
      a {
        font-size: 1.6rem;
        @include line-height(16, 24);
        color: $grey5;
        padding: 1rem 4rem;
        position: relative;
        font-weight: 500;
        border-bottom: 0.1rem solid $grey2;
        height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include bp(lg-max) {
          height: 7.5rem;
        }
        @include bp(sm-max) {
          height: 6rem;
          font-size: 1.4rem;
          padding: 1rem 2rem;
        }
        &.active {
          &:after {
            left: 0;
            right: 0;
            width: 100%;
          }
          .step-no {
            color: $black1;
          }
          .step-name {
            color: $black;
          }
        }
        &:after {
          content: "";
          position: absolute;
          left: 50%;
          right: 50%;
          width: 0;
          bottom: 0;
          @extend .transition;
          height: 0.3rem;
          background-color: $purple1;
        }
        .step-no {
          display: block;
          font-size: 1rem;
          @include line-height(10, 16);
          text-transform: uppercase;
        }
        .step-name {
          display: block;
          @include bp(xs-max) {
            display: none;
          }
        }
      }
    }
  }
}

.internal-section-title {
  margin-bottom: 1.6rem;
  h4 {
    margin-bottom: 0.4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  h6 {
    margin-bottom: 0.8rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    font-size: 1.6rem;
    @include line-height(16, 24);
    color: $trueGrey;
  }
}

.member-permission-wrapper {
  .permission-form-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > .MuiButton-root {
      width: 9.8rem;
      @include bp(xs-max) {
        width: 100%;
      }
    }
    .member-input {
      position: relative;
      width: calc(100% - 11rem);
      @include bp(xs-max) {
        width: 100%;
        margin-bottom: 1.5rem;
      }
      .MuiInputBase-root.sm-input {
        .MuiInputBase-input {
          padding-right: 10rem;
        }
      }
      .MuiButton-root {
        &.sm-btn {
          margin-top: 0.2rem;
        }
      }
      .menu-with-arrow {
        position: absolute;
        right: 0.1rem;
        top: 0;
      }
    }
  }
  .users-list {
    max-height: 16rem;
    overflow: auto;
    @include custom-scroll;
    @include bp(sm-max) {
      padding-left: 0;
      margin-top: 1.6rem;
    }
    .menu-with-arrow {
      width: 9.8rem;
      .MuiButtonBase-root.sm-btn {
        height: 3.2rem;
      }
    }
    .user-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .user-info {
      display: flex;
      align-items: center;
      width: calc(100% - 9.8rem);
      .dp {
        display: inline-flex;
        height: 3.2rem;
        width: 3.2rem;
        border-radius: 50%;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          @extend .cover-fit;
        }
      }
      .name {
        width: calc(100% - 3.2rem);
        padding: 0 0.8rem;
        font-size: 1.4rem;
        @extend .truncated-text;
      }
    }
  }
}

.boxed-title-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &.sm {
    .boxed-title {
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 0 0.6rem;
      margin-bottom: 0;
    }
  }
  .boxed-title {
    display: inline-block;
    font-weight: 500;
    font-size: 4.8rem;
    @include line-height(48, 60);
    color: $black1;
    background: $white;
    border: 0.2rem solid $black;
    border-top:0;
    padding: 0 1.5rem 0 0.6rem;
    margin-bottom: 0.5rem;
    &:first-child {
      border-top: 0.2rem solid $black;
    }
    @include bp(xlg-max) {
      font-size: 4rem;
    }
    @include bp(sm-max) {
      font-size: 3rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.swiper {
  &.secondary-swiper {
    .swiper-pagination {
      margin-top: 1.6rem;
      position: relative;
      z-index: 1;
      top: auto;
      bottom: auto;
      .swiper-pagination-bullet {
        height: 0.4rem;
        width: 0.4rem;
        background-color: $grey10;
        margin-right: 0.4rem;
        &:last-child {
          margin-right: 0;
        }
        &.swiper-pagination-bullet-active {
          width: 0.4rem;
          background-color: $purple1;
        }
      }
    }
  }
  .swiper-pagination {
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .swiper-pagination-bullet {
      margin: 0;
      height: 0.6rem;
      width: 0.6rem;
      margin-right: 0.6rem;
      @extend .transition;
      background-color: rgba($purple1, 0.2);
      opacity: 1;
      border-radius: 2rem;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.swiper-pagination-bullet-active {
        width: 1.4rem;
      }
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  height: 3.6rem;
  width: 3.6rem;
  min-width: 3.6rem;
  top: 44%;
  border-radius: 50%;
  background: $white;
  opacity: 0.6;
  box-shadow: 0 0.6rem 1.5rem -0.3rem rgba($black, 0.15);
  &.sm {
    height: 1.6rem;
    width: 1.6rem;
    min-width: 1.6rem;
    &::after {
      font-size: 0.8rem;
    }
  }
  &:after {
    font-family: $enterpriseFont;
    content: "\77";
    font-size: 1.2rem;
    color: $black1;
  }
}

.swiper-button-prev {
  left: 1.4rem;
  right: auto;
  &::after {
    @include rotate(180deg);
  }
}
.swiper-button-next {
  right: 1.4rem;
  left: auto;
}

.swiper-inline-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  .swiper-pagination-wrapper {
    display: inline-flex;
    align-items: center;
  }
  .swiper-pagination {
    position: static;
    margin: 0 1rem;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: static;
    opacity: 1;
    margin: 0;
    transform: none;
  }
}

.space-list-slide {
  .space-slide-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.2rem;
    .space-slide-col {
      padding: 0 1.2rem;
      width: 33.33%;
      .card-template {
        margin: 0;
        height: 100%;
      }
    }
  }
}

// Square three dot btn
.square-btn-three-dots {
  width: 4.8rem !important;
  min-width: 4.8rem !important;
  height: 4.8rem !important;
  .icon {
    display: flex;
    font-size: 2rem !important;
  }
}

// Three dot menu paper
.three-dot-menu-paper {
  .MuiPaper-root {
    box-shadow: 0px 2px 4px 0px #00000026 !important;
    border-radius: 0.8rem;
    min-width: 13rem;
    margin-top: 0.5rem;
    ul.MuiList-root {
      padding: 0 !important;
      li { 
        padding: 1.2rem 1.6rem !important; 
        border: 1px solid $grey13;
        padding: 0.9rem 1.6rem !important;
        img, .icon {
          margin-right: 0.8rem;
        }
        &:hover {
          background: $lightPurple !important;
          border-color: $lightPurple !important;
        }
      }
    }
  }
}

.no-search-results {
  text-align: center;
    border: 0.1rem dashed $grey2 !important;
    padding: 6rem 0 !important;
  
  @include bp(lg-max) {
    padding: 15rem 0;
  }
  @include bp(sm-max) {
    padding: 8rem 0;
  }
  &.is-flexbox {
    height: calc(100vh - 22.8rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 45rem;
    padding: 0;
  }
  .icon {
    display: inline-block;
    line-height: 0;
    margin-bottom: 3.4rem;
    &.sm {
      margin-bottom: 0.8rem;
      img {
        max-width: 7.5rem;
      }
    }
  }
  h4 {
    margin: 0;
  }
  h6 {
    margin-bottom: 0.4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    color: $trueGrey;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin: 0;
  }
}

.no-data-wrapper {
  text-align: center;
  padding: 3.2rem 1.5rem;
  background: rgba($white, 0.7);
  border: 0.1rem dashed $grey2;
  @extend .be-radius;
  &.secondary {
    margin: 0 auto;
    padding: 2.4rem;
    border: 1px dashed var(--blue-gray-3, #dfe0e6);
    background: #fff;
    width: 100%;
    .MuiButton-root {
      width: 13rem;
    }
    &.fixed-height {
      height: 32.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &.no-border {
    border: 0;
    background: transparent;
    padding: 5.5rem 0;
    &.xs-pad {
      padding: 0.8rem 0;
    }
    &.sm-pad {
      padding: 2.4rem 0;
    }
    .thumb {
      margin-bottom: 0.8rem;
    }
    p {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.is-purple {
    padding: 4rem 1.5rem;
    border-color: $purple2;
    border-radius: 0;
    &.sm-pad {
      padding: 2rem 0;
    }
    &.lg-pad {
      padding: 6.4rem 0;
    }
    .thumb {
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .MuiButton-root {
      width: auto;
      padding: 0 1.6rem;
    }
  }

  .thumb {
    display: inline-flex;
    margin-bottom: 1.8rem;
  }
  h4 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 3.2rem;
    color: $black1;
    margin: 0 0 0.4rem 0;
  }
  h6 {
    margin-bottom: 0.4rem;
    &:last-child {
      margin-bottom: 0;
    }
    &.sm {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
  p {
    font-size: 1.6rem;
    @include line-height(16, 24);
    color: $trueGrey;
    margin: 0 0 2rem 0;
    &.sm-font {
      color: $trueGrey;
      font-size: 1.4rem;
      line-height: 2rem;
      margin: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .MuiButton-root {
    width: 18rem;
    max-width: 100%;
    font-size: 1.4rem;
    margin: 0 auto;
    background-color: $lightPurple;
    &:hover {
      background-color: $grey3;
    }
    &.sm-width {
      width: 13.6rem;
    }
  }
  &.meetings {
    padding: 2.1rem 1.5rem;
    border: 0;
    background: transparent;
  }
  &.no-leads {
    transform: translate(0, 10%);
    .thumb {
      width: 4rem;
      height: 4rem;
    }
    h6 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
}

.page-filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  .left-filter {
    @include bp(smd-max) {
      width: 100%;
    }
    .count {
      display: inline-block;
      font-size: 1.6rem;
      @include line-height(14, 24);
      color: $trueGrey;
      font-weight: 400;
      letter-spacing: 0.01em;
      &.filter-title {
        font-weight: 500;
      }
      &.lg {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }
  .right-filter {
    display: flex;
    flex-wrap: wrap;
    @include bp(smd-max) {
      width: 100%;
      margin-top: 1.5rem;
    }
    > * {
      margin-right: 1.8rem;
      &:last-child {
        margin-right: 0;
      }
    }
    .form-group {
      width: 30.4rem;
      margin-bottom: 0;
      @include bp(sm-max) {
        width: 100%;
        margin: 0 0 1.5rem;
        order: -1;
      }
      &.sort-by {
        width: auto;
        font-size: 1.4rem;
        line-height: 2rem;
        .dropdown-text {
          margin-right: 0.7rem;
        }
        .MuiOutlinedInput-root {
          &.borderless-dropdown {
            .MuiSelect-select {
              font-weight: 500;
            }
          }
        }
      }
    }
    .MuiButton-root {
      &.purple-btn {
        min-width: 14.3rem;
      }
      &.btn-full {
        min-width: max-content !important;
      }
      &.is-icon-btn {
        &::before {
          //left: 0.2rem;
          position: relative;
        }
        &.btn-archive {
          background: $white;
          width: 3.6rem !important;
          height: 3.6rem;
          border:1px solid $grey;
          .icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }
      }      
      &.be-calendar-plus {
        background: none;
        border: none;
        margin-right: 2.2rem;
        &::before {
          font-size: 3.2rem;
        }
      }
    }
  }
  
  .justify-right {
    justify-content: flex-end;
    align-items: center;
  }
  .search-input-wrap {
    border-color: $grey;
    .be-search {
      color: $black1;
    }
    ::placeholder {
      color: $grey11 !important;
    }
    .search-icon-wrap {
      cursor: pointer;
      img {
        width: 2rem;
        height: 2.2rem;
      }
    }
  }
}

.payment-waiting-strip {
  background:$purple3;
  border: 0.2rem solid $purple3;
  @extend .be-radius;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 2rem 2.4rem 2rem 11.9rem;
  @include bp(lg-max) {
    padding: 1.5rem 1.5rem 1.5rem 10rem;
  }
  @include bp(sm-max) {
    padding: 1.5rem;
  }
  .icon {
    position: absolute;
    left: 2.7rem;
    bottom: -0.2rem;
    line-height: 0;
    @include bp(lg-max) {
      left: 2rem;
    }
    @include bp(sm-max) {
      left: auto;
      right: 1.5rem;
    }
  }
  .content {
    width: calc(100% - 32.5rem);
    padding-right: 1rem;
    color: $white;
    font-size: 2rem;
    @include line-height(20, 32);
    font-weight: 500;
    @include bp(xlg-max) {
      font-size: 1.8rem;
    }
    @include bp(smd-max) {
      width: 100%;
    }
    &.full-width {
      width: 100%;
      padding-right: 0;
    }
  }
  .btn-wrap {
    display: flex;
    flex-wrap: wrap;
    @include bp(smd-max) {
      width: 100%;
      margin-top: 0.5rem;
    }
    @include bp(xs-max) {
      flex-direction: column;
      max-width: calc(100% - 8rem);
    }
    .MuiButton-root {
      margin-right: 1.4rem;
      min-width: 15.4rem;
      &:last-child {
        margin: 0;
      }
      @include bp(xs-max) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
}

.or-block {
  margin: 2.4rem 0;
  text-align: center;
  position: relative;
  z-index: 0;
  color: $grey4;
  &:after {
    content: "";
    height: 0.1rem;
    display: block;
    background: $grey;
    z-index: -1;
    position: absolute;
    top: calc(50% - 0.05rem);
    left: 0;
    right: 0;
  }
  .or-text {
    padding: 0 0.8rem;
    background: $white;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.payment-status-block {
  padding: 3.3rem 3.4rem 2.9rem 4.5rem;
  background-color: $purple2;
  @extend .be-radius;
  color: $white;
  @include bp(lg-max) {
    padding: 3rem 2.5rem;
  }
  .status-row {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    @include line-height(16, 24);
    h2 {
      margin-bottom: 1.6rem;
      letter-spacing: 0.01em;
    }
    .status-right {
      .price {
        @extend .h2;
        letter-spacing: 0.01em;
      }
    }
  }
  .status-btn {
    display: flex;
    justify-content: flex-end;
    @include bp(lg-max) {
      margin-top: 1.5rem;
    }
    .MuiButton-root {
      &.xs-btn {
        height: 4rem;
        width: 19.6rem;
      }
    }
  }
}

.plan-wise-detail-wrapper {
  border: 0.1rem solid $grey2;
  box-shadow: 0 0.8rem 2rem rgba(64, 0, 152, 0.04);
  border-radius: 0 0 0 2rem;
  background: $white;
  h6 {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    margin-bottom: 0.4rem;
  }
  .primary-link {
    font-size: 1.4rem;
    letter-spacing: 0.01em;
  }
  .plan-detail-outer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: $lightPurple;
    position: relative;
    padding: 3.1rem 3.4rem 4rem 20.2rem;
    border-top: 0.8rem solid $purple1;
    overflow: hidden;
    z-index: 0;
    @include bp(lg-max) {
      padding: 3rem 2.5rem 3rem 15rem;
    }
    @include bp(sm-max) {
      padding: 2.5rem;
    }
    &::before {
      content: "";
      height: 33.7rem;
      width: 33.7rem;
      background-color: $white;
      border-radius: 50%;
      position: absolute;
      bottom: 2.9rem;
      left: -21rem;
      z-index: -1;
      @include bp(sm-max) {
        left: -18rem;
        bottom: auto;
        top: -20rem;
      }
    }
    .plan-img {
      position: absolute;
      left: 3.5rem;
      top: 4.8rem;
      line-height: 0;
      @include bp(lg-max) {
        left: 1rem;
      }
      @include bp(sm-max) {
        position: static;
        margin-bottom: 1rem;
      }
      img {
        width: 12rem;
      }
    }
    .plan-info {
      @include bp(sm-max) {
        width: 100%;
      }
      h2 {
        margin-bottom: 1.8rem;
      }
    }
    .plan-right {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include bp(sm-max) {
        width: 100%;
        text-align: left;
        margin-top: 3rem;
      }
      .plan-validity {
        .validity {
          display: block;
        }
      }
      .plan-links {
        @extend .no-bullets;
        display: flex;
        justify-content: flex-end;
        @include bp(sm-max) {
          justify-content: flex-start;
          margin-top: 2rem;
        }
        li {
          margin-right: 4rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .plan-credit-outer {
    padding: 3.2rem 3.4rem 4rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include bp(lg-max) {
      padding: 3rem 2.5rem;
    }
    @include bp(sm-max) {
      padding: 2.5rem;
    }
    .left-credit {
      .available-credit {
        display: block;
      }
      h6 {
        margin-bottom: 0.8rem;
      }
    }
    .topup-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: right;
      .credit-used {
        color: $grey4;
        display: block;
      }
    }
    .credit-utilization-outer {
      width: 100%;
      margin-top: 1.6rem;
      p {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $grey4;
        margin-top: 0.8rem;
      }
    }
  }
}

.main-page-header {
  &.has-bottom-space {
    // margin-bottom: 3.2rem;
  }
  .page-breacrumb {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.6rem;
    position: relative;
    align-items: center;
    &.has-back-btn {
      justify-content: flex-start;
      .is-icon-btn {
        margin-right: 1.6rem;
        background: transparent;
        border: 0;
      }
    }
    .compnay-logo {
      padding-right: 0;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 4rem;
      width: 10rem;
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
  .page-title {
    // margin-bottom:0.5rem;
    // margin-bottom: 1.2rem;
    .title-wrap {
      word-break: break-all;
    }
    h2 {
      margin: 0 0 0.3rem;
      //text-transform: capitalize;
      text-transform: lowercase; 
      &:first-letter {
        text-transform: uppercase;
      }
    }
    p {
      text-transform: lowercase;       
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .upload-progress {
    margin-bottom: 3.2rem;
    p {
      font-size: 1.4rem;
      @include line-height(14, 20);
      letter-spacing: 0.01em;
      margin-top: 0.8rem;
    }
  }
  .no-data-wrapper {
    margin-top: 8.8rem;
    @include bp(sm-max) {
      margin-top: 7rem;
    }
  }
  .member-heading{
    margin-bottom: 0.5rem;
    p{
      color: $trueGrey;
    }
  }
}

.draft-card {
  border: 0.1rem solid $grey2;
  margin-bottom: 2.4rem;
  @extend .be-radius;
  overflow: hidden;
  cursor: pointer;
  @extend .transition;
  background: linear-gradient(102deg, #fbf9ff 0.94%, #f5eefe 74.96%, rgba(243, 235, 255, 0) 155.42%);
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    border-color: $purple;
  }
  .draft-top {
    padding: 1.6rem 1.6rem 1.6rem;
    position: relative;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;   
      margin-bottom: 0.8rem;   
      .right {
        display: flex;
        align-items: center;
        .icon-btn-wrap {          
          .MuiButtonBase-root {
            &.rounded {
              border-radius: 1.2rem !important;
              padding: 0.4rem 0.8rem;
              font-size: 1.2rem;
              height: 2rem;
              width: 2.8rem;
              &::before {
                width: 1.2rem;
              }
            }
          }
        }
      }
    }
    .custom-dropmenu {
      @extend .custom-dropmenu;
    }
    .action-btn {
      margin-left: 0.8rem;
    }
    .custom-tag {
      margin-bottom: 0;
    }
    .updated-date {
      display: block;
      font-size: 1rem;
      line-height: 1.6rem;
      font-weight: 500;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
    }
    h6 {
      margin-bottom: 0.2rem;
    }
    .created-date {
      color: $grey11;
      display: block;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.012rem;
    }
  }
  .count-row {
    border-top: 0.1rem solid $grey2;
    display: flex;
    background: $white;
    .count-col {
      width: 33.33%;
      padding: 1.6rem;
      border-right: 0.1rem solid $grey2;
      &:last-child {
        border-right: 0;
      }
      .title {
        font-size: 1rem;
        line-height: 1.6rem;
        display: block;
        color: $grey5;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.1rem;
      }
      .count {
        display: block;
        font-weight: 500;
      }
    }
  }
}

// Card template
.card-template {
  border-radius: 0 0 0 2rem;
  color: $black1;
  border: 1px solid $grey2;
  margin-bottom: 1.6rem;
  @extend .transition;
  position: relative;
  &.eq-height {
    display: flex;
    flex-direction: column;
    height: calc(100% - 1.6rem);
    .card-body {
      flex: 1;
    }
    .card-footer {
      margin-top: auto;
    }
  }
  &.full-height {
    height: 100%;
    margin-bottom: 0;
  }
  &.hoverable {
    cursor: pointer;
    &:hover {
      border-color: $purple2;
      //box-shadow: 0 0.2rem 0.4rem 0 rgba($black, 0.15);
      box-shadow: 0px 4px 16px 0px #00000026;
    }
  }
  &.space-card {
    .card-body {
      .folder-icon {
        height: 4.8rem;
        width: 4.8rem;
        border-radius: 0.8rem;
        background-color: $purple5;
        font-size: 2.8rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $white;
      }
    }
  }
  &:hover {
    .link-arrow-icon {
      opacity: 1;
    }
  }
  .link-arrow-icon {
    position: absolute;
    right: 2.6rem;
    top: 1.8rem;
    color: $grey4;
    font-size: 1.6rem;
    display: inline-flex;
    cursor: pointer;
    @extend .transition;
    opacity: 0;
    &::before {
      @include rotate(-90deg);
    }
  }
  .card-body {
    background: linear-gradient(102deg, #fbf9ff 0.94%, #f5eefe 74.96%, rgba(243, 235, 255, 0) 155.42%);
    padding: 1.6rem;
    &.has-radius {
      @extend .be-radius;
    }
    .inner-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .tag-container {
      padding: 0 0 1.6rem 0;
      .MuiStack-root {
        .MuiChip-root {
          padding: 0.2rem 0.8rem;
          width: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2.4rem;
          .MuiChip-label {
            padding: 0;
            color: $white;
            font-size: 1rem;
            font-weight: 500;
            @include line-height(10, 16);
            letter-spacing: 0.1rem;
            text-transform: uppercase;
          }
        }
      }
      &.has-right-content {
        padding: 0 0 0.4rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon-btn-wrap {
          @extend .btn-goal-rounded;
        }
      }
    }
    .folder-icon {
      display: inline-flex;
      margin-bottom: 1rem;
      font-size: 3.2rem;
    }
    .date-container {
      line-height: 1;
      span {
        color: $black1;
        font-size: 1rem;
        font-weight: 500;
        @include line-height(10, 16);
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        &.has-icon {
          display: flex;
          flex-wrap: wrap;
          .icon {
            display: inline-flex;
            font-size: 1.6rem;
            margin-left: 0.5rem;
          }
        }
      }
    }
    .card-title-container {
      color: $black1;
      font-size: 1.6rem;
      font-weight: 500;
      @include line-height(16, 24);
      margin-bottom: 0.8rem;
      &:last-child {
        margin-bottom: 0;
      }
      &.secondary {
        font-weight: 400;
      }
      .card-name {
        cursor: pointer;
        display: block;
        max-width: 19.8rem;
        @extend .truncated-text;
        &.buildcard-tile {
          max-width: 18rem;
        }
        &.add-ellipses {
          @extend .truncated-text;
        }
      }
      .sub-heading {
        color: $grey4;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        @include line-height(12, 16);
        letter-spacing: 0.012rem;
        img,
        .icon {
          margin-right: 0.4rem;
        }
      }
      .lead-name {
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.012rem;
        margin-top: 0.8rem;
        .lead-title {
          margin-right: 0.5rem;
          color: $grey5;
        }
      }
    }
    .progress-circle {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      cursor: pointer;
      .progress-bar {
        height: 1rem;
      }
      &:hover {
        .credits {
          display: block;
        }
      }
    }
  }
  .card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $grey2;
    &.two-col {
      .content-box {
        width: 50% !important;
      }
    }
    .content-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-right: 1px solid $grey2;
      padding: 1.6rem;
      width: 32%;
      background-color: $white;
      &:first-child {
        border-radius: 0 0 0 2rem;
      }
      &:last-child {
        border: 0;
        width: calc(100% - 64%);
      }
      .title {
        color: $grey5;
        font-size: 1rem;
        font-weight: 500;
        @include line-height(10, 16);
        letter-spacing: 0.1rem;
        text-transform: uppercase;
      }
      .count {
        color: $black1;
        font-size: 1.6rem;
        font-weight: 500;
        @include line-height(16, 24);
      }
    }
  }
}

.intereset-map-card {
  border: 0.1rem solid $grey6;
  @extend .be-radius;
  overflow: hidden;
  .thumb-wrapper {
    height: 11.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #f4eefc 0%, #faeeec 100%);
    img {
      max-height: 8.4rem;
    }
  }
  .card-details {
    padding: 1.6rem;
    min-height: 22rem;
    h6 {
      margin-bottom: 0.8rem;
    }
    p {
      font-size: 1.4rem;
      line-height: 2rem;
      color: $trueGrey;
      margin: 0;
    }
    .description-text {
      max-height: 8rem;
      overflow-x: hidden;
      overflow-y: auto;
      @include custom-scroll;
    }
    .credit-duration {
      @extend .no-bullets;
      margin-top: 1rem;
      li {
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin-bottom: 0.4rem;
        &:last-child {
          margin: 0;
        }
        .title {
          color: $grey5;
          margin-right: 0.5rem;
        }
      }
    }
  }
  .card-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1.6rem;
    border-top: 0.1rem solid $grey2;
    .MuiButton-root {
      margin-right: 1.4rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.info-tooltip {
  &.donut-chart-tooltip {
    left: -15.5rem !important;
    top: -10rem !important;
  }
  &.fixed-width {
    max-width: 22.1rem;
  }
  &.dashboard-space-name,
  &.dashboard-projects-tab,
  &.dashboard-card-name {
    max-width: 26rem;
  }   
  &.dashboard-more-dropdown {
    max-width: 17rem;
  }
  .MuiTooltip-tooltip {
    width: auto !important;
    min-width: auto !important;
    &::before {
      visibility: hidden;
      opacity: 0;
      box-shadow: none;
      z-index: 0;
    }
    .MuiTooltip-arrow {
      color: #fff;
    }
  }
  &.archive {
    .MuiTooltip-tooltip {
      right: 1.2rem;
    }
  }
  &.has-list-items {
    .tooltip-content > span {
      text-align: left !important;
    }
    .MuiTooltip-tooltip:before {
      box-shadow: none;
      top: auto;
      bottom: 0.8rem;
      opacity: 1;
      visibility: visible;
      z-index: 0;
    }
  }
  &.capt-first-letter {
    .tooltip-content {
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  &.stack-menu {
    &[data-popper-placement="bottom-end"] {
      .MuiTooltip-tooltip {
        &::before {
          top: 0.7rem !important;
          bottom: auto !important;
          visibility: visible;
          opacity: 1;
        }
      }
    }
    &[data-popper-placement="top-end"] {
      .MuiTooltip-tooltip {
        margin-right: -1rem;
        &::before {
          bottom: 0.7rem !important;
          top: auto !important;
          visibility: visible;
          opacity: 1;
          left: auto;
          right: 0;
        }
      }
    }
    .MuiTooltip-tooltip {
      width: 21.6rem !important;
      max-width: 21.6rem !important;
      border-radius: 0.8rem;
      box-shadow: 0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.15) !important;
      padding: 1.6rem;

      .purple-chips {
        flex-flow: column;
        .MuiChip-root {
          border-radius: 3.2rem;
          border: 1px solid $grey16;
          background: $grey15;
          padding: 0.2rem 0.8rem;
          margin: 0 0 1.4rem;
          height: auto;
          display: inline-flex;
          width: auto;
          max-width: min-content;
          &:last-child {
            margin: 0;
          }
          .MuiChip-label {
            padding: 0;
            font-size: 1.2rem;
            font-weight: 500;
            color: $black1;
            @include line-height(12, 16);
            letter-spacing: 0.012rem;
            display: block;
            max-width: 18rem;
            @extend .truncated-text;
            &::first-letter {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  &.bottom-end {
    margin-right: 0 !important;
  }
}
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    &::before {
      transition: none !important;
    }
  }
}
.B-loading-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  -webkit-tap-highlight-color: transparent;
  z-index: 1400;
  //background-color: rgba(0, 0, 0, 0.2);
  background: rgba(248, 249, 250, 0.68);
}
// Alert messages
.alert-message-box {
  // &.MuiPaper-root {
  position: relative;
  left: 8rem;
  bottom: 1rem;
  background: $white;
  border-radius: 0.8rem;
  padding: 0.2rem 1.6rem;
  box-shadow: 0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.15);
  #notistack-snackbar {
    max-width: 40rem;;
  }
  &.is-success {
    border: 1px solid #00c853;
    .toast-message-success {
      padding-left: 0;
      color: $black1;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      &::before {
        background: url("../images/check-circle-succes.svg") 0 0 / cover no-repeat;
        width: 2rem;
        height: 2rem;
        display: inline-block;
        content: "";
      }
    }
  }
  &.is-error {
    border: 1px solid #d50000;
    .toast-message-error {
      padding-left: 0;
      color: $black1;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      &::before {
        background: url("../images/close-remove-cross-circle.svg") 0 0 / cover no-repeat;
        width: 2rem;
        height: 2rem;
        display: inline-block;
        content: "";
      }
    }
  }
  .MuiAlert-icon {
    padding: 0;
    margin: 0;
    width: 2.1rem;
    height: 2.1rem;
  }
  .MuiAlert-message {
    padding: 0.2rem 0 0 0;
    margin: 0 2rem 0 0.8rem;
    max-width: 50rem;
    color: $black1;
    font-size: 1.4rem;
    font-weight: 400;
    @include line-height(14, 20);
    letter-spacing: 0.014rem;
    // @extend .truncated-text;
  }
  &.message {
    max-width: 50rem;
    color: $black1;
    font-size: 1.4rem;
    font-weight: 400;
    @include line-height(14, 20);
    letter-spacing: 0.014rem;
  }
  .MuiAlert-action {
    padding: 0;
    .MuiButtonBase-root {
      width: 2rem;
      height: 2rem;
      svg {
        color: $black1;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.MuiAutocomplete-popper {
  border: 1px solid $grey8 !important;
  .MuiPaper-root {
    .MuiAutocomplete-listbox {
      padding: 0 !important;
      @include custom-scroll;
    }
  }
}
.MuiMenu-root {
  .MuiPaper-root {
    @include custom-scroll;    
  }
}
// Select carrot icon
.MuiAutocomplete-root {
  .MuiInputBase-root {
    .MuiAutocomplete-endAdornment {
      top: 50%;
    }
  }
}

.select-goal-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  .left-side {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1.6rem;
    .MuiStack-root {
      //margin-bottom: .8rem;
      display: flex;
      .MuiChip-root {
        display: flex;
      }
    }
  }
  .right-side {
    display: block;
    //width: calc(100% - 4.2rem);
    flex-direction: column;
    align-items: flex-start;

    @include bp(sm-max) {
      display: block;
      width: calc(100% - 4.2rem);
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.tag-wrapper {
  .MuiStack-root {
    display: block;
    .MuiChip-root {
      margin: 0 1.6rem 0.8rem 0;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0.012rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.MuiAutocomplete-listbox {
  //border: 1px solid $grey8;
  .custom-tag {
    padding: 0.4rem 0.8rem;
    border: 0;
    background: $lightPurple;
    cursor: pointer;
    .MuiChip-label {
      padding: 0;
      color: $black1;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.6rem;
      letter-spacing: 0.012rem;
      text-transform: none;
      max-width: 20rem;
      @extend .truncated-text;
    }
  }
}

// Sort by select menu
.sorting-menu-item {
  width: 18rem;
  padding: 1.2rem 1.6rem !important;
  background-color: $white;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  line-height: 1.6rem !important;
  letter-spacing: 0.012rem;
  border-left: 1px solid $grey6;
  border-right: 1px solid $grey6;
  @extend .truncated-text;
  display: block !important;
  &.Mui-selected {
    background-color: $lightPurple;
  }
  &.add-ellipses {
    @extend .truncated-text;
  }
}
.MuiMenu-root {
  .MuiMenu-paper.MuiPaper-rounded {
    border-radius: 0.8rem;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15) !important;
    ul.MuiList-root {
      padding: 0 !important;
    }
  }
}

.empty-tags-wrapper {
  text-align: center;
  .empty-icon {
    display: inline-block;
    margin-bottom: 0.6rem;
    &.goal {
      img {
        width: 4.5rem;
        height: 4.5rem;
      }
    }
  }
  .empty-text {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 500;
    &.grey {
      color: $trueGrey;
      margin-bottom: 0.8rem;
    }
  }
  .align-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tabs-menu.page-top-navigation {
  height: 4rem !important;
  .MuiTabs-root {
    min-height: 4rem;
    .MuiTabs-fixed {
      .MuiButtonBase-root {
        min-width: auto;
        padding: 0.4rem 0 0.8rem;
        margin: 0 4.8rem 0 0;
        min-height: 4rem;
        text-transform: capitalize;
        font-size: 1.6rem;
        &:last-child {
          margin-right: 0;
        }
        .MuiTouchRipple-root {
          color: $black1;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2.4rem;
        }
        &.Mui-disabled {
          pointer-events: inherit;
        }
      }
      //.MuiTabs-indicator {}
    }
  }
}

.goal-card {
  border: 0.1rem solid $grey2;
  border-top: 0.4rem solid $green;
  padding: 2rem 1.6rem 1.6rem;
  background: $white;
  @extend .be-radius;
  @extend .transition;
  &.off-track {
    border-top-color: $red1;
    .goal-status {
      &:after {
        background-color: $red1;
      }
    }
  }
  .goal-type {
    display: block;
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 0.8rem;
  }
  h6 {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 0.8rem;
  }
  .goal-status {
    position: relative;
    font-size: 1rem;
    line-height: 1.6rem;
    letter-spacing: 0.01rem;
    text-transform: uppercase;
    font-weight: 500;
    padding-right: 1.8rem;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 1.2rem;
      width: 1.2rem;
      display: block;
      background-color: $green;
      border-radius: 50%;
    }
  }
}

// Card Tags
.card-tags-wrap {
  margin: .8rem 0 0;
  //display: flex;
  .MuiButton-root.xs-btn.is-icon-btn {
    border: 1px solid $purple2;
    width: 2.8rem;
    height: 2rem;
    border-radius: 1.2rem;
    margin: 0 .6rem 0.6rem 0;
    padding: 0 0.8rem;
    &.whitebg {
      background: $white;
    }
    .icon { 
      width: 1.2rem; 
      &::before { font-size: 1.2rem; }
    }
  }
  .tag-wrapper {
    display: flex;
    align-items: center;    
    .tags-container {
      max-width: calc(100% - 2.6rem);
      .custom-tag {
        border-radius: 1.2rem;
        border: 1px solid $purple2;
        background: $white;
        margin: 0 .6rem .6rem 0;
        &.more-tags {
          cursor: pointer;
        }
      }
      .MuiStack-root {
        display: inline-flex;
        .MuiChip-root {          
          position: relative; 
          border-radius: 1.2rem;
          border: 1px solid $purple2;
          background: $white !important;
          font-size: 1rem;
          margin: 0 0.6rem 0 0;
          font-weight: 400;
          height: auto;    
          justify-content: flex-start;    
          .MuiChip-label {
            padding: 0.2rem 0.8rem;
            min-width: 2.8rem;
            max-width: 16rem;
            @extend .transition; 
          }
          .MuiChip-deleteIcon {
            opacity: 0;
            font-size: 0.8rem;
            color: $black1;
            position: absolute;
            right: 0.4rem;
            top: 0.25rem;
            padding: 0.2rem;
            margin: 0;
            line-height: 1;
            display: inline-block;
            // background-color: $lightPurple;
            transition: all ease-in 0.1s;
            border-radius: 1rem;
            &::before {
              font-weight: bold !important;
              font-size: .8rem;
            }
          }
          &:hover {    
            &.overflow-visible {
              .MuiChip-label {
                overflow: visible !important;
              }
            }        
            .MuiChip-label {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: calc(90%);
            }
            .MuiChip-deleteIcon{
              opacity: 1;
              cursor: pointer;
            }
          }
        }
      }
    }
    .icon-btn-wrap {
      width: 2.8rem;
      margin-top: 0.1rem;
      padding-top: 0;
      .MuiButtonBase-root {
        margin: 0;
        height: 2rem;
        width: 2.8rem;
        border-radius: 1.2rem;
        &:focus, &:focus-visible, &:visited {
          background: inherit;
          transition: none;
          outline: none;
        }
        &:hover{
          background-color: $white;
        }
      }
      &.tag-button {
        margin-top: 0.4rem;
        .MuiButtonBase-root {
          height: 2rem;
          width: 2.8rem;
          background: $lightPurple !important;
        }
      }
    }
  }
  &.list-view {
    display: inline-flex;
    justify-content: center;
  }
  &.playground {
    .icon-btn-wrap {
      padding-top: .3rem;
    }
  }  
}

// Tags dropdown list
.taglist-menu {
  .MuiMenu-paper {
    max-width: 22.4rem;
    max-height: 28rem;
    @include custom-scroll;
    box-shadow: 0px 4px 16px 0px #00000026 !important;
    .MuiList-root {
      .selected-tags {
        padding: 1.6rem 1.6rem 0 1.6rem;
      }
    }
  }
  .MuiMenu-list {
    .selected-tags {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid $grey12;
      .custom-tag {
        display: inline-flex;
        margin: 0 0.6rem 0.6rem 0;
      }
    }
  }
  &.cards { 
    .MuiPaper-root {
      box-shadow: 0 .4rem 1.6rem 0 rgba(0, 0, 0, 0.15) !important;
      .MuiList-root {
        padding: 0;
        .empty-tags-wrapper {
          padding: 0.6rem 0.6rem 0 1rem;
          text-align: left;
          .empty-text {
            font-weight: 400;
          }
        }
        .MuiFormControl-root {
          border-bottom: 1px solid $grey6;
          padding: 0 1rem;
        }
        .MuiButtonBase-root {
          font-size: 1.2rem;
        }
        .MuiStack-root {
          display: inline-flex;
          margin: 0 0.6rem 0.6rem 0;
          .MuiChip-root {
            background-color: #f3ebff;  
            height: 2.4rem;
            display: inline-flex;
            align-items: center;  
            border-radius: 1.2rem; 
            max-width: 19rem;
            cursor: pointer;       
            .MuiChip-label {
              color: $black1;
              font-size: 1.2rem;
              font-weight: 400;
              @include line-height(12,16);
              letter-spacing: 0.012rem;
            }
          }
        }
        .tag-row {
          padding: .8rem;
          margin: 0;
          border-bottom: 1px solid $grey6;
          &:last-child { border:0; }
          .MuiStack-root {
            margin-bottom: 0;
          }
          .custom-tag {
            padding: 0.4rem 0.8rem;
            height: 2.4rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: normal;
            font-size: 1.2rem;
            background: $lightPurple;
          }
        }
      }
    }
  }
}

.leads-by-bc-wrapper {
  display: flex;
  overflow: auto;
  @include custom-scroll;
  padding-bottom: 1.6rem;
  height: 23rem;
  &::-webkit-scrollbar-thumb {
    background: $grey12;
  }
  &::before,
  &::after {
    content: "";
    margin: auto;
  }
  .lead-col {
    width: 4.2rem;
    min-width: 4.2rem;
    margin-right: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    &:last-child {
      margin-right: 0;
    }
    .lead-graph {
      margin-bottom: 0.8rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      .count {
        font-size: 1.2rem;
        line-height: 1.6rem;
        display: block;
        margin-bottom: 0.8rem;
      }
      .graph {
        height: 11.6rem;
        max-height: 11.6rem;
        background: $purple3;
        width: 1.6rem;
        margin: 0 auto;
        border-radius: 1rem 1rem 0 0;
        min-height: 1rem;
        &.is-empty {
          background-color: $grey2;
        }
      }
    }
    .lead-info {
      .dp {
        display: inline-block;
        height: 3.2rem;
        width: 3.2rem;
        border-radius: 50%;
        background: $lightPurple;
        overflow: hidden;
        border: 0.2rem solid $white;
        font-size: 1.2rem;
        line-height: 3rem;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .name {
        font-size: 1.2rem;
        line-height: 1.6rem;
        display: block;
        text-align: center;
        margin-top: 0.4rem;
        @extend .truncated-text;
      }
    }
  }
}
.btn-idea-board {
  &.MuiButtonBase-root {
    width: 4.8rem !important;
    min-height: 4.8rem;
    height: 4.8rem;
    margin-right: 1.6rem;
    background: $white !important;
    &:hover {
      background: $grey1 !important;
    }
  }
}
.btn-start-working-idea {
  &.MuiButtonBase-root {
    font-size: 1.4rem;
    font-weight: 500;
    @include line-height(14,20);
    letter-spacing: 0.014rem;
    color: $black1;
    min-width: 20.3rem !important;
  }
}

.projects-top-nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  .page-top-navigation {
    width: calc(100% + 2.4rem);
  }
  .page-filters-wrapper {
    position: absolute;
    right: 0;
    bottom: 1.6rem;
    padding-right: 2.5rem !important;
    .purple-btn {
      font-size: 1.4rem;
    }
  }
}

.wb-integration-wrapper {
  .page-filters-wrapper {
    padding: 1.5rem 3.2rem 1.8rem 1.8rem;
    justify-content: space-between;
    background: $grey7;
    position: relative;
    
    .left-page-header {
      display: flex;
      align-items: center;
      .MuiButtonBase-root {
        margin: 0 1.6rem 0 0;
        &.btn-back {
          background: $white;
          width: 3.6rem;
          height: 3.6rem;
          min-width: 3.6rem;
          border: 1px solid rgba(179, 136, 255, 0.30);
          .btn-icon {
            font-size: 1.6rem;
            margin: 0;
          }
        }
      }
      .dropdown-wrap {
        padding-left: 1.7rem;
        .MuiButtonBase-root {
          min-width: 10rem;
          width: 10rem;
          height: 3.6rem;
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          font-weight: 500;
          color: $black1;
          border-radius: 0.8rem;
          em {
            margin: 0 0 0 1.3rem;
            line-height: 1;
          }
        }
      }
    }
    .right-page-header {            
      .compnay-logo {
        padding-right: 0;
        position: absolute;
        right: 0;
        top: 1.4rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 4rem;
        width: 10rem;
        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.faq-accordion{
  border: 0;
  .MuiAccordion-rounded{
  margin-bottom: 2rem !important;
  border: 0.1rem solid #CCC6D3 !important;  
  border-radius: 0 0 0 2rem !important;
}
.MuiCollapse-root{
  border-top: 0.1rem solid $grey2;
  margin: 0 3rem 0;
}
.MuiAccordion-root {
  .MuiAccordionSummary-root{
    padding: 2.35rem 3rem;
    &::after{
      font-size: 1.5rem;
    }
  }
  .MuiAccordionDetails-root{
    padding: 1.2rem 0rem 2rem;
    font-size: 1.4rem;
    color: $black1;
  }
}
}
.plans-pricing-wrapper{
  .plan-btn-wrap{
  .sm-btn {
    height: 6rem !important;
    background-color: $white;
    color: $black1;
    border: 1px solid rgba(179, 136, 255, 0.3);
    border-radius: 0.8rem;
  }
}
}

.noactive-plans{
  background-color: $white;
  border: 0.1rem dashed #D9C4FF;
  padding: 5.5rem 3rem 6.5rem;
  margin-top: 2rem;
  text-align: center;
  border-radius: 0 0 0 2rem;
  @include bp(xs-max) {
    padding: 3rem;
  }
  .btn-block{
    justify-content: center;
    @include bp(xs-max) {
      flex-wrap: wrap;
    }
    .sm-btn{
      font-size: 14px;
      color: $black1;
    }
    .purple-btn{
      margin-left: 1.5rem;
      min-width: 20rem;
      @include bp(xs-max) {
        margin-top: 1.5rem;
        margin-left: 0;
      }
    }
  }
  p{
    margin: -0.5rem 0 2.2rem;
  }
  .imgframe{
    margin-bottom: 1.5rem;
  }
}

.pricing-login{
  .login-page{
    background: transparent;
    min-height: inherit;
    .login-wrapper{
      min-height: inherit;
      padding: 0rem 0 2rem 0;
      .logo{
        margin-bottom: 1rem;
      }
    }
  }
  p{
    font-size: 1.8rem;
    text-align: center;
  }
  .purple-btn{
    width: 95.5%;
    height: 6rem !important;
    margin: 2rem 1.5rem 0;
    @include bp(xs-max) {
      font-size: 12px;
      width: 90.5%;
    }
    img{
      width: 34px;
      border-radius: 100%;
      margin-right: 1rem;
    }
  }
  .MuiDialog-container{
    .MuiDialog-paper{
      width: 68.0rem;
      border-radius: 1.6rem;
      margin: 3.2rem 1.5rem;
      min-height: 620px;
      .no-btns{
        padding: 4rem 2.5rem 1.6rem;
        @include bp(sm-max) {
          padding: 4rem 1rem 1.6rem;
        }
      }
    }
  }
}

.speak-expert{
  .expert-content{
    min-height: 272px;
    .sent-img{
      margin-bottom: 1.5rem;
      display: block;
      @include bp(sm-max) {
        max-width: 14.0rem;
        margin: 0 auto 1.5rem;
      }
    }
    h3{
      font-size: 2rem;
    }
  }
  .be-left-arrow{
    display: inline-flex;
    position: absolute;
    left: 3.2rem;
    top: 3.2rem;
    color:$black1;
    font-size: 1.8rem;
    cursor: pointer;
  }
  .login-wrapper{
    padding: 0 !important;
  }

}

// Date Picker - custom
.sm-datepicker-popper {
  .MuiPaper-root {
    .MuiDateCalendar-root {
      width: 26.4rem !important;
      max-height: 28.4rem !important;
      //.MuiPickersCalendarHeader-root {}
      .MuiPickersFadeTransitionGroup-root {
        .MuiDayCalendar-root {
          .MuiDayCalendar-header {
            .MuiTypography-root {
              width: 3rem !important;
            }
          }
          .MuiDayCalendar-slideTransition {
            .MuiDayCalendar-monthContainer {
              .MuiDayCalendar-weekContainer {
                .MuiPickersDay-root {
                  width: 3rem !important;
                  height: 3rem !important;
                }
              }
            }
          }
        }
        .MuiYearCalendar-root {
          width: 26.4rem !important;
          .MuiPickersYear-root {
            width: 100% !important;
            max-height: 23rem !important;
            button {
              margin: 0.2rem 0 !important;
              height: 3rem !important;
              width: 6rem !important;
            }
          }
        }
      }
    }
  }
}

// Search goals popper dropdown
.search-dropdown-popper {
  box-shadow: 0 0 3.2rem 0 $purple7 !important;
  z-index: 99;
  background-color: $white;
  .MuiPaper-root { 
    border-radius: 0; 
    ul { 
      padding: 0 !important; 
      .MuiAutocomplete-option {
        background-color: transparent;
        &:hover, &:focus {
          background-color: $lightPurple;
        }
      }
    }
  }  
  .search-goals-dropdown {  
    padding: 0; 
    width: 100%;
    display: flex; 
    align-items: center;
    justify-content: space-between;
  }
}

.secondary-header {
  margin-bottom: 0.6rem;
  h2{
    margin-bottom: 0.3rem;
  }
}
// Tag list : popper [Goals, playground]
.taglist-menu {
  &.MuiMenu-root .MuiMenu-paper.MuiPaper-rounded {
    box-shadow: 0px 4px 16px 0px #00000026 !important;
  }
}

// Fixed footer : Modal
.fixed-footer-wrap {
  padding: 0 !important;
  box-shadow: 0 -0.2rem 0.8rem 0 rgba(0, 0, 0, 0.08);
  .fixed-full-footer {
    display: flex;
    padding: 1.5rem 4rem;
    width: 100%;    
    .sm-btn {
      width: 50%; 
    }
    .grey-btn {
      width: calc(50% - 1.6rem);
      margin-right: 1.6rem;
    }

    &.sign-in {
      flex-flow: column;
      .sm-btn {
        width: 100%;   
        margin: 0;    
        height: 4.8rem !important; 
      }
      p {
        margin: 1.6rem 0;
      }
    }
  }
}

// Right side summary
.summary {
  position: relative;
  width: 33.6rem;
  height: auto;
  min-height: calc(100vh - 21.7rem);
  border-left: 1px solid $grey2;
  padding: 2.4rem;
  font-size: 1.4rem;
  color: $black1;
  background-color: $white;
  @include line-height(14, 20);
  @include bp(smd-max) {
    max-width: 100%;
    border: 0;
    border-bottom: 1px solid $grey2;
    border-left: 0;
    flex-wrap: wrap;
    display: flex;
  }
  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0.8rem 0;
    .MuiButtonBase-root {
      width: 2.4rem;
      height: 2.4rem;
      min-width: 2.4rem;
      font-size: 1.8rem;
      font-weight: 400;
      color: $black1;
    }
    .MuiPaper-root {
      .MuiList-root {
        margin-top: 0.4rem;
      }
    }
  }
  .title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0 0 0.8rem 0;
  }

  .credits {
    display: flex;
    justify-content: space-between;
    padding: 0 0 2.4rem;
    @include bp(smd-max) {
      width: 100%;
      padding: 0 0 1.6rem 0;
    }
    .credit-balance {
      .count {
        font-size: 3.2rem;
        @include line-height(32, 40);
        font-weight: 500;
        padding: 0 0 1.6rem;
        @include bp(smd-max) {
          padding: 0;
        }
      }
    }
    .request-credits {
      a {
        color: $purple1;
        text-decoration: underline;
        font-size: 1.2rem;
        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }

  .content-row {
    border-top: 1px solid $grey2;
    padding: 2.4rem 0;
    &:first-child {
      border-top: 0;
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    @include bp(smd-max) {
      width: 100%;
      padding: 1.6rem 0;
    }

    .user-link {
      display: inline-flex;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1.4285714286;
      letter-spacing: 0.01em;
      font-weight: 500;
      cursor: pointer;

      .initials,
      .user-avatar {
        height: 3.6rem;
        width: 3.6rem;
        min-width: 3.6rem;
        border-radius: 50%;
        background: #f3ebff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: 700;
        text-transform: uppercase;
        img {
          border-radius: 50%;
        }
      }
      .user-name {
        padding-left: 1.2rem;
      }
    }
    .user-detail {
      padding-left: 1.2rem;
      letter-spacing: 0.014rem;
      color: $black1;
      font-weight: normal;
      .user-name {
        padding-left: 0;
        color: $purple1;
      }
      .description-text {
        .time {
          color: $grey5;
        }
      }
    }
    .chips {
      .MuiStack-root {
        flex-direction: column;
        .MuiChip-root {
          margin: 0 0.8rem 0.8rem 0;
          max-width: fit-content;
        }
      }
    }
    .tags {
      .MuiStack-root {
        display: inline-block;
        .MuiChip-root {
          margin: 0 0.8rem 0.8rem 0;
          max-width: fit-content;
        }
      }
    }
    .recent-activity {
      max-height: 100%;
      // overflow: auto;
      // @include custom-scroll;
      .user-link {
        padding: 0.8rem 0;
        font-size: 1.2rem;
        @include line-height(12,20);
        align-items: flex-start;
      }
    }
  }
}

// Unauthorized Access || 404
.unauthorised-access-wrap, .page-404 {
  position: relative;
  padding: 0;
  margin: 0;
  .inner-container {
    position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, 100%);
    margin-left: -2.3rem;
		text-align: center;
		display: flex;
		flex-flow: column;
		align-items: center;
    .icon-wrap {
      width: 10rem;
			height: 10rem;
			border-radius: 2rem;
			background: $white;
			box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.25);
			display: flex;
			align-items: center;
			justify-content: center;
      img {
        width: 6rem;
        height: auto;
      }
    }  
    .img-wrap {
      .empty-box {
        width: 12rem;
        height: 12rem;
      }
    }
    h3 {
      color: $black;
			font-family: $primaryFont;
			font-size: 3.2rem;
			line-height: 4rem;
			margin: 3.2rem 0 2.4rem;
    }
    p {
      color: $black;
      font-size: 2rem;
      line-height: 3.2rem;
      margin-bottom: 2.4rem;
      margin-top: -0.8rem;
    }
    .MuiButtonBase-root {
      font-size: 1.6rem;
      background: $purple3;
      padding: 1.6rem 2.4rem;
      border-radius: 0.8rem;
      color: $white;
      &:hover {
        opacity: 0.95;
      }
    }
  }
}
.page-404 {
  width: 100%;
  height: 100vh;
  background: $white;
  .inner-container {   
    transform: translate(-50%, -50%);
    margin-left: 2.3rem; 
    z-index: 2;
  }
  .bg-404 {
    background: url('../images/bg-404.svg') no-repeat 0 0/contain;
    z-index: 1;
    position: absolute;
    top: 20%;
    left: 15%;
    height: 60%;
    width: 70%;
  }
  .logo {
    position: absolute;
    top: 5.4rem;
    left: 11.2rem;
    img {
      width: 12.6rem;
      height: auto;
    }
  }
}